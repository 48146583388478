<template>
  <div>
    <px-custom-modal
      v-model="showModal"
      @confirm="confirm"
      @cancel="cancel"
      :transition="{
        'enter-active-class': 'transition duration-200 ease-in-out transform',
        'enter-class': 'translate-y-full',
        'enter-to-class': 'translate-y-0',
        'leave-active-class': 'transition duration-200 ease-in-out transform',
        'leave-to-class': 'translate-y-full',
        'leave-class': 'translate-y-0',
      }"
    >
      <template v-slot:title>
        <div class="text-center mt-8">
          <img
            class="inline-block"
            src="@/assets/img/warning_amber_24px.png"
            alt=""
          />
          <br />
          <span class="font-bold text-lg">¿Olvidaste tu código?</span>
        </div>
      </template>
      <px-form-recovery-code />
    </px-custom-modal>
    <p
      @click="buttonClick"
      class="mt-3 text-sm font-normal leading-4 mb-2 float-right text-white cursor-pointer"
    >
      ¿Olvidaste tu código?
    </p>
  </div>
</template>
<script>
import PxCustomModal from "@/components/PxCustomModal.vue";
import PxFormRecoveryCode from "./PxFormRecoveryCode.vue";

export default {
  name: "PxModal",
  components: { PxCustomModal, PxFormRecoveryCode },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    confirm() {
      // some code...
      this.show = false;
    },
    cancel(close) {
      // some code...
      close();
    },
    buttonClick() {
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
.take-control {
  cursor: pointer;
  width: 260.6px;
  height: 62px;
  background: #f79009;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: #f79009 url("../assets/img/click.svg");
  background-position: 220px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

.enerbit-text {
  color: #4e04a3;
}
</style>

<template>
  <div class="sticky top-0 bg-nav pt-4 pl-16 pr-16 z-40 h-32">
    <nav
      class="container pb-12 mx-auto md:flex md:justify-between md:items-center max-w-full"
    >
      <div class="flex items-center justify-between">
        <a href="https://enerbit.co/">
          <img class="h-24" src="@/assets/img/mainLogo.svg" alt="enerbit"
        /></a>
        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="flex-col space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0 bg-white text-center"
      >
        <router-link
          v-for="l in links"
          :key="l.title"
          :to="l.to"
          class="block md:mt-4 lg:inline-block lg:mt-0 text-primary-900 font-bold text-sm"
          >{{ l.title }}</router-link
        >
      </ul>
    </nav>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "PxHeader",
  props: {
    links: {
      type: Array,
      required: true,
    },
    textButton: String,
  },
  methods: {
    myProcess: function () {
      this.$router.push("mi-proceso");
    },
  },
  setup() {
    let showMenu = ref(false);
    const toggleNav = () => (showMenu.value = !showMenu.value);
    return { showMenu, toggleNav };
  },
};
</script>
<style scoped>
.active {
  border-bottom: 2px solid #f79009;
  padding-bottom: 5px;
  width: 98px;
  height: 61px;
  background-color: #f79009;
  text-align: center;
  margin: 0 auto;
  padding-top: 20px;
  color: #ffffff !important;
}
.bg-nav {
  background: #ffffff;
  z-index: 110;
}
</style>

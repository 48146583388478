<template>
  <div>
    <vue-final-modal
      v-model="showSuccessModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <button class="modal__close" @click="changeModalState()">
        <i class="mdi mdi-window-close"></i>
      </button>
      <div class="modal__content py-5 px-5">
        <div class="text-center">
          <div class="w-full text-center">
            <img
              class="w-40 inline-block"
              src="@/assets/img/checkForm.svg"
              alt=""
            />
          </div>
          <div class="mt-10 text-center">
            <span class="mt-4 font-semibold text-2xl">{{
              messageModal.title
            }}</span>
          </div>
          <div class="responseDescContainer">
            <p class="text-gray-500">
              {{ messageModal.description }}
            </p>
          </div>
          <button class="success-button text-center" @click="changeModalState">
            Mil gracias
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
export default {
  name: "PxMessageModal",
  computed: {
    showSuccessModal() {
      return this.$store.state.showSuccessModal;
    },
    messageModal() {
      return this.$store.state.messageModal;
    },
  },
  methods: {
    changeModalState() {
      this.$store.commit("toggleSuccessModal");
    },
  },
};
</script>
<style scoped>
.take-control {
  cursor: pointer;
  width: 260.6px;
  height: 62px;
  background: #f79009;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: #f79009 url("../assets/img/click.svg");
  background-position: 220px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

.responseDescContainer {
  height: 41px;
  width: 386px;
  border-radius: nullpx;
  margin: 15px auto 33px auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.success-button {
  height: 36px;
  width: 132px;
  border-radius: 5px;
  padding: 10px, 25px, 10px, 25px;
  background: #4e04a3;
  border: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto !important;
}

.take-control {
  cursor: pointer;
  width: 260.6px;
  height: 62px;
  background: #f79009;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: #f79009 url("../assets/img/click.svg");
  background-position: 220px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

.enerbit-text {
  color: #4e04a3;
}

::v-deep .modal-container {
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin: 0 auto;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<template>
  <div class="container pt-0 plans-content">
    <px-plans
      :plans="plans"
      :titlePlans="titlePlans"
      :descriptionPlans="descriptionPlans"
    />
  </div>
</template>
<script>
import PxPlans from "@/components/PxPlans.vue";

export default {
  name: "Business",
  components: { PxPlans },
  data() {
    return {
      plans: [
        {
          title: "fijaBit",
          description: "Ahorra todo el tiempo",
          img: require("@/assets/img/fijabit.svg"),
          question: "¿Tu empresa opera 24/7?",
          buttonText: "¡Este fue!",
          parent: "business",
        },
        // {
        //   title: "dinaBit",
        //   description: "Elige tus horas y ahorra más",
        //   img: require("@/assets/img/dinabit.svg"),
        //   question: "¿Jornadas de trabajo flexibles?",
        //   buttonText: "¡Este fue!",
        //   parent: "business",
        // },
      ],
      titlePlans: "Empresas",
      descriptionPlans: "Planes simples pensados para tu empresa",
    };
  },
};
</script>
<style scoped>
.business-descrpiption {
  color: #04d15f;
}
.plans-content {
  height: 949px;
}
</style>

<template>
  <div>
    <footer
      class="text-center lg:text-left bg-gray-100 text-gray-600 bg-primary-700 footer"
    >
      <div class="mx-6 py-10 text-center md:text-left">
        <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div class="">
            <h6
              class="text-xl font-normal mb-4 flex items-center justify-center md:justify-start text-secondary-orange-300"
            >
              Legal
            </h6>
            <p class="text-white cursor-pointer ml-5" @click="pushPage()">
              Legal y regulatorio
            </p>
            <p
              class="text-white cursor-pointer ml-5"
              @click="showPersonalData()"
            >
              Política de tratamiento de datos personales
            </p>
            <p class="text-white cursor-pointer ml-5" @click="pushTycPage">
              Términos y condiciones
            </p>
            <p class="text-white cursor-pointer ml-5" @click="goToSicepPage">
              Convocatorias SICEP
            </p>

            <!-- <h6
              class="font-snormal text-xl emibold mb-4 flex justify-center md:justify-start text-secondary-orange-300 mt-5"
            >
              Compañ&iacute;a
            </h6>
            <p class="text-white ml-5">Ley de transparencia</p> -->
          </div>
          <div></div>
          <div class=""></div>
          <div class="">
            <img
              class="float-right"
              src="@/assets/img/companyBrand.png"
              alt="enerbit"
            />
          </div>
        </div>
      </div>
      <div class="p-6">
        <span class="text-white"
          >© enerBit S.A.S. E.S.P. Todos los derechos reservados
          {{ new Date().getFullYear() }}</span
        >
      </div>
    </footer>
    <vue-final-modal v-model="isShowModal">
      <PXModalMark :onClose="closeModal" :data="renderMark" />
    </vue-final-modal>
  </div>
</template>
<script>
import PXModalMark from "@/components/PXModalMark.vue";
import { tratamientoDatos } from "@/helpers/markDownString.js";

export default {
  components: {
    PXModalMark,
  },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
    };
  },
  methods: {
    pushPage() {
      window.scrollTo(0, 0);
      this.$router.replace("/legal");
    },
    pushTycPage() {
      window.scrollTo(0, 0);
      this.$router.replace("/tyc");
    },
    goToSicepPage() {
      window.open(
        "https://sicep.xm.com.co/public-announcements/electronic-file/CP-ENBC2022-001/ENERBIT/ENBC/01%2f02%2f2023/31%2f12%2f2027",
        "_blank"
      );
    },
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    showPersonalData() {
      this.isShowModal = true;
      this.renderMark = tratamientoDatos;
    },
  },
};
</script>

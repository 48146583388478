<template>
  <div class="mt-16 ml-10 container">
    <nav class="text-white rounded-md w-full mb-4">
      <ol class="list-reset flex">
        <li>
          <router-link to="/legal" class="text-blue-600 hover:text-blue-700"
            >Legal</router-link
          >
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold"
            >></span
          >
        </li>
        <li>
          <a href="#" class="text-secondary-orange-300 font-bold"
            >Cumplimiento de reglas y Resolución CREG 080 de 2019
          </a>
        </li>
      </ol>
    </nav>
    <div class="content">
      <div>
        <p class="text-secondary-orange-300 font-bold text-5xl mb-5">
          Resolución CREG 080 de 2019
        </p>
      </div>

      <div>
        <p class="text-white text-xl">
          Resolución CREG 080 de 2019 – se establecen reglas generales de
          comportamiento de mercado para los agentes que desarrollen las
          actividades de los servicios públicos domiciliarios de energía
          eléctrica y gas combustible.
        </p>
        <br />
        <p class="text-secondary-orange-300 font-bold text-3xl">
          Declaración de cumplimiento de reglas de comportamiento
        </p>
        <br />
        <p class="text-white">Conoce aquí la declaración</p>
        <br />
        <button
          class="bg-secondary-orange-500 rounded-md text-white see-document mb-6"
          @click="openModal"
        >
          Declaración
          <i class="mdi mdi-eye-outline"></i>
        </button>
        <br />
        <p class="text-white">
          Si quieres conocer esta resolución ingresa aquí
        </p>
        <br />

        <a
          href="https://www.creg.gov.co/sites/default/files/2019-08-26-_mbr_taller_socializacion_avr.pdf"
          target="_blank"
          ><button
            class="bg-secondary-orange-500 rounded-md text-white see-document mb-6"
          >
            Resolución
            <i class="mdi mdi-eye-outline"></i></button
        ></a>
      </div>
    </div>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="markCc" />
  </vue-final-modal>
</template>
<script>
import PXModalMark from '@/components/PXModalMark.vue';
import { cambioDeComercializador } from '@/helpers/markDownString.js';
export default {
  name: 'CREG',
  components: {
    PXModalMark,
  },

  data() {
    return {
      isShowModal: false,
      markCc: cambioDeComercializador,
    };
  },

  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },
  },
  created() {
    this.$store.commit('showLegalButtons');
  },
};
</script>
<style scoped>
.content {
  width: 70%;
}

.see-document {
  height: 44px;
  width: 170px;
}
</style>

<template>
  <div>
    <section class="py-14 bg-gray-100" id="steps">
      <div class="container mx-auto px-5">
        <h2 class="text-4xl font-semibold text-center mb-5">
          Pasarte a enerBit es muy fácil
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
          <div class="card-steps text-center">
            <h3 class="text-4xl font-bold color-primary300 text-left">1</h3>
            <img
              src="@/assets/sdm/assets/img/paso1.png"
              class="w-full mb-6"
              alt=""
            />
            <p class="text-center color-secondary text-lg mb-4">
              Nos eliges como tu empresa de energía,
              <span class="font-bold"
                >nos envias foto de tu cédula, firma digital de la carta de
                aceptación y oferta</span
              >
            </p>
            <a
              class="btn-primary"
              href="https://bit.ly/SDM-eB"
              target="__blank"
              >Firmar carta de aceptación</a
            >
          </div>
          <div class="card-steps text-center">
            <h3 class="text-4xl font-bold color-primary300 text-left">2</h3>
            <img
              src="@/assets/sdm/assets/img/paso2.png"
              class="w-full mb-6"
              alt=""
            />
            <p class="text-center color-secondary text-lg mb-4">
              Nosotros formalizamos tu elección ante el operador de red y
              <span class="font-bold"
                >te cambiamos tu medidor por uno inteligente</span
              >
            </p>
          </div>
          <div class="card-steps text-center">
            <h3 class="text-4xl font-bold color-primary300 text-left">3</h3>
            <img
              src="@/assets/sdm/assets/img/paso3.png"
              class="w-full mb-6"
              alt=""
            />
            <p class="text-center color-secondary text-lg mb-4">
              ¡Disfruta de nuestro servicio!
              <span class="font-bold"
                >Descarga la app y empieza a ser parte de este voltaje</span
              >
            </p>
          </div>
        </div>
        <div class="text-center">
          <p class="mb-3 color-secondary300">
            *Ten presente que el cambio no es inmediato, de lo complicado nos
            encargamos nosotros.
          </p>
          <a class="btn-primary" href="/#/sdm/">Elijo enerBit</a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "StepsSection",
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

<template>
  <div class="mt-16 ml-10 h-screen container">
    <div :class="{ hidden: showButtons }" class="w-3/5">
      <nav class="text-white rounded-md w-full mb-16">
        <ol class="list-reset flex">
          <li>
            <router-link to="/legal" class="text-blue-600 hover:text-blue-700"
              >Legal</router-link
            >
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold"
              >></span
            >
          </li>
          <li>
            <a href="#" class="text-secondary-orange-300 font-bold"
              >Convocatorias
            </a>
          </li>
        </ol>
      </nav>
      <a
        class="text-white font-bold text-base border py-5 px-5"
        href="https://sicep.xm.com.co/public-announcements"
        target="__blank"
      >
        Ingreso a SICEP</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonCosts: [
        {
          text: "Ingreso a SICEP",
          to: "/legal/costos-tarifas/tarifas",
        },
      ],
      showButtons: false,
    };
  },
  methods: {
    hideButtons() {
      this.showButtons = true;
    },
  },

  created() {
    this.$store.commit("showLegalButtons");
  },
};
</script>
<style scoped></style>

<template>
  <div v-if="showPlans">
    <div class="text-center lg:w-2/3 m-auto text-white mt-10">
      <p class="lg:text-4xl text-2xl font-normal leading-10 mb-4">
        {{ titlePlans }}
      </p>
      <p
        class="lg:text-4xl text-2xl font-normal leading-10 business-descrpiption"
      >
        {{ descriptionPlans }}
      </p>
    </div>
    <div
      class="flex text-center w-5/6 m-auto h-fit overflow-hidden lg:flex-row flex-col"
    >
      <div
        v-for="plan in plans"
        :key="plan.title"
        class="overflow-hidden w-full lg:w-2/5 mt-10 flex-auto mr-3"
      >
        <div
          class="rounded-full h-20 pt-4 pr-12 title-plan relative m-auto w-64 lg:w-3/6"
        >
          <p class="font-bold text-5xl leading-10 ml-10 text-white">
            {{ plan.title }}
          </p>
        </div>
        <div class="w-full -mt-8 pt-16 pr-0 pb-12 pl-0 rounded-md bg-white">
          <p
            class="text-2xl text-indigo-900 font-normal leading-7 mb-3 text-neutral-700"
          >
            {{ plan.description }}
          </p>
          <img
            class="mt-8 mb-5 inline-block h-56"
            :src="plan.img"
            alt="fijabit"
          />
          <p
            class="text-2xl text-indigo-900 font-normal leading-7 mb-3 text-neutral-700"
          >
            {{ plan.question }}
          </p>
          <button
            @click="planDetail(plan)"
            class="button-plan text-center text-white bg-warning-500"
          >
            {{ plan.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    :style="{
      'background-image': `url('${backgroundImage}')`,
      'background-position': backgroundPosition,
    }"
    class="flex text-2xl font-normal leading-7 mb-3 bg-primary-500 plan-detail w-full absolute lg:relative pt-4 pb-24 pl-2 overflow-hidden lg:pl-0"
    v-if="!showPlans"
  >
    <div class="overflow-hidden w-full">
      <div class="text-2xl font-normal leading-7 text-left">
        <img src="@/assets/img/back.svg" alt="" class="inline-block" />
        <span
          class="text-4xl font-normal leading-10 text-white cursor-pointer"
          @click="hidePlanDetail"
          >Volver</span
        >
      </div>
      <div class="lg:-mt-8 lg:mb-16 font-normal leading-7 mt-8">
        <div
          class="rounded-full h-20 pt-4 pr-12 relative m-auto text-center bg-primary-300 lg:w-3/12 w-32"
        >
          <p class="font-bold lg:text-5xl leading-10 pl-8 lg:pl-10 text-white">
            {{ planSelected.title }}
          </p>
        </div>
      </div>
      <component :is="component" />

      <div class="mt-12 m-auto text-center text-white">
        <p class="text-4xl font-normal leading-10">¡Podrías ahorrar aún más!</p>
        <p class="mt-4 font-normal leading-10">
          Si consumes eficientemente y sigues nuestros tips
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import PxFooter from "@/components/PxFooter.vue";
import PxPlanFijaBit from "@/components/PxPlanFijaBit.vue";
import PxPlanDinaBit from "@/components/PxPlanDinaBit.vue";
import PxPlanFijabitHome from "@/components/PxPlanFijabitHome.vue";
import PxPlanDinaBitHome from "@/components/PxPlanDinaBitHome.vue";

export default {
  name: "PxPlans",
  components: { PxFooter, PxPlanFijaBit },
  data() {
    return {
      showPlans: true,
      component: "",
      planSelected: {},
      backgroundImage: "",
      backgroundPosition: "",
    };
  },
  props: {
    titlePlans: {
      type: String,
    },
    descriptionPlans: {
      type: String,
    },
    plans: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    planDetail(plan) {
      this.showPlans = false;
      const rawObject = { ...plan };
      this.planSelected = rawObject;
      if (
        rawObject["title"] == "fijaBit" &&
        rawObject["parent"] == "business"
      ) {
        this.component = PxPlanFijaBit;
        this.backgroundImage = require("@/assets/img/fijaBitBackground.svg");
        this.backgroundPosition = "100% 200%";
      }
      if (
        rawObject["title"] == "dinaBit" &&
        rawObject["parent"] == "business"
      ) {
        this.component = PxPlanDinaBit;
        this.backgroundImage = require("@/assets/img/dinaBitBackground.svg");
        this.backgroundPosition = "0% 68%";
      }
      if (rawObject["title"] == "fijaBit" && rawObject["parent"] == "homes") {
        this.component = PxPlanFijabitHome;
        this.backgroundImage = require("@/assets/img/fijaBitBackground.svg");
        this.backgroundPosition = "100% 200%";
      }
      if (rawObject["title"] == "dinaBit" && rawObject["parent"] == "homes") {
        this.component = PxPlanDinaBitHome;
        this.backgroundImage = require("@/assets/img/dinaBitBackground.svg");
        this.backgroundPosition = "0% 68%";
      }
    },
    hidePlanDetail() {
      this.showPlans = true;
    },
  },
};
</script>
<style scoped>
.business-descrpiption {
  color: #04d15f;
}

.plan-description {
  border-radius: 33px 0px 0px 33px;
  padding: 55px 9.55% 55px 8.36%;
}

.plan-detail {
  background-repeat: no-repeat !important;
}

.digital-list {
  padding-inline-start: 20px;
}

.button-plan {
  cursor: pointer;
  width: fit-content;
  height: 48px;
  box-shadow: 0px 4px 21px #f79009;
  border-radius: 5px;
  padding: 2.56px 57px 0px 57px;
}

.title-plan {
  background-color: #8b78b0;
}
</style>

<template>
  <div class="mt-16 ml-10 h-screen">
    <div>
      <nav class="text-white rounded-md w-full mb-4">
        <ol class="list-reset flex">
          <li>
            <router-link
              class="text-blue-600 hover:text-blue-700"
              @click="showLegal()"
              :to="{ path: '/legal' }"
              >Legal</router-link
            >
          </li>
        </ol>
      </nav>
      <div class="flex-container">
        <div class="column">
          <px-legal-button
            v-for="data in buttonLegalData"
            :key="data.text"
            :text="data.text"
            :to="data.to"
          />
          <div>
            <button
              @click="showWarranty"
              class="legal-button text-lg leading-8"
            >
              Garantías mecanismos de cubrimiento
            </button>
          </div>
        </div>
        <div class="column">
          <div>
            <button @click="showCCU" class="legal-button text-lg leading-8">
              Contrato de condiciones uniformes CCU
            </button>
          </div>
          <div>
            <button
              @click="showOHSPolicy"
              class="legal-button text-lg leading-8"
            >
              Política SST
            </button>
          </div>
          <div>
            <a
              :href="'https://enerbit.co/consumos-estacionales/'"
              target="_blank"
              class="legal-button text-lg leading-8"
            >
              Registro de consumos estacionales
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="renderMark" />
  </vue-final-modal>
</template>

<script>
import PxLegalButton from "../components/PxLegalButton.vue";
import PXModalMark from "@/components/PXModalMark.vue";
import { ccu, warranty, OHSPolicy } from "@/helpers/markDownString.js";

export default {
  components: { PxLegalButton, PXModalMark },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
      buttonLegalData: [
        {
          text: "Cambio de comercializador",
          to: "/cambio-comercializador",
        },
        {
          text: "Costos y tarifas",
          to: "/costos-tarifas",
        },
        {
          text: "Cumplimiento de reglas y Resolución CREG 080 de 2019",
          to: "/creg",
        },
        {
          text: "Convocatorias",
          to: "/convocatorias",
        },
        {
          text: "Derechos, obligaciones y mecanismos de protección",
          to: "/derechos",
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    showWarranty() {
      this.isShowModal = true;
      this.renderMark = warranty;
    },

    showOHSPolicy() {
      this.isShowModal = true;
      this.renderMark = OHSPolicy;
    },

    showCCU() {
      this.isShowModal = true;
      this.renderMark = ccu;
    },
  },
};
</script>

<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1400px) {
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 1399px) {
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>

<template>
  <div>
    <vue-final-modal
      :click-to-close="true"
      v-model="showPqrModal"
      classes="modal-container"
      content-class="modal-content h-full"
    >
      <button class="modal__close" @click="changeModalState()">
        <i class="mdi mdi-window-close"></i>
      </button>
      <div class="modal__content py-5 px-5 overflow-y-auto">
        <div class="flex flex-row">
          <div class="justify-center items-center text-invoice">
            <h1 class="text-center text-primary-500 text-4xl">
              ¿Necesitas información?
            </h1>
            <span class="text-center text-neutral-700 text-xl font-normal"
              >Peticiones, información de trámites o procedimientos
            </span>

            <PxFormPQR />
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import PxFormPQR from "@/components/PxFormPQR.vue";

export default {
  name: "PxPQRModal",
  components: { PxFormPQR },
  computed: {
    showPqrModal() {
      return this.$store.state.showPqrModal;
    },
  },
  methods: {
    changeModalState() {
      this.$store.commit("showPqrModal");
    },
  },
};
</script>
<style scoped>
.take-control {
  cursor: pointer;
  width: 310px !important;
  height: 61px;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: url("../assets/img/click.svg");
  background-position: 255px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.modal__content {
  max-width: 550px !important;
}
</style>

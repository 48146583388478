<template>
  <div class="mark-down">
      <Markdown :source="tratamientoDatos" class="prose md:prose-lg lg:prose-lg" />
    </div>
</template>

<script>
import { tratamientoDatos } from "@/helpers/markDownString.js";
import Markdown from 'vue3-markdown-it';
export default {
  props: ['onClose', 'data'],

  components: {
    Markdown,
  },
  data() {
    return {
      tratamientoDatos:  tratamientoDatos,
    };
  },
};
</script>
<style scoped>

.mark-down {
  background: #fff;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  width: fit-content;
  margin: auto;
}
</style>


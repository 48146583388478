export const cambioDeComercializador = `# CAMBIO DE COMERCIALIZADOR

## ENERBIT S.A.S. E.S.P.

### PROCEDIMIENTOS DE CAMBIO DE COMERCIALIZADOR

#### Resolución CREG 156 de 2011 (Reglamento de Comercialización) y Resolución CREG 080 de 2019

El presente procedimiento se aplica en los mercados atendidos por las empresas del grupo CELSIA: ENERBIT S.A.S. , CELSIA COLOMBIA S.A E.S.P CELSIA TOLIMA S.A E.S.P. y CETSA S.A E.S.P., en adelante enerBit.

El Operador de Red - OR es el agente del mercado que se encarga de la planeación de la expansión, las inversiones, la operación y el mantenimiento de las redes de transmisión y distribución de energía eléctrica, a las cuales se conectan los usuarios de este servicio.

Cada cliente debe contratar la prestación del servicio de energía eléctrica con un comercializador, agente que adquiere la energía en el mercado mayorista y la vende a usuarios finales, regulados o no regulados.

EnerBit ofrece contratos de venta de energía con destino a clientes de los mercados regulado y no regulado. Los clientes residenciales se clasifican de acuerdo con su estratificación socioeconómica y los clientes no residenciales se clasifican de acuerdo con la última versión vigente de la “Clasificación Industrial Internacional Uniforme de Todas las Actividades Económicas - CIIU” de las Naciones Unidas.

El costo unitario de prestación del servicio a usuarios regulados de la empresa enerBit, se publica mensualmente en la página web: [www.enerbit.co]

Para efectos del presente procedimiento, los comercializadores deberán emplear la debida diligencia en la gestión y prestación de los servicios, reportando información cierta, clara y oportuna a los usuarios que componen la demanda de estos servicios, con la finalidad de garantizar la atención y el respeto de los derechos de los usuarios.

Conforme a lo establecido en los Artículos 54 al 59 del Capítulo II de la Resolución CREG 156 de 2011 los requisitos y el procedimiento para el cambio de comercializador es el siguiente:

- Para el cambio de comercializador, el nuevo comercializador verificará que el usuario que le ha solicitado el servicio cumpla los siguientes requisitos:

- Haber cumplido los tiempos de permanencia mínima con el comercializador que le presta el servicio.

  - Estar a paz y salvo con el comercializador que le presta el servicio.

- El usuario interesado contactará al agente que haya elegido como nuevo prestador del servicio y lo habilitará expresamente para gestionar el cambio de comercializador.

- El comercializador que le presta el servicio al usuario no podrá hacer exigible la participación del mismo en el proceso de cambio de comercializador y se entenderá directamente con el nuevo prestador del servicio.

- Paz y salvo. Para la expedición del paz y salvo que se requiere para el cambio de comercializador, se deberán observar las siguientes reglas:

  - El usuario, directamente o a través del nuevo comercializador, le solicitará al comercializador que le presta el servicio un documento que certifique que se encuentra a paz y salvo por conceptos relacionados directamente con la prestación del servicio.
  - El paz y salvo corresponderá a los consumos facturados al usuario. Por consiguiente, no se requerirá paz y salvo por consumos no facturados al usuario por parte del comercializador que le presta el servicio.
  - El comercializador que le presta el servicio deberá dar respuesta clara a la solicitud de paz y salvo dentro de los cinco (5) días hábiles siguientes al día en que se hace la solicitud. En caso de que el usuario no se encuentre a paz y salvo con el comercializador que le presta el servicio, éste deberá dar respuesta por escrito, dentro del plazo señalado, indicando claramente los números de referencia de las facturas en mora, el período de suministro correspondiente y el valor pendiente de pago del respectivo usuario.

El documento que se emita como paz y salvo deberá contener, como mínimo, los siguientes elementos:

- Identificación del comercializador que le presta el servicio al usuario.
- Fecha de expedición.
- Identificación del usuario: incluyendo el nombre, NIU, y la dirección del predio para el cual se solicita el paz y salvo.
- Último período facturado y la lectura correspondiente.
- Cartera corriente: números de referencia de las facturas emitidas y que aún no se han vencido, indicando para cada una el concepto, valor y fecha de vencimiento.
- Acuerdos de pago: informar sobre los acuerdos de pago firmados con el usuario, indicando las cuotas pendientes y el saldo adeudado, discriminando el capital y los intereses.
- Procesos pendientes por resolver: indicar si el usuario tiene o no procesos de investigación en curso por posibles fraudes, que en caso de resolverse a favor de la empresa generarían nuevas obligaciones por consumos dejados de facturar.

  Nota: El paz y salvo que expida el comercializador que atiende al usuario, no perderá validez para efectos del cambio de comercializador, si aquél omite incluir alguno de los elementos señalados anteriormente. Sin perjuicio de lo anterior, el comercializador que prestaba el servicio podrá hacer uso de los mecanismos y acciones legales para exigir del usuario el pago de los valores que éste le pueda adeudar al momento del cambio de comercializador.

- Registro de la Frontera Comercial y adecuación del Sistema de Medida: el nuevo comercializador solicitará al Administrador del Sistema de Intercambios Comerciales – ASIC el registro de la Frontera de Comercialización para Agentes y Usuarios, y procederá a realizar, en caso de ser necesario, el cambio o la adecuación del Sistema de Medida de la respectiva Frontera Comercial y las disposiciones sobre medición establecidas por la CREG.

- Mecanismos para asegurar el pago: para asegurar el pago de los consumos facturados y/o realizados y no facturados entre la expedición del Paz y Salvo y el momento del cambio de Comercializador, se podrá utilizar alguno de los siguientes mecanismos:

- El usuario deberá realizar el pago de los consumos facturados y garantizar, con un título valor, el pago de los consumos realizados y no facturados.

- El usuario deberá realizar el pago de los consumos facturados y el prepago de los consumos realizados y no facturados. El consumo no facturado se estimará con base en el promedio de consumo del usuario durante los últimos seis meses.

Si queda un saldo a favor del usuario, éste podrá autorizar al nuevo comercializador para que lo reclame al comercializador que le prestaba el servicio y lo abone al pago de la siguiente factura.

- Previo acuerdo entre el usuario y el nuevo comercializador, éste asumirá el pago de los consumos facturados y el de los consumos realizados y no facturados. El nuevo comercializador deberá cobrar al usuario el valor de los pagos que haya realizado por los conceptos antes mencionados.

  Nota: La lectura del medidor en la fecha de registro de la Frontera Comercial, y por tanto del momento en que se hace efectivo el cambio de comercializador, deberá ser informada al nuevo comercializador por parte del comercializador que le prestaba el servicio al Usuario, para efectos de determinar los consumos realizados y no facturados.

- Cambio de comercializador en caso de Fronteras de Comercialización para Agentes y Usuarios que agrupan varios Usuarios: el cambio de comercializador de los usuarios cuyos consumos se encuentren agrupados en una Frontera de Comercialización para Agentes y Usuarios al momento de publicación de este Reglamento en el diario oficial se regirá por las siguientes reglas:

- Si uno o varios usuarios desean cambiar de prestador del servicio, se deberá instalar una nueva Frontera de Comercialización para Agentes y Usuarios para cada uno de ellos, cumpliendo los requerimientos establecidos en este Reglamento y en el Código de Medida, definido en la Resolución CREG 038 de 2014, y las disposiciones sobre medición contenidas en el numeral 7 del Anexo General de la Resolución CREG 070 de 1998, o en las normas que las modifiquen o sustituyan. Para el efecto no se podrá exigir al usuario la construcción de nuevas redes de uso.

- Si todos los usuarios desean ser atendidos por un mismo prestador del servicio, podrán mantener una sola Frontera de Comercialización para Agentes y Usuarios que agrupe sus consumos. Para el efecto el nuevo comercializador podrá registrar dicha Frontera Comercial, siempre que cumpla los demás requisitos establecidos en este Reglamento. Si todos los usuarios están de acuerdo en no continuar agrupando la medida, podrán cambiar de prestador del servicio, para lo cual cada uno deberá seleccionar el prestador del servicio y cumplir con los requisitos que establece el Código de Medida cuando sea del caso.

### Mecanismos de Presentación Directa de Solicitudes

EnerBit tiene habilitados actualmente los siguientes mecanismos para la presentación de las solicitudes objeto del presente documento:

- Correo electrónico: escribenos@enerbit.co
- Peticiones, Quejas y Reclamos: escribenos@enerbit.co
- Solicitudes escritas:
  - Radicación Oficina C.C. Palmetto Local 115. Cali-Valle del Cauca

## PROCEDIMIENTOS DE CAMBIO DE COMERCIALIZADOR QUE REPRESENTA LA FRONTERA COMERCIAL PARA ENTREGA DE EXCEDENTES DE UN AGPE

### Resolución CREG 156 de 2011 (Reglamento de Comercialización), Resolución CREG 080 de 2019 y Resolución CREG 135 de 2021 y Resolución CREG 174 de 2021

El usuario Autogenerador a Pequeña Escala – AGPE debe escoger el comercializador al cual venderá o entregará los excedentes de energía, entre las alternativas existentes, puede ser el comercializador que le presta el servicio público domiciliario de energía eléctrica como otro diferente.

De acuerdo con el Artículo 4 y el Artículo 10 de la Resolución CREG 135 de 2021, en el caso que el usuario AGPE elija al comercializador que le presta el suministro de energía eléctrica, el comercializador adquiere la obligación de recibir o comprar los excedentes de energía, la relación para la entrega o venta de excedentes se regirá por las disposiciones que se establezcan en la Resolución CREG 135 de 2021; por otro lado, de acuerdo con el Artículo 12 de la Resolución CREG 135 de 2021, si el usuario AGPE selecciona un comercializador diferente al que le suministra el servicio de energía eléctrica la relación para la entrega o venta de excedentes se regirá por lo dispuesto en el derecho civil y comercial en lo que respecta a los acuerdos de venta y las normas de protección al consumidor.

Asimismo, el Artículo 34 de la Resolución CREG 135 de 2021 establece que el comercializador que le presta el servicio de energía eléctrica al usuario AGPE no podrá oponerse ni hacer exigible la participación en el cambio de agente, ni desplegará acciones y omisiones que tengan por objeto o efecto la limitación del derecho que tienen el usuario AGPE sobre el cambio de comercializador para le entrega o venta de excedentes. Así mismo, la normatividad vigente no contempla una permanencia mínima que el usuario AGPE debe tener con el comercializador para la entrega o venta de excedentes.

Por otro lado, de acuerdo con el Parágrafo 3 del Artículo 26 de la Resolución CREG 174 de 2021, es obligación del usuario AGPE tener una relación de entrega o venta de excedentes con un agente que represente dichos excedentes y, en el caso que no se tenga una relación consolidada, los excedentes no serán remunerados en el caso que sean entregados a la red.

De acuerdo con lo establecido en el Artículo 21 de la Resolución CREG 174 de 2021, y la normatividad vigente, los requisitos y el procedimiento para el cambio de comercializador que representa a frontera comercial para entrega de excedentes de un AGPE, es el siguiente:

1. Requisitos y deberes:

a) El usuario AGPE interesado deberá conocer la normatividad vigente para la entrega o venta de excedentes, relacionadas con la actividad de autogeneración a pequeña escala; de igual manera conocer los tiempos regulados que exige la normatividad y que deberá tener en cuenta en el procedimiento de cambio de comercializador para la entrega o venta de excedentes.

b) El usuario AGPE interesado deberá conocer las disposiciones y condiciones establecidas por el nuevo comercializador para la entrega o venta de excedentes, condiciones que se encuentran en la página web de enerBit.

2. Procedimiento:

a) El usuario AGPE interesado deberá contactar al nuevo comercializador para habilitarlo expresamente para comenzar la gestión del cambio de agente.

b) El usuario AGPE deberá aceptar las condiciones y disposiciones que establece el nuevo comercializador para la entrega o venta de excedentes, mediante la suscripción del formato que establece el Artículo 14.2 de la Resolución CREG 135 de 2021, formato que se encuentra publicado en la página web de Celsia.

c) Una vez el usuario AGPE manifieste y acepte las decisiones y disposiciones que establece el nuevo comercializador para la entrega o venta de excedentes mediante el diligenciamiento y entrega del anterior formato, el nuevo comercializador deberá enviarle el acuerdo especial que se establece en el Artículo 13 de la Resolución CREG 135 de 2021 para acordar las condiciones de entrega o venta de excedentes.

d) Verificación de la frontera comercial: el nuevo comercializador verificará que las condiciones técnicas del sistema de medida cumplan con la normatividad vigente en el Código de Medida y las disposiciones establecidas por la CREG para las fronteras de generación (de acuerdo con el Artículo 19 de la Resolución CREG 174 de 2021), y, en caso necesario, realizará las adecuaciones necesarias para dar cumplimiento a la normatividad vigente.

e) Registro de la frontera comercial: el nuevo comercializador procederá a registrar la frontera comercial de generación en el ASIC (de acuerdo con el Artículo 20 de la Resolución CREG 174 de 2021).

f) Una vez se ha cumplido con las disposiciones sobre la verificación y registro de la frontera comercial como con la suscripción e inicio del acuerdo especial para la entrega o venta de excedentes del usuario AGPE al nuevo comercializador, el usuario AGPE deberá informar al anterior comercializador que suscribió un nuevo acuerdo especial para la entrega o venta de excedentes. En este evento, el usuario AGPE deberá informar al anterior comercializador mediante la ventanilla única que este administra con una antelación de 15 días hábiles a la fecha de vencimiento del próximo periodo de facturación; por el contrario, si el usuario AGPE informa con una antelación menor, la finalización del contrato y el reconocimiento de excedentes se efectuará en el siguiente periodo de facturación al informado.

g) Una vez ha terminado el acuerdo especial entre el usuario AGPE y el anterior comercializador, este último deberá emitir paz y salvo de acuerdo con el Artículo 35 de la Resolución CREG 135 de 2021 con el propósito de evitar reclamaciones posteriores por parte del usuario AGPE.

En el evento que se tenga saldos a favor del usuario AGPE, serán responsabilidad del anterior comercializador reconocerlos dentro de las responsabilidades adquiridas en el acuerdo especial firmado con el usuario AGPE.

### Mecanismos de Presentación Directa de Solicitudes

EnerBit tiene habilitados actualmente los siguientes mecanismos para la presentación de las solicitudes objeto del presente documento:

- Correo electrónico: escribenos@enerbit.co
- Peticiones, Quejas y Reclamos: escribenos@enerbit.co
- Solicitudes escritas:
  - Radicación Oficina C.C. Palmetto Local 115. Cali-Valle del Cauca
`;

export const ccu = `# CONTRATO DE PRESTACIÓN DEL SERVICIO PÚBLICO DOMICILIARIO DE ENERGÍA ELECTRICA CON CONDICIONES UNIFORMES

ENERBIT S.A.S. E.S.P., en adelante ENERBIT, es una empresa de servicios públicos privada constituida como sociedad por acciones simplificada, con domicilio principal en la ciudad de Medellín - Antioquia, por medio del presente contrato define las CONDICIONES UNIFORMES en las que prestará el servicio público domiciliario de energía eléctrica a una persona natural o jurídica en su calidad de propietario, poseedor, tenedor, arrendatario y/o usuario final de un bien determinado, en adelante EL (LOS) USUARIO(S).

## Capítulo I

Objeto del Contrato y disposiciones generales

### Cláusula 1

Objeto del contrato: El objeto del presente contrato es definir las condiciones uniformes de acuerdo con las cuales ENERBIT, presta o prestará el servicio público domiciliario de energía eléctrica a los Usuarios actuales y futuros, en condiciones de calidad y eficiencia a cambio de un precio en dinero, en el territorio colombiano. 

### Cláusula 2

Régimen Jurídico: El presente contrato se rige, y por tanto hacen parte del mismo,  lo dispuesto en las Leyes 142 y 143 de 1994, y demás normas que las desarrollen, adicionen, reglamenten o modifiquen; las regulaciones y reglamentaciones expedidas por la Comisión de Regulación de Energía y Gas, el Ministerio de Minas y Energía u otra entidad competente; las normas de construcción y técnicas vigentes; el Código de Comercio; el Código de Procedimiento Administrativo y de lo Contencioso Administrativo y el Código Civil; así como también por  las cláusulas especiales o cualquier acuerdo que se pacte con LOS USUARIOS.

PARÁGRAFO: Las adiciones, modificaciones o derogatorias de las normas que se surtan con posterioridad, se entienden incorporadas al presente contrato a partir de su entrada en vigencia, sin que para el efecto sea necesario expedir un nuevo contrato o su reforma, salvo cuando ENERBIT lo estime conveniente, a efectos de dar claridad sobre el mismo.

### Cláusula 3

Partes del Contrato: Son partes en el presente contrato de condiciones uniformes de servicios públicos, por una parte, ENERBIT y por la otra, EL USUARIO y todo aquel que lo suceda en sus derechos reales sobre el inmueble, a cualquier título, por acto entre vivos o causa de muerte, por convenio o disposición legal o judicial. Los poseedores y tenedores de todo o de la parte del bien beneficiado con el servicio y los suscriptores o usuarios o consumidores a que alude el artículo 14.33 de la Ley 142 de 1994, quedan sometidos a las reglas del presente Contrato y por ende serán solidarios en los derechos y obligaciones derivadas del mismo.

### Cláusula 4

Destinación del Servicio: El servicio público de energía eléctrica que se suministra será para uso únicamente del bien para el cual se solicitó el servicio y no podrá ser cedido, vendido, facilitado a terceros o dársele un uso diferente sin el cumplimiento de los requisitos legales para el efecto.

### Cláusula 5

Duración del contrato: El presente contrato es a término indefinido y podrá darse por terminado de común acuerdo entre las partes y de conformidad con lo establecido en la regulación vigente.

### Cláusula 6

Celebración y ejecución del Contrato: Existe contrato de servicios públicos desde que ENERBIT define las condiciones uniformes en las que está dispuesta a prestar el servicio y el USUARIO, solicita recibir el servicio, si el solicitante y el inmueble se encuentran en las condiciones previstas por la normatividad que regula la prestación de los servicios públicos domiciliarios o por aquella especialmente establecida por ENERBIT para la conexión al respectivo servicio , y el servicio queda efectivamente instalado por ENERBIT o por quien ésta autorice.

### Cláusula 7

Solidaridad: Los propietarios, tenedores o poseedores del inmueble en el que se presta el servicio, los demás usuarios, beneficiarios y los suscriptores, son solidarios en sus obligaciones y derechos del presente contrato de conformidad con el artículo 130 de la Ley 142 de 1994, modificado por el artículo 18 de la Ley 689 de 2001 o aquellas que lo adicionen, modifiquen o sustituyan.

#### 7.1

Rompimiento de la Solidaridad: se entenderá que existe rompimiento de solidaridad en los siguientes eventos:

##### 7.1.1

Cuando el USUARIO incumpla su obligación de pagar oportunamente los servicios facturados dentro del término previsto en la factura; el cual no excederá de tres (3) periodos, y ENERBIT incumpla su obligación de suspender el servicio a partir de la fecha informada en la factura como aviso de suspensión.

##### 7.1.2

Cuando se denuncie la existencia del contrato de arrendamiento del inmueble objeto de la prestación del servicio de energía eléctrica en los términos previstos en la Ley 820 de 2003 y el Decreto 3130 de 2003 o aquellas normas que las modifiquen, adicionen o sustituyan, siempre que la denuncia sea aceptada por ENERBIT. En este caso, se entenderá que la solidaridad establecida en el artículo 130 de la Ley 142 de 1994 modificada por el artículo 18 de la Ley 689 de 2001, no operará para los conceptos cubiertos por el valor de la garantía constituida, siendo responsable del pago y del cumplimiento de las mencionadas obligaciones el arrendatario a partir del vencimiento del período de la factura en que se hizo la denuncia y por el término de duración del contrato de arrendamiento. Lo mismo ocurre respecto de las prórrogas del contrato, siempre y cuando sean avisadas a ENERBIT y se allegue copia del documento en que conste lo pertinente o se realice una nueva denuncia del contrato, allegando en todo caso, el reajuste a la garantía. En caso de no pago, ENERBIT podrá hacer exigible la garantía constituida; no obstante, si la misma fuere insuficiente para cubrir la obligación de pago del servicio de energía, ENERBIT queda facultada para adelantar las acciones legales a que haya lugar.

En todo caso, para la denuncia del contrato de arrendamiento y la constitución de garantías o depósitos, se deberán seguir los lineamientos establecidos la Ley 820 de 2003 y el Decreto 3130 de 2003 o aquellas normas que las modifiquen, adicionen o sustituyan. 

En caso de terminación del contrato de arrendamiento, ENERBIT autorizará a la institución financiera respectiva a devolver al interesado las sumas de dinero depositadas con ocasión de la denuncia del contrato de arrendamiento; no obstante, de dichas sumas ENERBIT podrá descontar el valor de los servicios prestados hasta la fecha de terminación del contrato de arrendamiento.


## Capítulo II

Derechos y obligaciones de las partes

### Cláusula 8

Derechos del Usuario: Sin perjuicio de los derechos establecidos por la ley o la regulación, son derechos del USUARIO los siguientes:

a) Solicitar y obtener el servicio público domiciliario de energía eléctrica en su modalidad  pospago, siempre y cuando cumpla con las condiciones uniformes establecidas en el presente contrato.
b) Exigir que no se vulneren ni desconozcan por parte de ENERBIT los derechos y garantías consagrados en las Leyes 142 y 143 de 1994, en las normas de carácter general expedidas por la Comisión de Energía y Gas (CREG), y demás autoridades competentes, así como en las normas que las complementen, adicionen, modifiquen o sustituyan, constituyéndose en el mínimo de derechos y garantías del USUARIO.
c) Recibir un trato igualitario por parte de ENERBIT en desarrollo del Contrato de Prestación del Servicio Público de Energía Eléctrica, sin distinciones a las derivadas de las condiciones y características técnicas de la prestación del servicio.
d) Obtener los bienes y servicios ofrecidos por ENERBIT en la calidad y cantidad definidas regulatoriamente, siempre que no se perjudiquen terceros y que el USUARIO asuma los costos correspondientes.
e) Conocer el presente contrato o las modificaciones que se le hagan al mismo, y obtener una copia física, en medio magnético o cualquier forma de transmisión de datos, cuando así lo solicite.
f) Presentar reclamación por falla en la prestación del servicio en los términos de los artículos 136 y 137 de la Ley 142 de 1994.
g) Contar con medición periódica del consumo a través de instrumentos idóneos, salvo las excepciones contempladas en la normatividad vigente, o cuando, por las condiciones de la prestación del servicio, se requiera calcular el consumo a través de las metodologías previstas en el presente Contrato. 
h) Recibir oportunamente la factura del servicio público de energía eléctrica a su cargo, y acceder a ella a través de los medios de transmisión de datos que disponga ENERBIT. En caso de que la factura no pueda ser entregada directamente por ENERBIT por razones ajenas a su voluntad, al USUARIO se le informará con cinco (5) días hábiles antes de la fecha de vencimiento de la factura que ésta se encuentra a su disposición en un lugar de acceso al público. El hecho de no recibir la factura no exonera a EL USUARIO de la obligación de atender el pago. 
i) Ser tratado con el respeto y la consideración debida a la dignidad de la persona humana.
j) Recibir atención especial y preferente si se trata de personas en situación de discapacidad, niños, niñas, adolescentes, mujeres gestantes o adultos mayores, y en general de personas en estado de indefensión o de debilidad manifiesta de conformidad con el artículo 13 de la Constitución Política.
k) Presentar peticiones, quejas y recursos contra los actos relativos al contrato uniforme de prestación del servicio. Lo anterior podrá ser adelantados por cualquier medio tecnológico o electrónico disponible en ENERBIT.
l) Obtener respuesta oportuna y eficaz a sus peticiones en los plazos establecidos para el efecto.
m) Que ENERBIT le notifique de manera oportuna por cualquier medio de contacto y/o en la factura que se le sea remitida, la fecha de suspensión del servicio en caso de mora en el pago del mismo.
n) Presentar unilateralmente por los medios dispuestos por ENERBIT solicitud de suspensión del servicio a ENERBIT, acompañada de la autorización de terceros cuando estos resulten afectados con dicha petición. 
o) Obtener la reconexión del servicio, dentro del término establecido en la ley o por regulación, a partir de la fecha en que se verifique el pago o haya desaparecido la causal de suspensión del servicio.
p) Verificar el funcionamiento de sus equipos de medida, cuando lo considere necesario.
q) Conocer las causas por las cuales se requiere el retiro por parte de ENERBIT del equipo de medida. 
r) Solicitar asesoría técnica en un lapso de quince (15) minutos, de cualquier persona, en caso que ENERBIT no hubiere informado con anticipación sobre la visita técnica, para que sirva de testigo en cualquier intervención técnica a realizar por parte de ENERBIT.
s) A que ENERBIT actualice oportunamente ante las bases de datos y centrales de riesgo la información sobre deudas o cualquier novedad relacionada con su comportamiento de pago. La permanencia de los registros será la que definan las disposiciones legales que regulen la materia. 
t) Contar con la garantía de buen funcionamiento del equipo de medida, cuando este sea suministrado por ENERBIT, por un periodo no inferior al que establezcan las normas sobre la materia o las que otorgue el fabricante de estos bienes.
u) Cambiar de comercializador cuando se cumplan los requisitos exigidos por la regulación o de común acuerdo con ENERBIT.
v) Ejercer sus derechos frente a sus datos personales de acuerdo con lo previsto en el artículo 8 de la Ley 1581 de 2012, o aquellas que la modifiquen, adicionen o sustituyan, según las condiciones establecidas en la Política de Tratamiento de Datos Personales de ENERBIT la cual puede ser consultada en la página web: www.enerbit.co.
w) Solicitar que le notifiquen uno o varias decisiones empresariales a través del correo electrónico.
x) Permitir la inclusión de conceptos diferentes a la prestación del servicio dentro de la factura, los cuales sean pactados previamente 


### Cláusula 9

Derechos de ENERBIT:

a) Negar la prestación del servicio cuando no se cumpla con las condiciones previstas en este contrato y/o en la regulación vigente.
b) Realizar en cualquier momento visitas de control al USUARIO de revisión, inspección e investigaciones pertinentes a fin de verificar el buen estado de funcionamiento de los equipos que se utilizan para medir el consumo, la condición técnica de las instalaciones eléctricas externas como acometida, puesta a tierra, caja y demás elementos utilizados, así como cualquier bien asociado con la prestación del servicio. 
c) Registrar los datos relacionados y asociados con la prestación del servicio de energía eléctrica a un inmueble, a través de cualquier medio fotográfico, fílmico, etc.  
d) Exigir las adecuaciones y reparaciones que se estimen necesarias para la correcta utilización del servicio, para garantizar la exactitud y precisión de la medida o para determinar el estado de las conexiones, las cuales deberán ser realizadas por el USUARIO en un término no superior a un periodo de facturación siguiente a su comunicación por parte de ENERBIT.
e) Exigir a los USUARIOS la instalación de equipos de medida conforme con las condiciones señaladas en este Contrato, norma técnica del Operador de Red del mercado del que haga parte EL USUARIO y lo dispuesto en el Código de medida o las normas que lo modifiquen, sustituyan o deroguen. 
f) Realizar el cambio de medidor cuando (i) se establezca que el funcionamiento no permite determinar en forma adecuada los consumos; y (ii) cuando el desarrollo tecnológico ponga a su disposición equipos de medida más precisos.
g) Retirar el equipo de medida para que sirva como prueba en cualquier actuación administrativa iniciada por ENERBIT, a fin de establecer técnicamente la existencia de una irregularidad dejando constancia de ello en un documento diseñado para el efecto. 
h) Adoptar acciones eficaces para que no se alteren los equipos de medida e instalar elementos de control y potencia al nivel de cada equipo de medida con el fin de proteger el equipo de medida conforme a la carga instalada.
i) Solicitar a la autoridad correspondiente el apoyo para que cesen los actos que entorpezcan o amenacen perturbar, en cualquier tiempo, el ejercicio de sus derechos.
j) Exigir al USUARIO la no modificación de la modalidad de uso del bien inmueble convenido para el servicio sin informarlo a ENERBIT, y a modificar el cobro de tarifas que correspondan a la modalidad del servicio, en caso de incumplimiento del uso inicialmente aprobado.
k) Medir y facturar los consumos.
l) Suspender o cortar el servicio cuando se configure alguna de las causales indicadas en este contrato, o cuando se cause daño o utilice indebidamente la red o el servicio. 
m) Adelantar las denuncias penales por el delito de defraudación de fluidos y/o entablar las demás acciones que considere convenientes en caso de evidenciar alteración a las condiciones técnicas en las instalaciones eléctricas, equipos de medida y en general a las condiciones de prestación del servicio. 
n) Efectuar el cobro prejudicial, judicial y/o extrajudicial de las obligaciones a su favor.
o) Consolidar en las facturas, el valor total de las facturas anteriores no canceladas, frente a las cuales, no procede ningún tipo de reclamación cuando tuviesen más de cinco meses de haber sido expedidas por ENERBIT. 
p) Adelantar las actuaciones administrativas tendientes a recuperar energía dejada de facturar al predio objeto de este contrato que por acción u omisión no se le ha podido determinar su consumo en forma individual. 
q) Exigir el pago de:
   - El valor facturado correspondiente al consumo del USUARIO o la suma arrojada después de haberse resuelto la petición, queja y/o recurso presentado por EL USUARIO, más los intereses de mora. 
   - Las sumas que no han sido objeto de reclamo o recurso o el promedio del consumo de los últimos cinco (5) periodos.
   - El valor del equipo de medida instalado por ENERBIT cuando EL USUARIO no lo haya cambiado en los términos señalados en este contrato.
   - Los servicios por revisión de instalaciones, por retiro e instalación de equipos de medida y/o transformadores, calibración de medidores, conexión, reconexión, reinstalación del servicio, de conformidad con los valores que para el efecto haya dispuesto ENERBIT.

### Cláusula 10

Obligaciones y deberes de ENERBIT:

a) Dar un tratamiento igual a sus USUARIOS, sin discriminaciones diferentes a las derivadas de las condiciones y características técnicas de la prestación del servicio.
b) Evitar toda práctica o conducta que genere competencia desleal o restrinja en forma indebida la competencia respecto a otros prestadores de servicios públicos domiciliarios.
c) Actuar en la ejecución del contrato de servicios públicos con lealtad, rectitud y honestidad.
d) Informar ampliamente en todo el territorio en el que presta el servicio el Contrato de Prestación de Servicio de Energía Eléctrica con Condiciones Uniformes, y disponer de copias o medios de consulta para EL USUARIO que lo solicite. El suministro de la copia del Contrato podrá realizarse por medio magnético o cualquier forma de transmisión de datos.
e) Abstenerse de incurrir en conductas que constituyen abuso de la posición dominante.
f) Comunicar por escrito al solicitante sobre la negación de la prestación del servicio de comercialización, con indicación expresa de los motivos que sustentan la decisión.
g) Calcular y cobrar la tarifa de energía eléctrica conforme a la fórmula establecida por la CREG o la autoridad competente, de acuerdo con el nivel de tensión al que se encuentre conectado directa o indirectamente el medidor.
h) Reconocer a través de la factura las compensaciones a las que tengan derecho LOS USUARIOS, con ocasión de las fallas en la prestación del servicio, en los términos señalados por la regulación.
i) Registrar oportunamente los consumos determinados por los medidores y expedir las facturas con la periodicidad que determine ENERBIT.
j) Liquidar en forma discriminada el consumo y demás conceptos que de acuerdo con la regulación y/o autorización de EL USUARIO puedan ser incluidos en las facturas.
k) Estimar el consumo de EL USUARIO cuando durante un periodo no sea posible medirlo razonablemente con los instrumentos adecuados para ello.
l) Hacer la revisión previa de las facturas para detectar los consumos anormales e investigar las desviaciones significativas del consumo frente a consumos anteriores, en el momento de preparar la factura. 
m) Dar a conocer las facturas por lo menos con cinco (5) días hábiles de antelación a la fecha de vencimiento del plazo en que debe efectuarse el pago.
n) Disponer de medios apropiados para recibir, atender, tramitar y responder las peticiones, quejas y recursos, presentados de forma verbal o escrita, y que estén relacionados con la prestación del servicio de energía.
o) Disponer de formularios cuando el USUARIO solicite para facilitarle la presentación de los recursos de ley.
p) Atender, tramitar y solucionar dentro del término legalmente establecido las peticiones, quejas, reclamaciones y recursos que presenten los USUARIOS con ocasión de la prestación del servicio de energía eléctrica.
q) Reconectar o reinstalar el servicio dentro del término establecido en la regulación o la ley, luego de eliminada la causa que dio origen a la suspensión o al corte del servicio.
r) Conceder al USUARIO un lapso de quince (15) minutos para que se acompañe de un técnico particular o cualquier persona que sirva de testigo, en los casos en que no haya informado previamente sobre la ejecución de una visita técnica.

### Cláusula 11

Obligaciones y deberes del Usuario:

a) Cumplir con las condiciones técnicas y demás requisitos exigidos por ENERBIT, la regulación para la prestación del servicio y/o el Operador de Red.
b) Suministrar a ENERBIT información real y cierta que se requiera para identificar el bien objeto de la prestación del servicio, e identificar en qué calidad actúa, es decir, si es propietario, usuario, arrendador, poseedor o tenedor.
c) Manifestar de manera expresa y por escrito en el momento de realizar la solicitud del servicio que los proyectos presentados cumplen a cabalidad con las disposiciones del RETIE.
d) Hacer uso adecuado del servicio de energía eléctrica de modo que no genere riesgos para EL USUARIO, la comunidad o para ENERBIT.
e) Informar de inmediato a ENERBIT sobre cualquier alteración que se presente en las instalaciones eléctricas, en el equipo de medida, la propiedad del bien, dirección, u otra novedad que implique variación a las condiciones y datos registrados de EL USUARIO por ENERBIT.
f) Solicitar autorización para el cambio del uso del servicio, la modificación de la carga o capacidad contratada.
g) Atender las instrucciones de ENERBIT y/o de entidades competentes para el uso del servicio y de los equipos de medida, adoptando precauciones eficaces para que estos últimos no se alteren, incluso por factores humanos. 
h) Utilizar el servicio únicamente en el inmueble para el que se contrató, con la cuenta, carga y clase de servicio, y bajo las condiciones estipuladas en la respectiva solicitud de servicio o contrato.
i) Contar con un equipo de medición individual del consumo que cumpla con las especificaciones señaladas en este Contrato, la norma técnica del Operador de Red, el código de medida (resolución CREG 038 de 2014) y demás normas aplicables, o aquellas que las modifiquen, adicionen o deroguen. 
j) Reemplazar, reparar y tomar las medidas necesarias que le indique ENERBIT respecto del equipo de medida de su propiedad, dentro de un periodo de facturación, cuando se establezca que su funcionamiento no permite determinar en forma adecuada los consumos o cuando el desarrollo tecnológico ponga a disposición instrumentos de medida más precisos.
k) Permitir la instalación de un medidor testigo o paralelo al existente con el objeto de corroborar el correcto funcionamiento de los equipos de medida y/o el correcto uso del servicio.
l) Facilitar el acceso al inmueble de las personas debidamente autorizadas por ENERBIT para efectuar la medición del consumo registrado al equipo de medida (de ser necesario), revisiones de las instalaciones internas y medidores, suspensiones, cortes del servicio, realización de censos de carga, retiro de medidores para su verificación, reemplazo de medidores cuando se hayan encontrado adulterados o intervenidos y, en general, cualquier derecho consagrado a favor de ENERBIT en la Ley, la regulación o este Contrato. 
m) Asumir en su totalidad, en caso de pérdida, daño o destrucción por cualquier causa, incluso por fraude o manipulación, los costos por reposición o reparación de la acometida externa, equipo de medida y todos sus elementos asociados.
n) Abstenerse de efectuar cualquier conexión, adecuación o instalación de red, acometida, equipo de medida, transformador o de cualquier otro elemento que integre la red o las instalaciones eléctricas del suscriptor o usuario, sin que haya sido previamente aprobada y revisada por ENERBIT.
o) Abstenerse de retirar, dañar, romper, añadir elementos o adulterar el equipo de medición o cualquiera de los elementos de seguridad instalados en tales equipos, ni sustituir sin autorización de ENERBIT los elementos instalados por ésta.
p) Responder solidariamente por el acceso indebido a las acometidas, y por cualquier irregularidad, defraudación de fluidos, adulteración en los equipos de medida, elementos de seguridad tales como cajas de medidores, sellos, pernos, chapas, etc., así como por las variaciones que sin autorización de ENERBIT se hagan a las condiciones del servicio contratadas.
q) Verificar que la factura remitida corresponda al bien receptor del servicio.
r) Permitir la ejecución de las actividades de suspensiones o cortes del servicio, retiro del equipo de medida y en general, cualquier diligencia que se requiera efectuar en ejecución del contrato. Los copropietarios serán responsables de los perjuicios que se causen a terceros o a ENERBIT cuando la administración o celaduría de un condominio, conjunto cerrado, edificio y/u otro tipo de inmueble que se habite en copropiedad, no permita el libre acceso y de forma permanente para que ENERBIT pueda cumplir alguna de estas actividades u orden administrativa, legal o judicialmente amparada de corte o suspensión del servicio de energía eléctrica a un inmueble que haga parte de la copropiedad, de revisión de la red y de los equipos de medida o toma de consumos registrados por el medidor. Sin perjuicio de las demás acciones legales que sean procedentes, ENERBIT, podrá recurrir al amparo policivo de que trata el artículo 29 de la Ley 142 de 1994, para que cesen los actos que entorpezcan el ejercicio de sus derechos.
s) Eliminar las causas que originaron la suspensión o el corte del servicio, pagando todos los gastos en que se incurran. Cuando la causal de suspensión del servicio fuere la imposibilidad de ENERBIT para acceder al equipo de medida por culpa atribuible al USUARIO, éste deberá reubicarlo en un lugar de fácil acceso desde el exterior del inmueble como condición previa para la reconexión del servicio.
t) Abstenerse de realizar por su cuenta la reconexión o reinstalación del servicio de energía eléctrica sin autorización previa de ENERBIT, cuando ésta lo haya suspendido o cortado en cumplimiento de las obligaciones que legal y regulatoriamente le correspondan. 
u) Informar a ENERBIT la intención de terminar el contrato, siempre y cuando cumpla con los requisitos que para estos efectos se señalan en el presente Contrato. 
v) Acreditar el pago de:
    - Los consumos incluidos en la factura que ENERBIT envía por concepto de la prestación del servicio de energía, dentro del plazo establecido en la misma como fecha de vencimiento, cuando no se interponga petición, queja o recurso en forma oportuna o éstos se resuelvan desfavorablemente, más los intereses de mora.
    - Las sumas que no han sido objeto de reclamación o del promedio del consumo de los últimos cinco (5) periodos con el fin de dar trámite a la petición, queja, reclamo o recurso.
    - Las deudas pendientes que a nombre del USUARIO o del respectivo inmueble existan para obtener el restablecimiento del servicio.
    - Los servicios por revisión de instalaciones y/o transformadores, calibración de medidores, conexión, reconexión, reinstalación, cuyos valores se fijarán anualmente y estarán disponibles en todas las oficinas comerciales.
    - Los gastos en que incurra ENERBIT cuando haya lugar a reemplazar por parte de ésta el equipo de medida del inmueble.
    - Las sumas pactadas en los acuerdos de pago o contratos de transacción suscritos con ENERBIT.
    - Los demás valores autorizados por la ley, este contrato y/o por EL USUARIO. 
    - Los honorarios del abogado y demás conceptos relacionados con la cobranza extrajudicial o judicial, cuando el USUARIO haya dado lugar al incumplimiento del contrato. 

## Capítulo III

Condiciones para la prestación del servicio

### Cláusula 12

Modalidades de prestación del Servicio: ENERBIT prestará el servicio público de energía eléctrica en forma permanente bajo la modalidad de residencial o no residencial, a toda persona capaz de contratar, que habite o utilice de modo permanente un inmueble a cualquier título.

ENERBIT suministrará el servicio de energía eléctrica, siempre y cuando exista viabilidad técnica, económica y financiera en las condiciones de continuidad, calidad, normas de seguridad reglamentarias que lo regulan, y se hayan cumplido con los requisitos para la conexión de nuevas cargas que se señalan en este contrato.

### Cláusula 13

Servicio Provisional: : El SUSCRIPTOR POTENCIAL podrá solicitar una instalación provisional del servicio de energía eléctrica para consumir, en forma temporal o transitoria, siempre que cumpla con las condiciones para la prestación del servicio establecidas en este contrato.

Para las solicitudes de servicio provisional, se suministrará el servicio hasta por un periodo de un (1) mes, plazo que podrá ser prorrogado hasta por dos (2) meses más, previa solicitud de EL USUARIO y aprobación de ENERBIT.  
Para el caso de servicio provisional para obras, los cuales se denominarán servicio provisional de obra, ENERBIT podrá suministrar el provisional para periodos no mayores a seis (6) meses, los cuales podrán ser prorrogables según el criterio de ENERBIT, previa solicitud del USUARIO.

Para el suministro de Provisionales, EL USUARIO deberá hacer entrega de un plan procedimiento de análisis y mitigación de riesgos eléctricos, el cual ENERBIT validará frecuentemente la aplicación so pena de suspensión del servicio. Ese procedimiento debe estar firmado por el técnico o ingeniero electricista responsable de la instalación eléctrica.
Para las instalaciones eléctricas provisionales de eventos para ferias, circos, parques de diversiones y espectáculos, las autoridades locales responsables de los espectáculos deben exigir y verificar que se cumplan los requisitos de seguridad en dichas instalaciones. ENERBIT podrá desenergizar aquellas instalaciones que presenten peligro inminente para las personas.

En el caso de solicitud para prestación del servicio en locales o negocios que ocupen el espacio público, se deberá adjuntar el permiso expedido por el municipio para la utilización del espacio público. 
La prestación del servicio provisional no exime a EL USUARIO del cumplimiento de los requisitos técnicos de conexión, medición e instalaciones eléctricas que la norma ha definido para ello, tales como el Reglamento Técnico de Instalaciones Eléctricas (RETIE), Norma Técnica Colombiana NTC 2050 y las resoluciones CREG que resulten pertinentes. 
Los servicios de energía provisionales no son para uso final. Por lo tanto, una vez termine el tiempo de servicio provisional y en aquellos casos que vayan a continuar con servicio definitivo, EL USUARIO debe solicitar la instalación del servicio de carácter permanente.

Toda solicitud de servicio provisional tendrá como requisito de aprobación la instalación de un equipo de medida según las condiciones definidas en este contrato.

### Cláusula 14

Procedimiento para solicitar la conexión de nuevos servicios: ENERBIT podrá suministrar el servicio de energía eléctrica en su calidad de comercializador dentro de sus posibilidades económicas y técnicas, en las condiciones de continuidad y calidad establecidas por la regulación y de acuerdo con las normas técnicas y de seguridad que lo regulen.

14.1 Requisitos generales para acceder al servicio por nuevas conexiones.

EL SUSCRIPTOR POTENCIAL para realizar la solicitud de nuevos servicios deberá presentar la siguiente documentación:

a) Formato de solicitud del servicio debidamente diligenciado. Este formato será entregado por ENERBIT una vez EL SUSCRIPTOR POTENCIAL lo solicite. 
b) Copia del documento de identidad o copia del certificado de existencia y representación legal de EL SUSCRIPTOR POTENCIAL. 
c) Certificado de tradición del inmueble con fecha de expedición no mayor a un (1) mes o en caso que no cuente con este, copia del recibo del impuesto predial, copia de la escritura pública o certificado de estratificación expedido por la autoridad competente.
d) Declaración de cumplimiento del reglamento técnico de instalaciones eléctricas RETIE firmada por el responsable de la construcción de las instalaciones, adjuntando copia de su matrícula profesional. 
e) En los casos en que ENERBIT no sea el mismo Operador de Red, deberá aportar la aprobación de la conexión por parte de este último, así como los demás requisitos que establezca éste y/o la regulación vigente.

Sin el lleno de los anteriores requisitos, ENERBIT podrá negar la solicitud de conexión de nuevos servicios. Así mismo, ENERBIT podrá exigir el cumplimiento de requisitos adicionales previstos en la regulación o por el Operador de Red respectivo, para la solicitud de conexión de cargas y/o proyectos que así lo requieran.

### Cláusula 15

Negación del Servicio: ENERBIT negará la solicitud del servicio en los siguientes casos:

a) Por razones técnicas que estén expresamente previstas en este contrato, en la norma técnica del Operador de Red, el RETIE y demás normas técnicas aplicables.
b) Cuando la zona haya sido declarada de alto riesgo según decisión de la autoridad competente. 
c) Cuando el SUSCRIPTOR POTENCIAL no cumpla las condiciones establecidas por la autoridad competente.
d) Cuando el inmueble no cumpla con las condiciones establecidas en la Ley, la regulación o exista algún pronunciamiento expreso de autoridad competente que impida su conexión.
e) Cuando el inmueble para el cual se está solicitando el servicio cuente con deuda por prestación del servicio o no ha asegurado el pago de las deudas contraídas por el servicio anterior. 

Parágrafo: La negación del servicio, se comunicará al SUSCRIPTOR POTENCIAL, con indicación de los motivos que sustentan tal decisión. Contra esa decisión procede el recurso de reposición ante ENERBIT, y en subsidio el de apelación ante la Superintendencia de Servicios Públicos Domiciliarios.

### Cláusula 16

Registro de Instalación: ENERBIT registrará todo acto de instalación y/o modificación de la conexión y/o equipo de medida que realice; el cual contendrá, entre otros los siguientes datos:

a) Características generales de la conexión y de los equipos de medida.
b) Cantidad y serie de los sellos instalados.
c) Lectura y/o registro de consumo inicial del medidor.
d) Datos sobre la propiedad de la conexión y del equipo de medida.

### Cláusula 17

Condiciones técnicas para la conexión del servicio: Los aspectos relativos a los requisitos técnicos, el procedimiento y la conexión del servicio se regirán por las disposiciones contenidas en el Reglamento Técnico de Instalaciones Eléctricas (RETIE), la resolución CREG 038 de 2014 (código de la medida), el Reglamento de Distribución de Energía Eléctrica (Anexo General de la Resolución CREG 070 de 1998), normas técnicas establecidas por cada Operador de Red, Reglamento de Comercialización (Resolución CREG 156 de 2011) y las normas que los aclaren, modifiquen o reemplacen.

## Capítulo IV

Condiciones del equipo de medida

### Cláusula 18

Equipo de Medida: por regla general todos los USUARIOS deberán contar con equipo de medición individual para la determinación de su consumo.

### Cláusula 19

Adquisición del equipo de medida: Cuando un SUSCRIPTOR POTENCIAL o USUARIO desee suministrar directamente el medidor deberá cumplir con las especificaciones técnicas que se exigen en la Norma técnica de ENERBIT y/o manifestarlo expresamente al momento de hacer la solicitud de servicio, y tendrá un plazo de quince (15) días hábiles para entregar el medidor a ENERBIT, contados a partir de la aprobación de la solicitud; agotado el plazo sin que se haya realizado entrega, ENERBIT podrá instalar el equipo de medida a costo del SUSCRIPTOR POTENCIAL o USUARIO. En todo caso la aceptación del medidor o los transformadores de corriente y tensión adquiridos por EL SUSCRIPTOR POTENCIAL quedará sujeta a la certificación que, sobre el cumplimiento de las condiciones técnicas, de funcionamiento, calibración, conformidad de producto y certificación que expida la entidad u organismo encargado de la acreditación. Si ENERBIT rechaza el equipo de medida, el SUSCRIPTOR POTENCIAL deberá suministrar uno nuevo, de acuerdo con las observaciones que se le presenten, dentro de los quince (15) días siguientes al recibo de la comunicación que se le remita. Vencido dicho término sin que el SUSCRIPTOR POTENCIAL suministre el equipo de medida, ENERBIT podrá suministrar, instalar y obrar su valor en la factura que se emita por el servicio de energía eléctrica según sea la modalidad aceptada por el USUARIO: venta, a título de alquiler o cualquier otro título.

Parágrafo.
ENERBIT podrá cobrar al USUARIO el costo del equipo de medida o el valor de los servicios de calibración, cuando los suministre o preste, de conformidad con las tarifas vigentes para este tipo de servicios.

### Cláusula 20

Instalación del equipo de medida: la conexión de la acometida al sistema de medida, así como su sellado, será efectuado por ENERBIT o por personal autorizado por ella.
Parágrafo 1. Antes de su instalación en el punto de medición, el equipo de medida será revisado, calibrado y programado por un laboratorio de mediciones acreditado ante la entidad competente.
Parágrafo 2. Protección y sellado del equipo de medida: Los equipos de medida se instalarán en una caja de seguridad u otro dispositivo similar conforme a las especificaciones que estipule ENERBIT. Igualmente, ENERBIT y/o el Operador de Red suministrarán e instalarán sellos o sistemas similares, que solo podrán ser retirados o cambiados por ENERBIT y/o el Operador de Red.
Parágrafo 3. El USUARIO que rompa o adultere los sellos o permita que ello ocurra o que estos no correspondan a los que fueron instalados por ENERBIT, es responsable por todos los costos y la responsabilidad legal derivada.

### Cláusula 21

Los equipos de medida deberán estar localizados en zonas de fácil acceso desde el exterior del inmueble, a fin de que permitan hacer la revisión del estado y funcionamiento del mismo, el registro del medidor para la determinación del consumo facturable y los demás aspectos necesarios para una eficiente atención al USUARIO. No cumplir con las anteriores condiciones, habilita a ENERBIT para negar el suministro del servicio. El equipo de medida deberá instalarse en el punto de conexión, considerando aspectos regulatorios, económicos, de seguridad y de protección de la instalación.

### Cláusula 22

Características técnicas de los equipos de medida: los equipos de medida podrán ser monofásicos, bifásicos o trifásicos de acuerdo con la conexión a la red. Los medidores de energía activa y reactiva, lo mismo que los transformadores de corriente y tensión se ajustarán al código de medida (resolución CREG 038 de 2014 o aquellas que la adicionen, modifiquen o sustituyan), las normas técnicas colombianas vigentes, o aquellas que las modifiquen, adicionen o sustituyan o las normas internacionales correspondientes. 

Parágrafo.
Aplicación de las Características técnicas de los equipos de medida: las características técnicas aquí adoptadas serán exigibles para todo equipo de medida que se instale, así como para toda reposición o reemplazo que se efectúe de los equipos de medida existentes.

### Cláusula 23

Cambio del equipo de medida: La propiedad del equipo de medida será de quien lo hubiere pagado. Será obligación del USUARIO cuando el equipo de medida sea de su propiedad reparar o reemplazar los medidores a satisfacción de ENERBIT, cuando se establezca:

a) Mal funcionamiento del equipo de medida: El USUARIO estará obligado al cambio del equipo de medida cuando se determine su mal funcionamiento. Se entenderá por mal funcionamiento cuando al momento de la revisión o inspección al equipo de medida instalado, las condiciones del mismo no garanticen un adecuado registro de los flujos de energía.

b) Desarrollo tecnológico: El USUARIO también estará obligado al cambio del equipo de medida, cuando existan nuevos desarrollos tecnológicos que ponen a disposición instrumentos de medida más precisos o cuando dichos cambios tengan como origen la implantación de una nueva tecnología, la modernización, mejora y cambio de los niveles de tensión de la red por parte de ENERBIT y que busquen mejorar la prestación del servicio, o cumplir requerimientos regulatorios. 

Parágrafo 1.  Una vez ENERBIT comunique o informe al USUARIO la razón para solicitar el cambio o reparación del equipo de medida, EL USUARIO contará con un periodo de facturación para entregar el medidor calibrado y cumpliendo con lo dispuesto en el código de la medida y demás normas técnicas aplicables. Provisionalmente ENERBIT instalará un equipo de medida, mientras EL USUARIO le comunica que ha adquirido el suyo; de no hacerlo en el término equivalente a un período de facturación, ENERBIT podrá facturar al USUARIO el medidor y los costos asociados a esta operación.

Parágrafo 2. Cuando se requiera el cambio del equipo de medida que es de propiedad de ENERBIT, ésta informará dicha acción a EL USUARIO a quien le va a reemplazar el medidor, con una anticipación mínima de un (1) mes a la fecha prevista para el cambio, salvo en el evento en que el equipo presente mal funcionamiento, caso en el cual se instalará un equipo de medida de manera inmediata para garantizar la medida. ENERBIT conservará la obligación de instalar, administrar, operar, mantener y reponer los equipos que sean de su propiedad.

### Cláusula 24

Retiro no autorizado del equipo de medida: El USUARIO no podrá desmontar, retirar o reubicar el medidor sin autorización expresa de ENERBIT. El desmonte, retiro o reubicación del medidor sin autorización de ENERBIT constituye una causal para la suspensión del contrato.

### Cláusula 25

Revisión al equipo de medida e instalación del Usuario: ENERBIT podrá adelantar revisiones y/o verificaciones técnicas, así como efectuar registros visuales sobre las instalaciones eléctricas y equipos de medida de LOS USUARIOS, en cualquier momento, con el fin de cerciorarse del estado y correcto funcionamiento de los equipos instalados, y adoptar medidas eficaces para prevenir y controlar cualquier hecho que pueda implicar un uso no autorizado del servicio de energía eléctrica. El USUARIO deberá permitir la revisión de los equipos de medida, las acometidas y los consumos registrados por el medidor, y destinar, para la instalación de los medidores, sitios de fácil acceso para que los funcionarios autorizados de ENERBIT puedan llevar a cabo dichas revisiones y/o verificaciones.

Parágrafo. Procedimiento para efectuar revisiones a los equipos de medida e instalaciones: el procedimiento que llevará a cabo ENERBIT para realizar las revisiones de equipos de medida e instalaciones del USUARIO para verificar su estado y correcto funcionamiento es el siguiente:


a) ENERBIT informará al USUARIO la razón de la visita y su derecho de estar asistido por un ingeniero o técnico electricista o testigo hábil, para lo cual se le concederá un tiempo de 15 minutos en los casos en que ENERBIT no hubiere informado con anticipación sobre la visita técnica. Luego procederá a verificar el estado de los instrumentos que se utilizan para medir el consumo, acometidas, instalaciones eléctricas y medidor, y verificará el cumplimiento de las obligaciones derivadas del presente contrato.
b) Realizada la revisión, se diligenciará un acta que contendrá información clara, expresa y detallada sobre el sitio, los equipos revisados, los nombres, identificación y firma de las personas presentes por ENERBIT y quien atienda la revisión y el resultado de la misma.
c) En caso de que EL USUARIO no atienda la visita o no designe a un representante, ENERBIT dejará constancia de la revisión con la firma de un testigo hábil que se encuentre en el sitio, sin importar su relación con el predio objeto de revisión. En todo caso se constatará que quien atienda la diligencia sea una persona mayor de edad, para garantizar el debido proceso. En el evento en que la revisión no pueda ser atendida por EL USUARIO, su representante o un testigo, ENERBIT dejará constancia en el acta de revisión y/o instalación medición de energía y programará una nueva visita.
d) Una copia del acta se enviará por medio electrónico. Podrá obtenerse en forma adicional pruebas como videos, fotografías y en general todas aquellas que permitan establecer el estado general de las instalaciones.
e) En caso de que se encuentren las conexiones, aparatos de medición o de control alterados en su normal funcionamiento, o en ausencia de sellos, ruptura en uno o más de los elementos de seguridad instalados en la tapa principal de los equipos de medida, que los sellos no corresponden a los instalados u otra circunstancia que indique alteración o que afecte la medición y registro adecuado de los consumos, se procederá al retiro del equipo de medida y se enviará a un Laboratorio de Mediciones acreditado por la entidad competente. De todo lo anterior, ENERBIT dejará constancia en el acta de revisión y/o instalación medición de energía y formato de inconsistencias.
f) En caso de haberse retirado el medidor, ENERBIT adoptará medidas para evitar su deterioro. En todo caso, el equipo de medida se embalará en presencia de quien atienda la visita, en una bolsa diseñada para tal efecto, la cual será sellada para su posterior apertura en el laboratorio que designe ENERBIT.
g) Se procederá a la normalización temporal, dejando instalado un medidor provisional, si es del caso.
h) Si el USUARIO no permite la revisión, el retiro del equipo de medida o la normalización, se procederá a la suspensión del servicio de energía eléctrica de conformidad con el procedimiento indicado en el Capítulo No. XI del presente Contrato.
i) Si como resultado de la investigación se determina que no se ha registrado en parte o en su totalidad la energía consumida, ENERBIT cobrará los consumos dejados de facturar, conforme al procedimiento establecido en el Capítulo VIII del presente Contrato.

### Cláusula 26

Equipos de medida o medidor testigo: ENERBIT podrá instalar un equipo de medida simultáneamente con el del USUARIO o en las redes o líneas eléctricas que alimentan el predio del USUARIO, con el objeto de corroborar el correcto funcionamiento de los equipos de medida y/o el correcto uso del servicio. Estos equipos cumplirán las características exigidas en las normas técnicas; en caso de presentarse una diferencia entre los registros del medidor del USUARIO con el medidor testigo, se realizará el procedimiento de revisión y normalización del medidor del USUARIO; mientras se realiza dicho procedimiento se tendrá el medidor testigo como el medidor principal y los consumos se facturarán con base en sus registros.

### Cláusula 27

Propiedad de las conexiones: la propiedad de las redes, equipos y elementos que integran una acometida externa será de quien haya pagado por ello, salvo cuando sean inmuebles por adhesión, caso en el cual pertenecerán al propietario del inmueble al cual se adhieren, pero ello no exime al USUARIO de las obligaciones resultantes del contrato que se refieran a esos bienes. ENERBIT se reserva el dominio sobre los bienes instalados por ella, cuando su costo no haya sido trasladado al USUARIO.

Parágrafo. Riesgo por daño o perdida de las acometidas: el riesgo de daño o pérdida de las acometidas será de su propietario, sin perjuicio de las acciones indemnizatorias a que haya lugar contra los responsables de tales hechos. Por este motivo, el USUARIO se encuentra en la obligación de denunciar a ENERBIT el daño y la pérdida de la misma una vez esta ocurra, y ejecutar las acciones necesarias para que se lleve a cabo su cambio o reemplazo, en los términos señalados en este contrato.

## Capítulo V

Calidad y continuidad del servicio

### Cláusula 28

Calidad y continuidad del servicio: ENERBIT velará por el cumplimiento de la calidad y continuidad del servicio en las condiciones establecidas en la ley y la regulación vigente. Los niveles de calidad y continuidad que el USUARIO recibe dependen de los que le sean suministrados por los agentes transportadores y distribuidores de energía eléctrica en sus distintos niveles. Por esa razón, ENERBIT en su calidad de comercializador será quien actúe como vocero técnico autorizado por el USUARIO para trasladar a los responsables de regulación, control y supervisión de la operación del sistema, así como a los propietarios de las redes de transporte, los problemas del servicio detectados con el monitoreo realizado por ENERBIT y/o de las quejas que sobre estos aspectos le eleven sus USUARIOS.

### Cláusula 29

Falla en la prestación del servicio: De acuerdo con el artículo 136 de la Ley 142 de 1994, el incumplimiento del Operador de Red respectivo en la prestación continua del servicio será entendido como falla en la prestación del mismo. Para ello debe tenerse en cuenta que la misma se da cuando se presente incumplimiento de los índices de calidad del servicio establecidos en la regulación vigente. ENERBIT no tendrá responsabilidad por fallas en la prestación del servicio teniendo en cuenta su calidad de comercializador de energía eléctrica. No obstante, las fallas en la prestación del servicio serán responsabilidad y por tanto, estarán a cargo del Operador de Red en los términos señalados en los artículos 136, 137, 139 y 142 de la ley 142 de 1994.

### Cláusula 30

Compensaciones a usuarios por calidad del servicio: Como parte del esquema de calidad del servicio, ENERBIT compensará a EL USUARIO en caso de incumplimiento de los indicadores de calidad del servicio de acuerdo con las metas establecidas para cada Operador de Red en los términos previstos en la regulación vigente.

La aplicación del esquema de incentivos y compensaciones descritos en esta cláusula se aplicará sin perjuicio de las acciones a las que tenga derecho EL USUARIO.

## Capítulo VI

Determinación del consumo

### Cláusula 31

Determinación del consumo:

31.1 Determinación del consumo:

Para los USUARIOS con sistema de medición horaria con o sin reporte al Administrador del Sistema de Intercambios Comerciales, ASIC, el total de la energía a liquidar y cobrar al Usuario se determinará con base en los consumos de energía leídos por ENERBIT de forma horaria, a partir de los registros tomados del equipo de medida y/o en la forma que la regulación vigente lo determine.

En caso de que al inicio de la prestación del servicio de ENERBIT, el USUARIO no cuente con el sistema de medición horaria, , se determinará con base en la diferencia entre la lectura actual y la lectura anterior del medidor, o en la información de consumos que este registre.  Cuando aplique dicho consumo se multiplicará por el factor del respectivo sistema de medida, ya sea que la lectura se tome al equipo de medida instalado en el inmueble o a aquél instalado por ENERBIT como medidor testigo o paralelo del existente.

31.2 Determinación del consumo estimado:

En caso de no ser posible determinar el consumo en la forma señalada en el numeral 31.1 de la presente cláusula, se utilizará alguno de los siguientes métodos para establecer el consumo:

31.2.1 Cálculo por curvas típicas: Se determinará conforme se establezca en el Código de Medida, el procedimiento definido por el Consejo Nacional de Operación o por la Resolución que lo modifique, adicione o sustituya. Este cálculo podrá ser enviado al USUARIO en caso de solicitarlo a ENERBIT.

31.2.2 Cálculo por promedio del estrato socioeconómico: Para LOS USUARIOS que no posean equipo de medida, el consumo se determinará con base en el consumo promedio de los últimos seis (6) meses de LOS USUARIOS de su mismo estrato socioeconómico que cuenten con medida, considerando el mercado total de ENERBIT.

31.2.3 Cálculo por promedio de consumo individual: Para usuarios que durante un periodo de facturación no sea posible tomar los consumos registrados por el equipo de medida, su consumo se determinará con base en el consumo promedio individual del mismo USUARIO de los últimos seis (6) meses y en caso de LOS USUARIOS con periodos de consumo inferior a seis (6) meses, se realizará con base en el consumo promedio de los periodos en los cuales se haya registrado consumo de energía.

ENERBIT estimará el consumo y lo facturará en los siguientes casos:

a) Desperfectos en el equipo de medida que impidan el registro adecuado del consumo.
b) Retiro provisional del equipo de medida, siempre que el inmueble continúe disfrutando del servicio y no se haya instalado un equipo de medida provisional.
c) Inaccesibilidad al equipo de medida.
d) Por fuerza mayor o caso fortuito.
e) Por causas no imputables a ENERBIT.
f) Cuando ENERBIT al someter su facturación a un proceso de revisión previa, detecta que el consumo registrado comparado con el promedio del consumo de los últimos seis (6) periodos registrados de EL USUARIO, presenta una desviación significativa conforme se señala en el numeral 31.4 de la presente cláusula o según los parámetros que defina la Comisión de Regulación de Energía y Gas (CREG). Cuando a un USUARIO se le hubiere facturado con base en esta causal, se realizará un ajuste a la facturación una vez se registren los consumos del medidor, para lo cual se dividirá el consumo por el número de períodos transcurridos a fin de determinar el consumo real por período de consumo registrado; estos consumos se liquidarán con las tarifas correspondientes a cada período, y las diferencias resultantes con relación a los valores cobrados en dichos meses, se abonarán o se cargarán al USUARIO según sea el caso.
g) Cuando EL USUARIO carece de medición individual por razones de tipo técnico, de seguridad o de interés social.
h) Cuando exista cualquier otra razón de naturaleza objetiva que impida a ENERBIT determinar el consumo.

31.3 Determinación del consumo en casos de fuerza mayor, caso fortuito o causa extraña:

En caso de presentarse un evento de fuerza mayor, caso fortuito o causa extraña, el consumo se determinará por curvas típicas durante el tiempo que dure el evento. Una vez superada la causa que motivó la ausencia de registro del consumo, ENERBIT procederá a establecer la diferencia generando unos valores que serán cargados o abonados a EL USUARIO como reliquidación de consumos en el siguiente período de facturación.

31.4 Determinación del consumo para USUARIOS que presenten desviaciones significativas:

Al preparar las facturas ENERBIT debe investigar las desviaciones significativas frente a consumos anteriores. Mientras se establece la causa, ENERBIT emitirá la factura con base en el promedio de los últimos seis (6) periodos registrados de EL USUARIO. Una vez aclarada la causa de la desviación, ENERBIT procederá a establecer las diferencias entre los valores facturados, que serán abonados o cargados a EL USUARIO, según sea el caso, en el siguiente periodo de facturación, aplicando las tarifas del periodo en que se realiza el ajuste.

Se considera que existe desviación significativa, cuando en el consumo de un periodo de facturación se presenta un aumento o reducción del consumo respecto de los promedios de los últimos seis (6) períodos superior a los porcentajes descritos en la siguiente tabla y siempre que su consumo promedio de los últimos seis (6) periodos sea mayor a los consumos mínimos relacionados a continuación:

    | Tipo de usuario | Reducción de consumo superior a | Aumento de consumo superior a | Consumo mínimo mes(kWh)* |
    | :---            | :---:                           | :---:                         | :---:                    |
    | Residencial     | -90%                            | 200%                          | 200                      |
    | Comercial       | -90%                            | 200%                          | 500                      |
    | Industrial      | -90%                            | 200%                          | 800                      |
    | Oficial         | -90%                            | 200%                          | 500                      |

No serán objeto de investigación de desviación significativa aquellos consumos que no cumplan con las condiciones antes descritas o según los parámetros que defina la Comisión de Regulación de Energía y Gas (CREG).

Cuando la investigación determine que la desviación significativa de los consumos se atribuye a irregularidad en los equipos de medida, elementos de seguridad o uso no autorizado del servicio, dará lugar al cobro de la energía dejada de facturar previo el procedimiento establecido en el capítulo correspondiente del presente contrato.

Cuando un USUARIO cuyo servicio ha sido suspendido por incumplimiento del contrato y se reconecte sin autorización de ENERBIT, ésta cobrará los consumos registrados por el equipo de medida que no hayan sido facturados al momento de la suspensión del servicio. En este caso ENERBIT procederá al corte del servicio y retiro de los materiales.

Para LOS USUARIOS con consumo inferior a seis (6) periodos, el consumo promedio se calculará tomando como base el consumo promedio de los periodos en los cuales se haya registrado consumo de energía con ENERBIT.

31.5 Determinación del consumo cuando no se tiene acceso al equipo de medida:

Si no fuere posible tomar la lectura de los consumos de energía por no tener acceso al equipo de medida, ENERBIT le informará al USUARIO que cuenta con un plazo de un (1) periodo de facturación, para la reubicación del equipo de medida o para corregir la irregularidad que no permite el acceso al equipo de medida. Pasado este periodo sin que EL USUARIO haya corregido la irregularidad en los términos indicados por ENERBIT será procedente la suspensión del servicio.

Cuando a un USUARIO se le hubiere facturado con base en esta causal, se realizará un ajuste a la facturación una vez se tome la lectura luego de corregida la irregularidad, para lo cual se dividirá el consumo por el número de períodos de las lecturas transcurridas a fin de determinar el consumo real por período de lectura; estos consumos se liquidarán con las tarifas correspondientes a cada período, y las diferencias resultantes con relación a los valores cobrados en dichos meses, se abonarán o se cargarán al USUARIO como reliquidación de consumos en el siguiente período de facturación.

### Cláusula 32

Acción u omisión de ENERBIT en la medición: Se entiende que existe acción u omisión de ENERBIT, en los siguientes casos:

a) Cuando investigando la causa que originó la desviación significativa, no la establezca al cabo de cinco (5) meses de presentada.
b) Estimar el consumo por más de un (1) periodo por causa no atribuible al USUARIO, con excepción de los casos de fuerza mayor, caso fortuito o causa extraña.
c) Se considera que no existe omisión en los casos establecidos en la Resolución CREG 108 de 1997, o aquellas normas que la modifiquen, adicionen o sustituyan.

## Capítulo VII

Facturación del servicio

### Cláusula 33

Periodo de facturación:

ENERBIT facturará el consumo de sus USUARIOS en intervalos de tiempo mensual. Es responsabilidad de ENERBIT informar con claridad el periodo a cobrar en la factura que expide y da a conocer a LOS USUARIOS.

En aquellos casos de nuevos servicios, el periodo de facturación podrá ser superior o inferior al intervalo de tiempo mensual. Se podrá proceder en el mismo sentido ante la existencia comprobada de circunstancias extraordinarias que imposibiliten la expedición de la factura, en los términos previstos al inicio de esta cláusula; tales circunstancias, serán informadas por ENERBIT a través de un medio masivo de comunicación.

### Cláusula 34

Facturas: Son las cuentas de cobro que ENERBIT remite al USUARIO, por causa del consumo y demás servicios inherentes al contrato de prestación del servicio público de energía eléctrica, conforme a la tarifa aplicable, y otros cobros según se indica a continuación:

34.1 Tarifa Aplicable: Una vez determinado el consumo de cada USUARIO en cada periodo de facturación, aplicará las tarifas calculadas según la fórmula establecida por la Comisión de Regulación de Energía y Gas –CREG, que hayan estado vigentes el mayor número de días de consumo del periodo correspondiente a su ciclo de facturación si se trata de un USUARIO REGULADO; si se trata de un USUARIO NO REGULADO, se aplicará la tarifa negociada.

34.2 Otros cobros:

a) Saneamiento básico, aseo o alcantarillado: ENERBIT podrá incluir en la factura el cobro de otros servicios públicos en los casos en que haya suscrito convenios con otras empresas de servicios públicos, en los eventos en que lo permita la normatividad vigente. EL USUARIO no podrá cancelar este último con independencia de los servicios de saneamiento básico, aseo o alcantarillado, salvo en aquellos casos en que exista prueba de mediar petición, queja o recurso debidamente interpuesto ante la entidad prestataria del servicio de saneamiento básico, aseo o alcantarillado.

b) Alumbrado Público: ENERBIT podrá incluir en las facturas del servicio público domiciliario de energía eléctrica, el cobro del impuesto de alumbrado público municipal, siempre que el municipio o distrito encargado de ello lo haya solicitado formalmente a ENERBIT.

c) Otros: ENERBIT podrá incluir dentro de la factura el cobro de los bienes y/o servicios vendidos y/o prestados por esta o por otras empresas de servicios públicos y/o personas naturales o jurídicas con las cuales haya celebrado convenios, siempre y cuando medie autorización expresa por parte del USUARIO.

Cuando se trate de bienes y/o servicios no atinentes a la prestación del servicio, las obligaciones pertinentes serán entre quienes suscribieron el convenio de cobro. Por no tratarse de bienes y/o servicios atinentes a la prestación del servicio, el incumplimiento de las obligaciones por parte de EL USUARIO no dará lugar a la solidaridad, suspensión y/o corte del servicio; sin embargo, éste deberá responder ante ENERBIT, el proveedor del bien y/o servicio conforme a lo acordado con éste.

### Cláusula 35

Contenido mínimo de la factura: La factura expedida por ENERBIT deberá contener como mínimo la siguiente información:

1) Nombre de ENERBIT responsable de la prestación del servicio.
2) Nombre y Código del USUARIO y dirección del inmueble receptor del servicio.
3) Estrato socioeconómico y clase de uso del servicio.
4) Número de la factura.
5) Fecha de emisión.
6) Período por el cual se cobra el servicio, consumo correspondiente a ese período y valor.
7) Lectura anterior y/o lectura actual de medidor de consumo o los consumos tomados directamente del medidor, según aplique.
8) Causa de la falta de lectura o de consumo tomado directamente del medidor, según aplique, en los casos en que no haya sido posible realizarla.
9) Fechas máximas de pago oportuno, fecha de suspensión y/o corte del servicio y valor total de la factura.
10) Consumo en unidades físicas de los últimos seis (6) períodos, cuando se trate de facturaciones mensuales; en defecto de lo anterior, deberá contener el promedio de consumo, en unidades correspondientes, al servicio de los seis (6) últimos meses.
11) Cantidad de energía prepagada y valor del consumo prepagado que se esté registrando, de ser el caso.
12) Los cargos expresamente autorizados por la CREG.
13) Valor de las deudas atrasadas.
14) Cuantía de los intereses de financiación, intereses moratorios, y señalamiento de la tasa aplicada.
15) Monto de los subsidios, y la base de su liquidación.
16) Cuantía de la contribución de solidaridad, así como el porcentaje aplicado para su liquidación, en los eventos que aplique.
17) Cargos por concepto de reconexión o reinstalación.
18) Otros cobros, siempre y cuando medie autorización expresa por parte del USUARIO.
19) Indicar que ENERBIT es "Vigilada por la Superintendencia de Servicios Públicos Domiciliarios- SSPD”.
20) Nombre, dirección y teléfono del operador de red que atiende al USUARIO.
21) Los costos unitarios de prestación del servicio desagregados o costo económico que resulta de desagregar los costos de generación, transmisión, distribución y comercialización de energía.
22) Los indicadores de Calidad del Servicio calculados a compensar, en los términos de la regulación vigente.
23) Código del circuito y transformador al que se encuentra conectado el USUARIO y grupo de calidad.
24) Valor de las cuotas de financiación.
25) Valores en reclamación.
26) Consumos dejados de facturar.
27) Valor discriminado del impuesto de alumbrado público y/o demás tributos, cuando sea facturado de manera conjunta con la factura de prestación del servicio de energía eléctrica.
28) Valores facturados por otras empresas con quienes ENERBIT haya suscrito acuerdos, convenios o contratos de facturación conjunta y recaudo.
29) Los demás requisitos que establezca la ley y la regulación.
30) La demás información que ENERBIT considere oportuno adicionar.

La factura expedida por ENERBIT representativa de los bienes, servicios y obligaciones derivadas con ocasión de la prestación del servicio inherentes al mismo o pactadas especialmente con EL USUARIO, una vez firmada por el representante legal de ENERBIT o quien haga sus veces, presta mérito ejecutivo, de acuerdo con las normas del derecho comercial, civil y demás que rijan la materia. En consecuencia, al no ser pagada una factura en la fecha indicada para pago oportuno, podrá ser cobrada ejecutivamente ante la jurisdicción ordinaria, sin perjuicio de la aplicación de las demás acciones legales y contractuales a que haya lugar.

La inclusión de cobros distintos a la prestación del servicio o el cobro de bienes y servicios que no tengan ninguna relación con el servicio público domiciliario es un asunto estrictamente privado de la relación ENERBIT-USUARIO, puesto que corresponde a la autonomía de la voluntad de las Partes su inclusión y cobro dentro de dicho instrumento.

### Cláusula 36

Oportunidad y modo de dar a conocer la factura vía mensaje de datos:

EENERBIT hará entrega y/o dará a conocer la existencia de la factura en forma de mensaje de datos, a través de la página web www.enerbit.co, plataforma digital, aplicación (APP) o por cualquier otro medio electrónico y/o digital idóneo que ENERBIT y el USUARIO hayan acordado y siempre que cuente con la autorización del USUARIO, autorización que podrá ser obtenida a través de los diferentes canales de comunicación habilitados por ENERBIT para sus USUARIOS.

EL USUARIO tiene derecho a recibir oportunamente la factura y por ende, ENERBIT se obliga a darla a conocer por lo menos con cinco (5) días hábiles de antelación a la fecha de vencimiento o pago oportuno que se encuentre señalada en la misma. La factura se entenderá entregada en la fecha en que conste la remisión de la misma.

En caso que ENERBIT no pueda dar a conocer las facturas por razones ajenas a su voluntad, se informará al USUARIO a través de algún medio de comunicación masivo, con una anticipación de cinco (5) días hábiles al vencimiento de la factura, que la misma puede ser reclamada por cualquier de los canales de atención de ENERBIT.

El no recibo de la factura por parte del USUARIO no lo libera de la obligación de atender su pago oportunamente. Para el efecto, EL USUARIO debe informar tal situación a ENERBIT, solicitar la factura y cancelar oportunamente.

Para el USUARIO NO REGULADO, la factura del servicio se enviará de conformidad con lo acordado en el contrato individual que se suscriba con este.

### Cláusula 37

Facturación oportuna:

ENERBIT facturará en forma oportuna los servicios objeto del suministro de la energía eléctrica. Para estos efectos, el lapso comprendido entre la fecha final del periodo de facturación del medidor del USUARIO y la fecha de entrega de la factura no podrá superar un período de facturación, salvo en el evento en medie mora del USUARIO, caso en el cual, podrán cobrarse los saldos insolutos de los periodos anteriores.

### Cláusula 38

Fecha de pago:

El USUARIO deberá efectuar el pago de la factura dentro del plazo señalado en la misma, en las entidades financieras y de recaudo autorizadas por ENERBIT. El pago realizado solo se imputará al Código del bien registrado en la factura; no obstante, ENERBIT no será responsable por los inconvenientes que puedan presentarse por deficiente atención, exigencias especiales, horarios restringidos, y en general, por cualquier situación irregular que afecte o dificulte el pago de las facturas que presenten los establecimientos con los cuales tenga convenios o contratos de recaudo.

### Cláusula 39

Forma de pago:

El USUARIO deberá pagar la factura en la forma, sitios y ante entidades autorizadas por ENERBIT según se indique en ésta.

En el evento en que El USUARIO realice el pago de su factura a través de plataformas bancarias o transferencias electrónicas, y que el saldo en la cuenta de EL USUARIO no sea suficiente para cubrir la totalidad del valor de la factura, habrá lugar al cobro del recargo por mora en las condiciones establecidas en el presente Contrato.

En caso de que el USUARIO efectúe sus pagos a terceros no autorizados por ENERBIT, ésta se exime de toda responsabilidad por la utilización de ese medio o sistema de recaudo.

Conforme al artículo 1629 del Código Civil, estarán a cargo del USUARIO los gastos que se ocasionen con el pago, tales como costos de transferencias, canjes, impuestos, gravámenes, entre otros.

ENERBIT podrá efectuar descuentos, condonar intereses y otorgar créditos para financiación de deudas mediante la celebración con sus USUARIOS de acuerdos de pago a plazos que faciliten el cumplimiento de sus obligaciones, siempre y cuando se cumplan las condiciones y requisitos exigidos para cada situación en particular, de conformidad con la política de cartera vigente. La falta de pago oportuno del acuerdo de pago o los demás valores incluidos en la factura, dará lugar a iniciar los procesos de cobro correspondientes y se causarán intereses moratorios pactados desde la fecha de incumplimiento de EL USUARIO.

### Cláusula 40

Garantías que debe prestar el usuario para el pago del servicio:

Si EL USUARIO se encuentra en mora en el pago de su obligación y desea el restablecimiento del servicio sin haber pagado el total de su deuda, ENERBIT podrá acordar con éste que lo adeudado sea respaldado con bienes de aquél o por un tercero con capacidad de pago, previo estudio de su condición económica. El incumplimiento del acuerdo de pago dará lugar a exigir las garantías prestadas, e incluso a la suspensión del servicio de haberse así acordado. En caso de configurarse un silencio administrativo positivo, éste no aplicará a aquellas deudas reconocidas por el usuario que obren en acuerdos de pago.

### Cláusula 41

Intereses de mora:

En caso de incumplimiento en el pago de la factura contentiva de las obligaciones contraídas por EL USUARIO, u otros valores liquidados en la factura, ENERBIT cobrará intereses de mora. Para los USUARIOS no residenciales se aplicará la tasa máxima legal permitida según las normas comerciales; para los USUARIOS residenciales se aplicará el interés legal previsto en el artículo 1617 del Código Civil o en las normas que lo aclaren, modifiquen o adicionen. ENERBIT, podrá modificar el interés de mora cuando el Gobierno Nacional, la Comisión de Regulación de Energía y Gas -CREG o las entidades competentes varíen los mecanismos o las disposiciones que fijan estas tasas de interés.

Para el retiro definitivo del servicio por solicitud de EL USUARIO, o cuando este se encuentre en mora, ENERBIT podrá exigir el pago inmediato de la obligación.

### Cláusula 42

Exoneración en el pago:

Sin perjuicio de las normas vigentes sobre subsidios y contribuciones, y de acuerdo con lo establecido por el artículo 99.9 de la Ley 142 de 1994, no existirá exoneración en el pago del servicio de energía eléctrica para ningún USUARIO.

### Cláusula 43

Reporte a centrales de riesgo:

Cuando EL USUARIO lo autorice expresamente, ENERBIT con sustento en las normas legales y demás disposiciones normativas que regulen la materia, podrá consultar, procesar, reportar, y divulgar a la central de riesgo que maneje o administre datos de los bancos financieros o de solvencia patrimonial y crediticia con los mismos fines, toda la información referente al comportamiento comercial o crediticio, especialmente en relación con todas las operaciones activas de crédito celebradas o que celebre en el futuro.

Las facultades anteriores estarán plenamente vigentes mientras subsista alguna relación comercial u obligación insoluta a cargo del USUARIOS, por cualquier concepto y cualquier calidad. La información reportada permanecerá en los referidos bancos de datos durante el tiempo que establezcan las normas que regulan la materia y de acuerdo con los términos y condiciones definidas por ellas.

### Cláusula 44

Constitución en mora:

La constitución en mora no requiere pronunciamiento judicial. Por lo tanto, EL USUARIO renuncia a todos los requerimientos para constituirlo en mora y se obliga a pagar los gastos que por todo concepto se causen en razón del cobro extrajudicial o judicial de la deuda. De esta forma, con el solo vencimiento del plazo para el pago de la obligación por parte de EL USUARIO quedará constituido en mora.

## CAPITULO VIII

Consumos dejados de facturar

### Cláusula 45

Consumos dejados de facturar:

De conformidad con la Ley 142 de 1994, ENERBIT podrá determinar y/o cobrar la energía que EL USUARIO consumió y no pagó porque no fue posible su correcto registro, en los siguientes eventos:

a) Cuando se evidencie la existencia de una acometida o derivación de la misma en servicio directo, que no permita medir total o parcialmente la energía efectivamente consumida.
b) Cuando se evidencie una no conformidad como consecuencia de una falla, intervención o alteración de los bienes o equipos del conjunto de medida, que impidan el registro total o parcial de la energía efectivamente consumida por el USUARIO.
c) Retiro del medidor sin autorización de ENERBIT, impidiéndose el registro total o parcial de la energía que efectivamente se consume en el inmueble.

### Cláusula 46

Cálculo de los consumos dejados de facturar:

De constituirse una de las situaciones enunciadas anteriormente, ENERBIT procederá a calcular y cobrar la energía consumida y no pagada por el USUARIO, con fundamento en lo establecido en el artículo 146 de la Ley 142 de 1994 o demás normatividad que la complementen, adicionen, modifiquen o sustituyan.

Para proceder al cobro, se tendrá en cuenta lo siguiente: 

46.1 Cuando a un USUARIO se le haya retirado el equipo de medida para revisión y éste se encuentre defectuoso, o no cumpla con las normas técnicas colombianas, el consumo se establecerá con base en el consumo registrado por el nuevo medidor, en un periodo no menor a 15 días de consumo.

46.2 Cuando a un USUARIO se le encuentre una acometida o línea intervenida o servicio directo se calculará por Carga instantánea o por aforo, Amperes o carga encontrada (en kW) X 720 horas, por meses, por factor utilización.

EEn caso de evidenciarse dolo por parte del USUARIO, ENERBIT estará facultada para realizar el cobro de más de cinco (5) meses conforme lo establecido en el artículo 150 de la Ley 142 de 1994.

### Cláusula 47

Procedimiento para efectuar el cobro de los consumos dejados de facturar:

Para proceder con el cobro de los consumos dejados de facturar, en virtud de las circunstancias señaladas anteriormente, se seguirán las pautas que se indican a continuación:

47.1 Etapa preliminar: ENERBIT podrá realizar revisiones rutinarias al equipo de medida, a las acometidas y a las instalaciones eléctricas del inmueble, para verificar su estado o adecuado funcionamiento. Únicamente el personal autorizado por ENERBIT y/o el Operador de Red podrá manipular o desconectar los aparatos y equipos de medida y control, según lo dispuesto por la regulación.

ENERBIT procederá a realizar la revisión técnica siguiendo los parámetros señalados en el parágrafo de la cláusula 25 del contrato.

47.2 Evaluación y verificación de los medios probatorios: ENERBIT empleará como medios de prueba para determinar la existencia de circunstancias que dan origen a la actuación descrita en el presente capítulo, cualquiera de los medios que se señalan a continuación:

a) Acta de revisión de las instalaciones y equipos de medida, efectuadas por personal autorizado por ENERBIT, en la que conste la presencia de irregularidades en las instalaciones, elementos de seguridad o equipos de medida.
b) Resultado del análisis técnico practicado al equipo de medida en un laboratorio debidamente acreditado.
c) Fotografías, videos, y demás medios que comprueben el uso no autorizado del servicio de energía eléctrica.
d) Actas de visitas efectuadas previamente por personal autorizado por ENERBIT, en las que conste el buen funcionamiento de los equipos y elementos de seguridad.
e) Análisis histórico de consumos, facturaciones y antecedentes comerciales.
f) Cualquier otro medio de prueba que legalmente sea conducente para demostrar la existencia de consumos dejados de facturar.

47.3 Etapa de investigación e inicio de la actuación administrativa: En cumplimiento de las funciones administrativas que se entienden prestadas por orden constitucional y legal y en cuanto a la prestación del servicio público domiciliario de energía eléctrica, ENERBIT podrá iniciar de oficio la correspondiente actuación mediante el proceso pertinente para la recuperación de consumos dejados de facturar, de acuerdo con lo establecido en la Ley 142 de 1994. Proceso que iniciará de la siguiente manera:

47.3.1 El acta de revisión en que consten los datos relacionados y asociados con la prestación del servicio de energía eléctrica a un inmueble, debidamente firmado por quien atendió la visita, el funcionario de ENERBIT y/o el (los) testigo(s) hábil(es) si lo(s) hay, será sometida al análisis técnico y jurídico al interior de ENERBIT, al igual que las fotografías, registros fílmicos y demás medios probatorios disponibles.

47.3.2 Si del análisis anterior ENERBIT encuentra que las situaciones halladas en el inmueble objeto de visita, dan lugar a iniciar un proceso en aras de recuperar la energía, se procederá a realizar una liquidación del consumo dejado de facturar (energía no facturada).

47.3.3 ENERBIT enviará al USUARIO una comunicación que se denominará “INICIO DE ACTUACIÓN ADMINISTRATIVA”, el cual corresponde a un acto de trámite con el que se da inicio a la investigación para la recuperación de los consumos dejados de facturar, y que deberá contener como mínimo la siguiente información:

a) Los hechos, los fundamentos técnicos y jurídicos que dieron lugar al “INICIO DE LA ACTUACIÓN ADMINISTRATIVA” para el cobro de consumos dejados de facturar
b) La forma de calcular el valor de los consumos dejados de facturar.
c) La identificación del periodo o periodos desde el(los) cual(es) procede la recuperación de energía.
d) El derecho que le asiste al USUARIO a presentar una reclamación ante el “INICIO DE LA ACTUACIÓN ADMINISTRATIVA”, así como la indicación de la oportunidad de defensa con la que cuenta, el derecho que tiene a controvertir las pruebas practicadas y argumentos que pretende hacer valer ENERBIT y la facultad de allegar las pruebas que él considere pertinentes para su defensa.

47.3.4 El USUARIO contará con cinco (5) días hábiles siguientes a la recepción de la comunicación de “INICIO DE LA ACTUACIÓN ADMINISTRATIVA" para ejercer su derecho a la defensa, mediante la presentación de los DESCARGOS que cumpla con los requisitos señalados en la cláusula 49 del presente contrato para la presentación de peticiones, reclamaciones y recursos ante ENERBIT.

47.4 Etapa probatoria: Si se considera oportuno dar trámite a la solicitud de pruebas que realice el USUARIO al presentar los DESCARGOS señalada en el numeral anterior o practicar de manera adicional las que de oficio considere ENERBIT, se le informará al USUARIO que esta etapa probatoria tendrá un término de duración no mayor de treinta (30) días hábiles. Este acto será comunicado al USUARIO y sobre este no procede recurso alguno por tratarse de un acto de trámite.

47.5 Expedición de la decisión empresarial con el cobro de los consumos dejados de facturar: Al vencimiento del término para la presentación de los DESCARGOS señalados en el numeral 47.3.4 de la cláusula 47 del presente contrato o del periodo probatorio, si los hubiere, ENERBIT determinará si hay lugar o no a efectuar el cobro de los consumos dejados de facturar. Para llegar dicha determinación ENERBIT deberá respetar los derechos fundamentales de LOS USUARIOS, y basarse en los hechos y las pruebas recaudadas y practicadas en la actuación administrativa.

En el evento de comprobarse la inexistencia de las causas que dieron lugar al inicio de la actuación administrativa, ENERBIT se pronunciará en ese sentido y archivará el procedimiento iniciado en contra del USUARIO.

En caso de comprobarse la existencia de consumos dejados de facturar, se remitirá al USUARIO una decisión empresarial que contendrá:

a) Los fundamentos técnicos y jurídicos que soportan el cobro de los consumos dejados de facturar.
b) La forma de calcular el valor de los consumos dejados de facturar.
c) La relación de todos los medios probatorios permitidos por la Ley, en orden tanto a verificar como a desvirtuar los aspectos que allí se acrediten.
d) La identificación del periodo o periodos desde el cual procede los consumos dejados de facturar.
e) La identificación de las fechas de inicio y finalización de las causas que dieron lugar al inicio de la actuación administrativa.
f) Los recursos que proceden con la decisión empresarial, así como la forma y los términos para interponerlos y las autoridades ante quienes proceden.
g) Un pronunciamiento sobre las razones de defensa presentadas por EL USUARIO en el escrito de descargos.

47.6 Recursos y finalización de la actuación administrativa: En caso que EL USUARIO no esté de acuerdo con la decisión tomada por ENERBIT, podrá presentar los recursos que le hayan sido concedidos contra la decisión que resuelva dicha reclamación.

La notificación de la decisión empresarial, así como aquellas que resuelvan los recursos, su interposición y trámite se efectuarán de conformidad con lo establecido en la Ley 142 de 1994, el Código de Procedimiento Administrativo y de lo Contencioso Administrativo y las demás normas que las modifiquen, adicionen o sustituyan y en todo caso, conforme a los términos señalados en la cláusula 50 del presente contrato.

Ejecutoriada la decisión empresarial que impuso el cobro de los consumos dejados de facturar, ENERBIT procederá a la inclusión de estos valores en la factura para su respectivo pago. La factura deberá contener los valores establecidos anteriormente o la resolución en firme que los imponga, prestará mérito ejecutivo.

Si vencido el plazo para cancelar la factura, EL USUARIO no ha realizado su pago, ENERBIT podrá realizar la suspensión o corte del servicio sin perjuicio del cobro de intereses y/o el inicio de las acciones judiciales procedentes a fin de obtener el pago de la suma adeudada.

No procederán recursos contra la decisión empresarial en firme y/o la factura que contenga el cobro de los consumos dejados de facturar, si con estos se pretende discutir un asunto que no fue objeto de recurso oportuno.

Parágrafo 1: Transacción: ENERBIT y EL USUARIO podrán transar en cualquier etapa de la actuación administrativa hasta que la decisión se encuentre en firme, el valor de los consumos dejados de facturar.

SSuscrita el acta de transacción, que prestará merito ejecutivo y con la cual se dará por terminada la actuación administrativa, ENERBIT incluirá en la factura el valor transado por concepto de consumos dejados de facturar, la cual hará llegar a EL USUARIO para su pago. Vencido el plazo, si no se ha cancelado, ENERBIT procederá a la suspensión o corte del servicio de energía eléctrica sin perjuicio de las acciones judiciales procedentes a fin de obtener el pago de la suma adeudada.

Parágrafo 2: Opciones de pago: ENERBIT podrá otorgar a los USUARIOS las opciones de pago que estén establecidas como políticas comerciales de ENERBIT.

## CAPITULO IX

Mecanismos de defensa del usuario ante ENERBIT

### Cláusula 48

Peticiones, quejas y recursos:

EL USUARIO podrá presentar peticiones, quejas, y recursos relacionados con la negación para contratar, suspensión del servicio, terminación y/o corte del servicio, facturación y en general con asuntos relacionados con la prestación del servicio de energía eléctrica por los siguientes medios: correo electrónico, línea de servicio al USUARIO, servicios o plataformas tecnológicas habilitadas por ENERBIT y a través de la página web: [www.enerbit.co].

Las peticiones, quejas, reclamaciones y recursos se recibirán, tramitarán y responderán conforme con las condiciones uniformes que se señalan a continuación, cumpliendo en todo caso con los principios y garantías constitucionales y legales que le asisten a los USUARIOS durante el trámite de las mismas, en especial el derecho a la defensa y el debido proceso consagrado en el artículo 29 de la Constitución Política y en el Código de Procedimiento Administrativo y de lo Contencioso Administrativo y demás normas que lo reglamenten, modifiquen o complementen.

Parágrafo 1. En ningún caso procederán reclamaciones contra los actos de facturación, suspensión, terminación y corte del servicio de energía eléctrica, si con ellos se pretende discutir una facturación que tiene más de cinco (5) meses de haber sido expedida por ENERBIT o contra aquellos conceptos que fueron objeto de acuerdo de pago con el USUARIO.

Parágrafo 2. Las peticiones, quejas y recursos se responderán en un término de quince (15) días hábiles contados a partir de la fecha de su presentación, salvo que se expida una norma que modifique el término antes señalado o que se requiera la práctica de pruebas, lo cual deberá ser notificado al USUARIO.

Parágrafo 3: Una vez en firme la decisión respectiva, ENERBIT podrá incluir en la facturación los valores que hayan quedado definidos, así como los intereses corrientes que correspondan, valores sobre los cuales no procede reclamo o recurso.

### Cláusula 49

Presentación de las peticiones, quejas, reclamaciones y recursos:

Para la presentación de peticiones, quejas, reclamaciones y recursos ante ENERBIT, EL USUARIO deberá cumplir los siguientes requisitos:

a) La designación del prestador del servicio: ENERBIT S.A.S. E.S.P.
b) Señalar los nombres y apellidos completos del USUARIO y de su representante o apoderado, si es del caso, con indicación del documento de identidad, teléfono y dirección de notificación física o electrónica.
c) El objeto de la petición y las razones en que fundamenta su petición.
d) La relación de los documentos que desee presentar para iniciar el trámite.
e) La firma del peticionario, cuando fuere el caso.
f) Presentarse dentro del horario de atención que ENERBIT defina durante los días hábiles de lunes a viernes. Aquellas recibidas, fuera del horario definido por ENERBIT y días hábiles, se entenderán recibidas al día hábil siguiente de su radicación.

Parágrafo 1: ENERBIT pondrá a disposición de LOS USUARIOS, formatos para facilitarla presentación de recursos, sin que su uso sea de carácter obligatorio.

Parágrafo 2: Las peticiones y reclamaciones podrán ser presentadas en cualquier tiempo; sin embargo, aquellas que pretendan discutir un acto de facturación deberán formularse antes del quinto mes de expedida la factura de servicios públicos.

### Cláusula 50

Trámite de los Recursos:

EL USUARIO tiene derecho a presentar los recursos en los casos en los que la ley lo permite, los cuales, en caso de ser procedentes, se concederán en el efecto suspensivo, con la finalidad de que ENERBIT o la Superintendencia de Servicios Públicos Domiciliarios confirme, aclare, modifique o revoque, total o parcialmente la decisión emitida por ENERBIT.

El recurso de reposición debe interponerse de forma subsidiaria al de apelación y de manera simultánea ante ENERBIT, en la forma y con el lleno de los requisitos señalados en la cláusula 49 del presente contrato, dentro de los cinco (5) días hábiles siguientes a la notificación de la respectiva decisión. En caso de resolverse negativamente el recurso de reposición y de haberse interpuesto de manera subsidiaria el recurso de apelación, ENERBIT remitirá copia de la totalidad del expediente a la Superintendencia de Servicios Públicos Domiciliarios para que resuelva el recurso de apelación.

En caso de rechazo del recurso de apelación, procederá el recurso de queja ante la Superintendencia de Servicios Públicos Domiciliarios, el cual podrá ser interpuesto por EL USUARIO dentro de los cinco (5) días hábiles siguientes a la notificación de la decisión empresarial mediante la cual ENERBIT rechazó el recurso de apelación.

La interposición de los recursos y su trámite se efectuará de conformidad con lo establecido en el Código de Procedimiento Administrativo y de lo Contencioso Administrativo, la Ley 142 de 1994 y las demás normas que las modifiquen, adicionen o sustituyan.

Si EL USUARIO no presenta los recursos con el lleno de los requisitos previstos en el Código de Procedimiento Administrativo y de lo Contencioso Administrativo, la Ley 142 de 1994 y las demás normas que las modifiquen, adicionen o sustituyan, ENERBIT los recha-zará de conformidad con las causales de rechazo establecidas en la ley.

No proceden recursos contra las decisiones o actos de suspensión, terminación y corte, si con ello EL USUARIO pretende discutir un acto de facturación que no fue objeto de reclamación o recurso oportuno. Tampoco serán procedentes los recursos contra los actos de trámite, preparatorios o de ejecución, excepto en los casos previstos en norma expresa.

Para interponer los recursos contra los actos que deciden o resuelven reclamos, EL USUARIO deberá acreditar, como requisito para que se le otorgue el trámite correspondiente, el pago de las sumas que no han sido objeto de recurso, o del promedio de los consumos de los últimos cinco (5) períodos, salvo que EL USUARIO alegue no deber dicho promedio.

### Cláusula 51

Peticiones incompletas y desistimiento tácito o expreso:

Si las informaciones o documentos que proporcione el USUARIO al elevar una petición, reclamación, queja o recurso no son suficientes para decidir, ENERBIT lo requerirá por escrito, por una sola vez, para que lo subsane en el término de un (1) mes, lapso durante el cual se suspenderá el término para decidir. Una vez el USUARIO aporte la información requerida, se reanudará el término para decidir. Se entenderá que el USUARIO ha desistido de su petición, reclamación, queja o recurso cuando no satisfaga el requerimiento o cuando lo solicite expresamente, lo cual dará lugar a que ENERBIT ordene el archivo del expediente; salvo que antes de vencer el plazo concedido el USUARIO solicite prórroga hasta por un término igual y durante dicho periodo aporte la información requerida.

### Cláusula 52

Práctica de Pruebas:

Cuando para decidir sobre una petición, reclamación, queja o recurso sea necesaria la práctica de pruebas, se le informará a EL USUARIO y se señalará para ello un término no mayor de treinta (30) días hábiles. Los términos inferiores podrán prorrogarse por una sola vez, sin que con la prórroga el término exceda de treinta (30) días hábiles. En el acto que decrete la práctica de pruebas se indicará el día en que vence el término probatorio.

### Cláusula 53

Comunicaciones y notificaciones:

ENERBIT comunicará sus actos informativos o de trámite y notificará sus decisiones conforme lo establezca la normatividad vigente. La notificación se considerará surtida a partir de la fecha y hora en que EL USUARIO acceda a la decisión empresarial, la cual deberá certificar ENERBIT. En lo no previsto en la presente cláusula, serán aplicable el Código de Procedimiento Administrativo y de lo Contencioso Administrativo y las demás normas que lo modifiquen, adicionen o sustituyan. Las solicitudes que sean presentadas en forma verbal podrán ser resueltas de la misma forma, de lo cual ENERBIT deberá dejar constancia y entregará la misma, solo si así lo solicita EL USUARIO. Las decisiones empresariales emitidas por ENERBIT serán oponibles a todos aquellos que sean solidarios en las obligaciones derivadas del presente contrato conforme al artículo 130 de la Ley 142 de 1994.

## CAPITULO X

Causales y procedimientos para la suspensión del servicio

### Cláusula 54

De común acuerdo:

Por solicitud realizada por el USUARIO, y aceptada por ENERBIT. Para el efecto, EL USUARIO debe presentar la solicitud de suspensión del servicio por lo menos con cuarenta y ocho (48) horas de antelación a la fecha a partir de la cual, se espera hacer efectiva la suspensión; ésta será por un término máximo de seis (6) meses prorrogables por otros seis (6) meses. En caso de prórroga, debe solicitarla con una antelación no inferior a treinta (30) días calendario de la terminación del plazo inicial. La suspensión del contrato de común acuerdo no podrá ser superior a un (1) año, caso en el cual será procedente la terminación del contrato. Los costos de reconexión correrán por cuenta de EL USUARIO.

A la solicitud de suspensión del servicio de común acuerdo EL USUARIO debe anexar lo siguiente:

a) Autorización escrita del propietario del bien, si esta calidad no la tiene quien solicita la suspensión.
b) Manifestar bajo la gravedad del juramento que no existen terceros perjudicados con la solicitud de suspensión. En caso que la suspensión afecte a terceros, la solicitud debe ir acompañada de la autorización escrita de éstos.

Si no se cumplen los requisitos anteriores, ENERBIT no podrá realizar la suspensión solicitada y así se lo notificará al USUARIO, quien podrá interponer los recursos contra dicha decisión. Los daños o perjuicios que se puedan generar a terceros que resulten afectados por la suspensión del servicio, estarán a cargo de EL USUARIO.

Durante el término que dure la suspensión del servicio de común acuerdo, ENERBIT no emitirá factura; sin embargo, dicha suspensión no libera a EL USUARIO del cumplimiento de las obligaciones adquiridas con anterioridad a ésta. En consecuencia, ENERBIT podrá emitir la factura, cuando existan deudas pendientes por consumos anteriores, por financiación de deudas y/o cargos por conexión, por cargos por suspensión o cuando ENERBIT compruebe que existe consumo en el inmueble, caso en el cual, podrá terminar unilateralmente el acuerdo de suspensión y procederá a cobrar los consumos y cargos a que hubiere lugar.

### Cláusula 55

En interés del servicio:

ENERBIT podrá suspender el servicio, en los siguientes eventos:

a) Para evitar perjuicios que se deriven de la inestabilidad del inmueble o del terreno siempre, que se haya empleado toda la diligencia posible, dentro de las circunstancias, del caso concreto para que EL USUARIO pueda hacer valer sus derechos.

b) Por emergencia declarada por la autoridad competente.

c) Para adoptar medidas de seguridad que se requieran con urgencia.

### Cláusula 56

Por orden de autoridad:

ENERBIT podrá suspender el servicio de energía eléctrica cuando se reciba orden emitida por autoridad competente o directiva gubernamental, ya sea nacional, regional o local o de la autoridad reguladora.

### Cláusula 57

Por incumplimiento del contrato por parte del USUARIO: Por las siguientes razones:

a) Cuando el Usuario no pague el valor total de la factura de servicios en el plazo señalado por ENERBIT, salvo que exista, reclamación o recurso interpuesto, caso en el cual deberá acreditar el pago de las sumas que sean objeto de reclamación.
b) Cuando EL USUARIO incumpla las condiciones pactadas con ENERBIT en un contrato de transacción o acuerdo de pago.
c) Dar al servicio de energía eléctrica o al inmueble receptor del servicio un uso distinto al convenido con ENERBIT o proporcionar el servicio de energía a otro inmueble en forma temporal o permanente.
d) Impedir u obstaculizar a los empleados y/o contratistas autorizados por ENERBIT y debidamente identificados, la inspección y/o la revisión de las instalaciones eléctricas, equipos de medida, realización de aforo o censo de carga y/o efectuar la medición del consumo.
e) No realizar dentro del plazo fijado o impedir el traslado, reparación, normalización, adecuación, exteriorización o cambio del equipo de medida cuando se requiera para garantizar la correcta medición del consumo. El plazo máximo que ENERBIT, concederá para realizar las anteriores actividades no podrá ser superior a un (1) periodo de facturación, plazo que le será comunicado por escrito a EL USUARIO.
f) Realizar sin autorización previa de ENERBIT cualquier modificación en la acometida, carga y capacidad instalada o hacer conexiones externas, no autorizadas, derivaciones de acometida o adulterar las existentes; dañar, retirar, cambiar, reubicar o los aparatos de medición o control y los dispositivos de seguridad, protección o gabinete.
g) Dañar, retirar, cambiar, romper o adulterar las condiciones técnicas de la red de media o baja tensión.
h) Efectuar sin autorización de ENERBIT la reconexión del servicio cuando éste haya sido suspendido. Esta operación y los cobros que se deriven de ella serán asumidos por el USUARIO de acuerdo con las tarifas vigentes al momento de detectarse la irregularidad.
i) Por utilizar equipos o instalaciones que no cumplan con las normas técnicas y las condiciones de homologación que determine la autoridad competente.
j) Cuando la acometida, instalaciones internas o equipos instalados desmejoren el servicio de energía a otro USUARIO, o generen perjuicios a las redes o equipos de ENERBIT.
k) En caso que personal técnico de ENERBIT, en razón a la labor de verificación a los predios cuyo servicio de energía se haya suspendido y se encuentren con servicio, ENERBIT procederá nuevamente a realizar la suspensión. Esta operación y los cobros que se deriven de ella serán asumidos por el USUARIO de acuerdo con las tarifas vigentes al momento de detectarse la irregularidad.
l) Cuando el inmueble no cumpla con las disposiciones del RETIE, la norma técnica del Operador de Red y demás normas técnicas aplicables.
m) En general, cualquier alteración inconsulta y unilateral, por parte del USUARIO, de las condiciones contractuales, o incumplimiento de la regulación, ley o normas técnicas aplicables.

Parágrafo 1: Con excepción de las causales de suspensión del servicio originada por común acuerdo, orden de una autoridad competente, en interés del servicio o la falta de pago del servicio y de los demás conceptos relacionados, con ocasión y conexos con el mismo, ENERBIT podrá proceder a la suspensión, una vez cumpla el siguiente procedimiento:

ENERBIT comunicará al USUARIO la causal de suspensión o incumplimiento del contrato que da lugar a la suspensión, mediante escrito que dirigirá a la dirección reportada del inmueble.

ENERBIT en la comunicación, señalará sobre la procedencia de los recursos de conformidad con el artículo 154 de la ley 142 de 1994.


Los recursos y su notificación se tramitarán de conformidad con lo establecido en la Ley 142 de 1994, el Código de Procedimiento Administrativo y de lo Contencioso Administrativo, y las normas que lo modifiquen, adicionen o sustituyan. Una vez en firme la decisión empresarial mediante la cual se determinó sobre la suspensión o terminación del contrato, se procederán a ejecutar las actividades necesarias para hacer efectiva la suspensión del servicio.


Parágrafo 2: En el evento en que EL USUARIO no permita la suspensión del servicio, ENERBIT podrá solicitar amparo policivo y procederá al retiro de la acometida. EL USUARIO deberá cancelar el valor de la reconexión y los demás conceptos que adeude a ENERBIT, como requisito previo para que le sea habilitada nuevamente la instalación. En caso de reincidencia habrá lugar al retiro definitivo de la acometida y a la terminación del contrato, sin perjuicio de las acciones legales a que hubiere lugar.¿

Parágrafo 3: ENERBIT estará exenta de toda responsabilidad originada por la suspensión del servicio cuando ésta haya sido generada por el incumplimiento de EL USUARIO a las obligaciones establecidas en la Ley de Servicios Públicos, su regulación y este contrato.

Parágrafo 4: ENERBIT restablecerá el servicio de energía eléctrica una vez EL USUARIO elimine la causa que dio origen a la suspensión y haya pagado los gastos en que haya incurrido ENERBIT para la reconexión del servicio de energía eléctrica. Los cargos por reconexión y ejecución de actividades podrán ser cargados por ENERBIT en la factura siguiente a la ejecución de la acción.

## Capítulo XI

Causales y procedimiento para la terminación del contrato y el corte del servicio

El contrato de prestación del servicio público domiciliario de energía eléctrica podrá darse por terminado en los siguientes eventos:

### Cláusula 58

Por mutuo acuerdo:

Cuando el USUARIO lo solicite y ENERBIT consienta en ello, estando el USUARIO a paz y salvo por todo concepto. Además, se deberá contar con el consentimiento de terceros que puedan verse afectados.

### Cláusula 59

Por parte del USUARIO:

a) Cuando sea demolido el inmueble al cual se le prestaba el servicio de energía eléctrica. Para estos propósitos se requerirá autorización de parte.
b) Por cambio de comercializador.
c) Cuando ENERBIT incumpla sus obligaciones.

En todo caso, EL USUARIO deberá elevar una solicitud para la terminación del contrato, la cual será viable solo en caso de cumplir con los requisitos que ENERBIT y la regulación exija.

### Cláusula 60

Por parte de ENERBIT:

ENERBIT podrá dar por terminado unilateralmente el contrato cuando el USUARIO incurra o se presenten los siguientes eventos:

a) Incumplir en el pago oportuno de tres (3) facturas o reincida en una causal de suspensión del servicio de energía eléctrica en un periodo de dos (2) años.
b) Hacer reconexiones no autorizadas del servicio de energía eléctrica por más de dos (2) veces en un periodo de dos (2) años, sin que se haya eliminado la causa que dio origen a la suspensión.
c) Reincidir en la adulteración de las conexiones, aparatos de medición, equipos de control y sellos, o alteraciones que impidan el funcionamiento normal de los mismos.
d) Cuando permanezca suspendido el servicio de energía eléctrica por un período superior a seis (6) meses o se haya cancelado la frontera comercial, excepto cuando la suspensión hubiere sido por mutuo acuerdo o la suspensión obedezca a causas originadas por ENERBIT.
e) Adulterar o falsificar la factura de cobro o documentos presentados como prueba para obtener el servicio de energía eléctrica, o en desarrollo de cualquier gestión frente a ENERBIT relacionada con la prestación del servicio.
f) Gestionar, conociendo la existencia de un servicio con deuda, la prestación del servicio de energía eléctrica para el mismo bien con cargo a un Código diferente. En este caso, la terminación del contrato operará sobre el nuevo servicio solicitado y si presenta deuda se cargará al Código anterior.

Parágrafo: Una vez terminado el contrato, ENERBIT procederá a cancelar la frontera comercial del USUARIO ante XM.

### Cláusula 61

Por orden de autoridad competente:

El corte del servicio de energía eléctrica podrá efectuarse sin perjuicio de que ENERBIT inicie las acciones judiciales necesarias para obtener el pago de la deuda a que haya lugar. Para que ENERBIT restablezca el servicio de energía eléctrica, el USUARIO, debe eliminar la causa que dio origen al corte del servicio de energía, realizar una nueva solicitud de prestación del servicio de energía y cancelar los gastos de reinstalación en los que ENERBIT haya incurrido.

### Cláusula 62

Procedimiento para la terminación del contrato y corte del servicio:

ENERBIT, sin perjuicio de su facultad de acudir a la jurisdicción ordinaria, de oficio o a solicitud de parte, podrá según el caso proceder a la terminación del contrato con su respectivo corte, una vez cumpla el siguiente procedimiento:

ENERBIT comunicará al USUARIO la causal de incumplimiento que da lugar a la terminación del contrato o dará respuesta a la solicitud que sobre este aspecto realice el USUARIO, mediante decisión empresarial que dirigirá a la dirección reportada del inmueble o aquella que hubiere sido informada por EL USUARIO, en la cual se indicará la procedencia de los recursos de conformidad con el artículo 154 de la Ley 142 de 1994.

Los recursos y su notificación se tramitarán de conformidad con lo establecido en la Ley 142 de 1994, el Código de Procedimiento Administrativo y de lo Contencioso Administrativo, y las normas que lo modifique o adicione.

Una vez en firme la decisión empresarial se procederá a ejecutar las actividades necesarias para hacer efectivo el corte del servicio.

### Cláusula 63

Exención de responsabilidad:

ENERBIT quedará exonerada de toda responsabilidad derivada del corte del servicio, cuando éste haya ocurrido por las causales anteriormente expuestas.

En adición a la decisión de corte del servicio, ENERBIT podrá ejercer todos los demás derechos que las normas que regulan la prestación del servicio de energía eléctrica, además de este Contrato, le conceden cuando se presente un evento de incumplimiento.

## CÁPITULO XII

Condiciones para la reconexión y/o reinstalación del servicio

### Cláusula 64

Condiciones para la reconexión del servicio:

64.1 Por causas imputables al USUARIO: Para obtener de nuevo el servicio en caso de que la suspensión sea imputable al USUARIO, se requiere:

a) Que elimine la causa que la originó.

b) Que pague la deuda, los intereses de mora y demás conceptos que se hayan causado.

c) Que pague todos los gastos de reconexión, los cuales serán fijados anualmente e informados de forma amplia en todo el territorio en el que ENERBIT presta el servicio.

d) Que pague los gastos que demande el cobro prejudicial o judicial, en el evento que se haya tenido que recurrir a cualquiera de estas vías.

e) Que asuma los costos en que haya incurrido ENERBIT para la modificación o adecuación de la acometida, la red interna o para la ubicación o reubicación del equipo de medida.

Una vez cumplidas las anteriores obligaciones por parte del USUARIO, ENERBIT restablecerá el servicio dentro de las siguientes veinticuatro (24) horas.

64.2 Solicitud del usuario: Si la suspensión es a solicitud del USUARIO, ésta se levantará a pedido de éste, en cualquier momento, o al vencimiento del término de duración de la suspensión por él indicado.

64.3 Acuerdo entre las partes: Si la suspensión es por mutuo acuerdo, se levantará en cualquier momento en que se convenga, o al vencimiento del término pactado.

64.4 Imputable a ENERBIT: Si la suspensión es imputable a ENERBIT o derivada de fuerza mayor o caso fortuito, ésta empleará la máxima celeridad que permita el restablecimiento del servicio, sin exceder en todo caso veinticuatro (24) horas siguientes al cese de la causa de la suspensión.

64.5 Orden de autoridad competente:

Cuando la suspensión sea por orden de autoridad competente, la misma se levantará cuando el funcionario competente lo ordene.

En los demás casos, el servicio se restablecerá cuando se elimine la causa que originó el cese, y se cumplan las prestaciones entre las partes que correspondan.

### Cláusula 65

Reinstalación por corte del servicio:

Para restablecer el servicio en el evento en que el corte del servicio haya sido causado por incumplimiento de este Contrato por parte del USUARIO, éste deberá eliminar la causa que originó el corte, pagar los gastos de reinstalación o reconexión y cumplir con todos los requisitos establecidos en Capítulo IV “Condiciones para la prestación del servicio” del presente contrato, para la nueva conexión del servicio.

## CAPITULO XIII

Disposiciones finales

### Cláusula 66

Causales de liberación del contrato:

De acuerdo con el artículo 128 de la Ley 142 de 1994, EL USUARIO podrá liberarse de las obligaciones derivadas del contrato de prestación del servicio público de energía eléctrica, en los siguientes casos:

a) Fuerza mayor o caso fortuito que imposibilite al suscriptor para continuar asumiendo las obligaciones propias del contrato.
b) Cuando el suscriptor sea el propietario, poseedor o tenedor del inmueble y, mediante sentencia judicial resulte privado de la propiedad, posesión, o tenencia del inmueble en el cual se presta el servicio. En este caso la manifestación de liberación de las obligaciones propias del contrato de servicios públicos deberá presentarse junto con copia de la respectiva sentencia.
c) Cuando el suscriptor es el poseedor o tenedor del inmueble, y entrega la posesión o la tenencia al propietario o a un tercero autorizado por éste. En este caso la manifestación de liberación de las obligaciones propias del contrato de servicios deberá presentarse ante ENERBIT con prueba de que el propietario del inmueble o el nuevo poseedor o tenedor del bien, acepta expresamente asumir tales obligaciones como suscriptor.
d) Cuando el suscriptor siendo el propietario de un inmueble urbano, lo enajena y opera la cesión del contrato de servicios públicos, de acuerdo con lo dispuesto en el artículo 129 de la Ley 142 de 1994.
e) Cuando se produzca la enajenación de bienes raíces rurales por parte del suscriptor si éste es propietario del inmueble, salvo que las partes pacten lo contrario.

Cuando se presente cualquiera de las causales aquí previstas, corresponde a la persona interesada en la liberación de las obligaciones propias del contrato de servicios públicos, informar a la empresa la existencia de dicha causal en la forma indicada.

Parágrafo 1. La liberación de las obligaciones por parte del suscriptor, de acuerdo con las causales señaladas en esta cláusula, no implica la extinción de la solidaridad establecida por el artículo 130 de la Ley 142 de 1994 respecto de obligaciones propias del presente  contrato exigibles con anterioridad a la fecha en que se produzca el hecho que determina la liberación del suscriptor.

### Cláusula 67

Cesión del Contrato:

Existirá cesión del contrato de prestación del servicio público de energía eléctrica en la enajenación de bienes inmuebles, salvo que las partes acuerden otra cosa. La cesión operará de pleno derecho e incluye la propiedad de los bienes inmuebles por adhesión o destinación utilizados para recibir el servicio.

EL USUARIO acepta anticipadamente que el presente contrato podrá ser cedido por ENERBIT a otro comercializador y dicha cesión surtirá efectos respecto del USUARIO, si éste dentro de los treinta (30) días calendario siguientes a la fecha de publicación de la cesión en un periódico de amplia circulación, no manifiesta su intención de cambiar de comercializador. El silencio del USUARIO se entenderá como aceptación de la cesión del contrato de servicios públicos.

### Cláusula 68

Tratamiento de datos personales:

ENERBIT recopilará y tratará los datos personales de sus USUARIOS de conformidad con lo establecido en la Ley 1581 de 2012 o las normas que la reglamenten, adicionen, modifiquen, deroguen o sustituyan y de acuerdo con las condiciones establecidas en su Política de Tratamiento de Datos Personales, la cual puede ser consultada en la página web: www.ENERBIT.com. ENERBIT, adoptará las medidas de seguridad previstas en la ley y en las normas técnicas internacionales con el objetivo de proteger y preservar la confidencialidad, integridad y disponibilidad de la información de los usuarios contenida en bases de datos, independiente del medio en el que se encuentre, de su ubicación o de la forma en que esta sea transmitida o transferida.

### Cláusula 69

Vigencia y Transición:

El presente contrato rige a partir de la fecha de su publicación por el/los medio(s) que establezca la regulación o la Ley y deja sin efectos jurídicos el contrato que con anterioridad regía las relaciones entre ENERBIT y los USUARIOS. Todo trámite, reclamo, solicitud o cualquier tipo de actuación de los USUARIOS frente a ENERBIT iniciadas antes de la entrada en vigencia del presente contrato, concluirán su trámite con base en las estipulaciones señaladas en el contrato anterior.

En constancia se firma en Medellín, Antioquia, el veintiocho (28) de abril de 2023.

SANTIAGO ARANGO TRUJILLO

Representante Legal
ENERBIT S.A.S. E.S.P.

---------------------
`;

export const tratoCliente = `# Carta de trato digno al cliente

## ¡Hola!

*Para enerBit, los clientes son lo más importante*, por eso nos comprometemos a brindarte un trato igualitario, respetuoso, considerado y diligente.
Además, estamos disponibles 24/7 para que cada experiencia sea única; razón por la cual, te damos a conocer tus derechos y los canales de atención disponibles:

## Tus derechos son: 

1. Puedes presentar peticiones, quejas, reclamos y recursos que serán atendidos dentro del término legal.
2. Recibir un servicio transparente, de buena calidad y comunicación.
3. Ser tratado con respeto y la consideración debida a la dignidad de la persona humana.
4. Conocer el contrato para la prestación del servicio de comercialización de energía eléctrica de enerBit o las modificaciones que se hagan, y a una copia de este cuando la necesites.
5. Trato igualitario en el régimen tarifario con respecto de otros clientes que tengan tus mismas características.

## Los canales de atención que tenemos para ti son:

Tenemos tres canales virtuales para darte una respuesta oportuna y sin salir de tu casa:

- WhatsApp 311 270 6213
- Nuestra página Web enerbit.co
- Correo electrónico escribenos@enerbit.co`;

export const tratamientoDatos = `# Política de Tratamiento de Datos Personales

Esta política de tratamiento de datos personales (en adelante la “Política”), tiene como objetivo definir los lineamientos y procedimientos para dar cumplimiento a la regulación en relación con el tratamiento de los datos de carácter personal de los cuales es encargada o responsable enerBit S.A.S. E.S.P.

Para efectos de esta política enerBit S.A.S. E.S.P. se denominará en adelante “LA COMPAÑÍA”.

## I. Objeto de la Política

Adoptar y establecer las reglas aplicables al Tratamiento de Datos Personales recolectados, tratados y/o almacenados por LA COMPAÑÍA en desarrollo de su objeto social, bien sea en calidad de Responsable y/o Encargado.

Comunicar al Titular del Dato Personal, las políticas de tratamiento de la información que le serán aplicables a sus Datos Personales, así como las finalidades de dicho tratamiento.

Explicar al Titular la forma de acceder a sus Datos Personales, además de determinar la forma en que se hará el Tratamiento y posterior uso a los mismos.

## II. Ámbito de aplicación

La presente Política se aplicará al Tratamiento de Datos Personales efectuado en territorio colombiano, o cuando le sean aplicables las políticas al Responsable y/o Encargado ubicado fuera del territorio colombiano, en virtud de tratados internacionales, relaciones contractuales, entre otros.

Las disposiciones contenidas en esta Política se aplicarán a cualquier Base de Datos Personales que se encuentre en custodia de LA COMPAÑÍA o de terceros autorizados por ésta, bien sea en calidad de Responsable y/o como Encargado del Tratamiento, así como a los procesos organizacionales que involucren el Tratamiento de Datos Personales.

## III. Derechos de los titulares de los datos

Los Titulares de Datos Personales contenidos en Bases de Datos que reposen en los sistemas de información administrados por LA COMPAÑÍA, tienen los derechos descritos en este acápite en cumplimiento de las garantías fundamentales consagradas en la Constitución Política y la Ley.

El ejercicio de estos derechos será gratuito e ilimitado por parte del Titular el Dato Personal; sin perjuicio de disposiciones legales que regulen el ejercicio de los mismos.

El ejercicio del Habeas Data, expresado en los siguientes derechos, constituye una potestad personalísima y serán ejercidos por el Titular del dato de manera exclusiva, salvo las excepciones de Ley:

* ### 3.1. Derecho de acceso

Este derecho comprende la facultad del Titular del Dato Personal de obtener toda a información respecto de sus propios Datos Personales, sean parciales o completos, el Tratamiento aplicado a los mismos, de la finalidad del Tratamiento, la ubicación de las Bases de Datos que contienen sus Datos Personales, y sobre las comunicaciones, transferencias y/o transmisiones realizadas respecto de ellos, sean estas autorizadas o no.

* ### 3.2 Derecho de actualización

Este derecho comprende la facultad del Titular del dato de actualizar sus Datos Personales cuando éstos hayan tenido alguna variación.

* ### 3.3. Derecho de rectificación

Este derecho comprende la facultad del Titular del Dato Personal de modificar los Datos Personales que resulten ser inexactos, incompletos o inexistentes.

* ### 3.4. Derecho a la revocatoria del consentimiento

El Titular de los Datos Personales tiene el derecho de revocar el consentimiento o la autorización que habilitaba a LA COMPAÑÍA para un Tratamiento con determinada finalidad, salvo en aquellos casos contemplados como excepciones por la Ley y/o que sea necesario en un marco contractual específico. En caso de proceder una revocatoria de tipo parcial de la autorización para el Tratamiento de Datos Personales para algunas de las finalidades, LA COMPAÑÍA podrá seguir utilizando los datos para las demás finalidades respecto de las cuales no proceda dicha revocatoria.

* ### 3.5. Derecho de oposición

Este derecho comprende la facultad del Titular del Dato Personal de oponerse al Tratamiento de sus Datos Personales, salvo los casos en que tal derecho no proceda por disposición legal o por vulnerar intereses generales superiores al interés particular. LA COMPAÑÍA, con base en los legítimos derechos que argumente el Titular del Dato Personal, hará un juicio de proporcionalidad o ponderación con el fin de determinar la preeminencia o no del derecho particular del Titular del Dato personal sobre otros derechos.

* ### 3.6. Derecho a presentar quejas y reclamos o a ejercer acciones

El Titular del Dato Personal tiene derecho a presentar ante la Superintendencia de Industria y Comercio, o la entidad que fuera competente, quejas y reclamos, así como las acciones que resultaren pertinentes para la protección de sus datos una vez haya agotado el trámite de consulta o reclamo ante el Responsable del Tratamiento o Encargado del tratamiento. LA COMPAÑÍA dará respuesta a los requerimientos que realicen las autoridades competentes en relación con estos derechos de los Titulares de los Datos personales.

* ### 3.7. Derecho a otorgar autorización para el Tratamiento de datos

El Titular del dato tiene derecho a otorgar su autorización, para tratar sus Datos Personales en LA COMPAÑÍA. Esta autorización no será requerida en los siguientes casos o en los eventos señalados en la reglamentación vigente aplicable:

* **3.7.1.** Información requerida por una entidad pública o administrativa en ejercicio de sus funciones constitucionales o legales o por orden judicial.

* **3.7.2.** Datos de naturaleza pública.

* **3.7.3.** Casos de urgencia médica o sanitaria.

* **3.7.4.** Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.

* **3.7.5.** Datos relacionados con el Registro Civil de las Personas.

* **3.7.6.** Los demás que establezca la ley o el reglamento.

En estos casos, si bien no se requiere de la autorización del Titular, si tendrán aplicación los demás principios y disposiciones legales sobre protección de Datos Personales.

## IV. Deberes de los destinatarios de esta Política respecto de las bases de datos personales cuando ostenten la calidad de responsables y encargados

4.1. Deberes para los Responsables o Encargados del Tratamiento

Cuando LA COMPAÑÍA o cualquiera de los destinatarios de esta Política, asuman la calidad de Responsables y/o encargados del Tratamiento de Datos Personales bajo su custodia, cumplirán los siguientes deberes, sin perjuicio de las demás disposiciones previstas en la Ley y en otras que resulten aplicables de acuerdo con su actividad:

**a)**  Garantizar al Titular de Datos Personales, en todo tiempo, el pleno y efectivo ejercicio del derecho de Hábeas Data.

**b)** Solicitar y conservar, en las condiciones previstas en la Ley 1581 de 2012 y en el Decreto 1377 de 2013 o las normas que las modifiquen, adicionen o sustituyan, copia de la respectiva autorización y consentimiento otorgada por el Titular de Datos Personales.

**c)** Informar debidamente al Titular de Datos Personales sobre la finalidad de la recolección y los derechos que le asisten en virtud de la autorización otorgada.

**d)** Conservar los Datos Personales bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.

**e)** Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.

**f)** Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.

**g)** Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.

**h)** Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la Ley.

**i)** Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.

**j)** Tramitar las consultas y reclamos formulados en los términos señalados en estas políticas y en la Ley.

**k)** Adoptar una política y los procedimientos que garanticen el adecuado cumplimiento de la Ley 1581 de 2020 y/o las que la modifiquen, adicionen o complementen, y en especial, para la atención de consultas y reclamos.

**l)** Informar al Encargado del Tratamiento la circunstancia de que determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.

**m)** Informar a solicitud del Titular de los Datos Personales sobre el uso dado a los mismos.

**n)** Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares de Datos Personales.

**o)** Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio o la autoridad competente sobre la materia.

## V. Procedimiento de habeas data para el ejercicio de los derechos de información, acceso, actualización, rectificación, cancelación y oposición

En desarrollo de la garantía constitucional de Habeas Data respecto de los derechos de acceso, actualización, rectificación, cancelación y oposición por parte del titular de Datos Personales, o interesado habilitado legalmente, esto es, sus causahabientes y representantes legales, LA COMPAÑÍA adopta el siguiente procedimiento:

5.1. El Titular del dato y/o interesado en ejercer uno de estos derechos, acreditará esta condición mediante copia de su documento de su identidad, que podrá suministrar por medio físico o digital. En caso de que el Titular esté representado por un tercero deberá allegarse el respectivo poder, con el cumplimiento de los requisitos que la ley exija a esta clase de documento. El apoderado deberá igualmente acreditar su identidad en los términos indicados.

5.2. La solicitud para ejercer cualquiera de los derechos mencionados deberá hacerse por escrito, sea este físico o digital. La solicitud de ejercicio de los derechos mencionados podrá dirigirse por cualquiera de los Canales de Atención. LA COMPAÑÍA podrá disponer de otros medios idóneos para que el Titular de los Datos Personales ejerza sus derechos.

5.3. La solicitud de ejercicio de cualquiera de los derechos mencionados contendrá la siguiente información:

* 5.3.1. Nombre del Titular del Dato Personal y de sus representantes, de ser el caso.

* 5.3.2. Petición concreta y precisa de información, acceso, actualización, rectificación, cancelación, oposición o revocatoria del consentimiento. En cada caso, la petición deberá estar razonablemente fundamentada para que LA COMPAÑÍA proceda como Responsable de la Base de Datos Personales a dar respuesta.

* 5.3.3. Dirección física y/o electrónica para notificaciones.

* 5.3.4. Documentos que soportan la solicitud.

(i) Si faltare alguno de los requisitos aquí indicados, LA COMPAÑÍA así lo comunicará al interesado dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, para que los mismos sean subsanados. Si transcurren dos (2) meses sin que presente la información requerida, se entenderá que se ha desistido de la solicitud. LA COMPAÑÍA podrá disponer de formatos físicos y/o digitales para el ejercicio de este derecho y en ellos indicará si se trata de una consulta o de un reclamo del interesado.

(ii) Dentro de los dos (2) días hábiles siguientes a la recepción completa de la solicitud, LA COMPAÑÍA indicará que se trata de un reclamo en trámite. En la respectiva Base de Datos se deberá consignar una casilla en la que se indique las siguientes leyendas: “Reclamo en trámite” o “Reclamo resuelto”, según corresponda.

(iii) LA COMPAÑÍA, cuando sea Responsable del Tratamiento de la Base de Datos de Datos Personales contenidos en sus sistemas de información, dará respuesta a la solicitud en el término de diez (10) días hábiles si se trata de una consulta; y de quince (15) días hábiles si se trata de un reclamo. En igual término, se pronunciará cuando verifique que en sus sistemas de información no tiene Datos Personales del interesado que ejerce alguno de los derechos indicados.

(iv) En caso de consulta, si no fuere posible dar respuesta dentro del término de diez (10) días hábiles, se informará al interesado los motivos de demora y la fecha en la que se atenderá el reclamo, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento de los primeros diez (10) días hábiles.

(v) En caso de reclamo, si no fuere posible dar respuesta dentro del término de quince (15) días hábiles, se informará al interesado los motivos de demora y la fecha en la que se atenderá el reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento de los primeros quince (15) días hábiles.

(vi) LA COMPAÑÍA, en los casos que detente la condición de Encargado del Tratamiento, informará tal situación al Titular o interesado en el Dato Personal, y comunicará al Responsable del Dato Personal la solicitud, con el fin de que éste dé respuesta a la solicitud de consulta o reclamo presentado. Copia de tal comunicación será dirigida al Titular del Dato Personal o interesado, para que tenga conocimiento sobre la identidad del Responsable del Dato Personal y en consecuencia del obligado principal de garantizar el ejercicio de su derecho.

(vii) LA COMPAÑÍA documentará y almacenará las solicitudes realizadas por los Titulares de los Datos Personales o por los interesados en ejercicio de cualquiera de los derechos, así como las respuestas a tales solicitudes. Esta información será tratada conforme a las normas aplicables a la gestión documental de la organización.

(viii) Para acudir a la Superintendencia de Industria y Comercio en ejercicio de las acciones legales contempladas para los Titulares de datos o interesados, se deberá agotar previamente el trámite de consultas y/o reclamos aquí descrito.

## VI. Finalidades del tratamiento de datos personales

El Tratamiento de los Datos Personales de los Titulares, que otorguen autorización expresa y previa a la COMPAÑÍA, se efectuará conforme las siguientes finalidades:

6.1. Datos Personales relacionados con la Gestión del Talento Humano

LA COMPAÑÍA tratará los Datos Personales de sus Colaboradores, así como de aquellos que se postulen para vacantes, en tres momentos, a saber: antes, durante y después de la relación laboral y/o de servicios.

6.1.1. Tratamiento de Datos Personales antes de la relación contractual laboral:

LA COMPAÑÍA informará, de manera anticipada, a las personas interesadas en participar en un proceso de selección adelantado directamente o a través de terceros debidamente contratados por LA COMPAÑÍA para ese propósito, las reglas aplicables al Tratamiento de los Datos Personales que suministre el interesado, así como respecto de aquellos que se obtengan durante el proceso de selección.

La información obtenida por LA COMPAÑÍA respecto de quienes no fueron seleccionados, resultados de las pruebas sicotécnicas y entrevistas, serán eliminados de sus sistemas de información a solicitud del Titular.

LA COMPAÑÍA podrá transmitir y/o transferir la información personal recolectada a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz y a las demás personas naturales o jurídicas que contrate para la selección de personal.

LA COMPAÑÍA, cuando contrate procesos de selección de personal con terceros, regulará en los contratos el Tratamiento que se deberá dar a los Datos Personales entregados por los interesados, así como la destinación de la información personal obtenida del respectivo proceso.

Los Datos Personales e información obtenida del proceso de elección respecto del personal seleccionado para laborar en LA COMPAÑÍA, serán almacenados en la carpeta personal, aplicando a esta información los niveles y medidas de seguridad requeridas por la normatividad, en virtud de la potencialidad de que tal información contenga datos de carácter sensible.

La finalidad de la entrega de los datos suministrados por los interesados en las vacantes de LA COMPAÑÍA es la participación en los procesos de selección vigentes en sí misma, así como análisis estadísticos y perfilamientos, para lo cual los datos se almacenarán y tratarán de acuerdo con la finalidad descrita hasta por 5 años.

6.1.2. Tratamiento de datos durante la relación contractual laboral:

LA COMPAÑÍA almacenará los Datos Personales e información personal obtenida del proceso de selección de los Colaboradores en una carpeta identificada con el nombre de cada uno de ellos. Esta carpeta física o digital solo será accedida y tratada por las personas jurídicas o áreas de LA COMPAÑÍA encargada de administrar la relación contractual entre LA COMPAÑÍA y el Colaborador.

LA COMPAÑÍA podrá transmitir y/o transferir la información personal recolectada a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz y a las demás personas naturales o jurídicas que contrate para la administración de la relación contractual entre LA COMPAÑÍA y sus Colaboradores.

El Tratamiento que LA COMPAÑÍA dará a los Datos Personales, tendrá la finalidad de cumplir con las obligaciones derivadas de la relación contractual laboral, incluyendo pero sin limitarse a la gestión del personal que involucra entre otros el pago y administración de nómina (incluyendo pero sin limitarse a salarios, prestaciones sociales legales y extralegales, beneficios, bonificaciones, reembolsos, seguros, realizar las deducciones autorizadas por la Ley, por orden de autoridad competente o por instrucción del Colaborador) y programas de bienestar personal del Colaborador y su familia; realizar los aportes al Sistema de Seguridad Social Integral; la asignación de las elementos de trabajo como son los equipos de comunicación y cómputo, puesto de trabajo, correo electrónico y demás que requiera por las particularidades del cargo; la contratación de seguros; el desarrollo de personal; la administración del talento humano a través de pruebas de evaluación de desempeño; garantizar la seguridad y salud de los colaboradores; asegurar el cumplimiento de obligaciones de confidencialidad y otras obligaciones laborales; garantizar el derecho de libertad de asociación; contactar a sus familiares cuando se requiera; la emisión de certificaciones laborales; el uso de la imagen o de datos relevantes para campañas publicitarias o comerciales de la empresa; identificación por seguridad e imagen; para auditorías internas o externas; para el envío de información de interés o corporativa; para la ejecución directa o solicitud de ejecución a un tercero de pruebas que evidencien la presencia de alcohol o de sustancias psicoactivas o psicotrópicas, en los casos autorizados por la ley; Igualmente, cualquier otra finalidad que sea compatible y pueda considerarse análoga a las señaladas.

La recolección de Datos Personales biométricos de los colaboradores, que sean captados por los sistemas de video vigilancia, de registro fotográfico, de captura de la huella digital o cualquier otro sistema de seguridad con los que cuente LA COMPAÑÍA, serán tratados con el fin de llevar un control de acceso a las instalaciones y velar por su seguridad. Los datos así recolectados podrán servir como prueba en los procesos disciplinarios o judiciales que adelante LA COMPAÑÍA e igualmente ser transferidos a los proveedores de bienes o servicios contratadas para alguna de las actividades antes descritas.

La Compañía también podrá transferir y/o transmitir los datos personales de sus Colaboradores a terceros con los cuales suscriba acuerdos de sustitución patronal o celebre cualquier tipo de operación societaria, de enajenación de establecimiento de comercio, entre otras que implique dicha sustitución.

El uso de la información de los Colaboradores para fines diferentes a los descritos, requerirá una autorización específica que incluya las nuevas finalidades. El uso diferente de los datos e información personal de los Colaboradores procederá por orden de autoridad competente, siempre que en ella radique tal facultad. Lo anterior, salvo autorización previa y escrita que documente el consentimiento por parte del Titular del Dato Personal o disposición legal sobre la materia.

6.1.3. Tratamiento de datos después de terminada la relación contractual laboral:

Terminada la relación laboral, cualquiera que fuere la causa, LA COMPAÑÍA procederá a almacenar los Datos Personales obtenidos del proceso de selección y documentación generada en el desarrollo de la relación laboral, en un archivo central, administrado por LA COMPAÑÍA o por terceros sometiendo tal información a medidas y niveles de seguridad requeridas por la normatividad, en virtud de la potencialidad de que la información laboral pueda contener Datos Sensibles.

Los Datos Personales de ex Colaboradores y/o pensionados, tendrán como finalidad la de cumplir con las obligaciones derivadas de la relación laboral que existió, incluyendo pero sin limitarse a la emisión de certificaciones laborales; reconocimiento de pensiones y/o sustituciones pensionales, emisión de certificados para liquidación de bonos pensionales, certificaciones para cobros y pagos de cuotas partes pensionales, cálculos actuariales, reconocimiento de beneficios y para auditorías internas o externas o su presentación como pruebas documentales en eventuales procesos judiciales y administrativos que se refieran al ex Colaborador.

LA COMPAÑÍA no cederá tal información a terceras partes, salvo autorización previa y escrita que documente el consentimiento por parte del Titular del Dato Personal o disposición legal sobre la materia.

Sin perjuicio de lo anterior, LA COMPAÑÍA podrá transferir y/o transmitir los datos personales de los pensionados a su cargo a terceros con los cuales suscriba acuerdos para el pago y liquidación de la respectiva pensión, o celebre cualquier tipo de operación societaria, de enajenación de establecimiento de comercio, entre otras que implique que terceros asumen el pago y liquidación de dicha prestación.

6.2. Tratamiento de Datos Personales de accionistas:

Los Datos Personales de los accionistas de LA COMPAÑÍA se considerará información reservada, por expresa disposición legal. En consecuencia, el acceso a tal información personal se realizará conforme las normas contenidas en el Código de Comercio, la Ley 964 de 2005, el Decreto 2555 de 2012, las normas que las modifiquen, adicionen o sustituyan y las demás normas que apliquen sobre la materia, incluyendo las del mercado público de valores. LA COMPAÑÍA solo usará los Datos Personales de los accionistas para las finalidades derivadas de la relación estatutaria existente.

6.3. Tratamiento de Datos Personales de proveedores:

LA COMPAÑÍA recabará de sus proveedores los Datos Personales que sean necesarios, pertinentes y no excesivos para la finalidad de selección, evaluación y ejecución del contrato a que haya lugar. Cuando se le exija a LA COMPAÑÍA por naturaleza jurídica la divulgación de datos del proveedor persona natural consecuencia de un proceso de contratación, ésta se efectuará con las previsiones que den cumplimiento a lo dispuesto en estas políticas y que prevengan a terceros sobre la finalidad de la información que se divulga.

LA COMPAÑÍA recolectará de sus proveedores los Datos Personales de los Colaboradores de éste, que sean necesarios, pertinentes y no excesivos, que por motivos de seguridad deba analizar y evaluar, atendiendo las características de los servicios que se contraten con el proveedor.

Cuando LA COMPAÑÍA entregue Datos Personales de sus Colaboradores a sus proveedores, estos deberán proteger los Datos Personales suministrados, conforme lo dispuesto en estas políticas. Para tal efecto se incluirá la previsión de auditoría respectiva en el contrato o documento que legitima la entrega de los Datos Personales. LA COMPAÑÍA verificará que los datos solicitados sean necesarios y pertinentes respecto de la finalidad que fundamente la solicitud de acceso a los mismos.

LA COMPAÑÍA podrá transmitir y/o transferir la información recolectada a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz y a las demás personas naturales o jurídicas que contrate para la administración de los datos recolectados de sus proveedores o aliados comerciales.

6.4. Tratamiento de Datos Personales en procesos de contratación de proveedores:

La finalidad para el tratamiento de los datos personales corresponde a cualquiera inherente, necesaria y pertinente en el proceso de contratación. Los terceros que, en procesos de contratación, alianzas y acuerdos de cooperación con LA COMPAÑÍA, accedan, usen, traten y/o almacenen Datos Personales de Colaboradores de LA COMPAÑÍA y/o de terceros relacionados con dichos procesos contractuales, adoptarán en lo pertinente lo dispuesto en estas políticas, así como las medidas de seguridad que le indique LA COMPAÑÍA según el tipo de dato de carácter personal tratado.

Para tal efecto, se incluirá la previsión de auditoría respectiva en el contrato o documento que legitima la entrega de los Datos Personales. LA COMPAÑÍA verificará que los datos solicitados sean necesarios, pertinentes y no excesivos respecto de la finalidad del Tratamiento.

6.5. Tratamiento de Datos Personales de clientes o usuarios de los servicios públicos domiciliarios de energía eléctrica y cualquier otro prestado por LA COMPAÑÍA.

Los Datos Personales contendidos en las Bases de Datos de LA COMPAÑÍA cuyo Tratamiento está relacionado con la prestación de los servicios públicos domiciliarios de energía eléctrica o cualquier otro y con cobros que sean permitidos realizar a través de la factura por el Titular, tendrán como finalidad dependiendo del tipo de servicio público domiciliario: (i) la lectura de contadores de los usuarios del servicio; (ii) la facturación y recaudo por la prestación del servicio público domiciliario; (iii) la facturación y recaudo de los conceptos de alumbrado público, aseo y los que expresamente autorice el Titular, la ley o la regulación sectorial aplicable; (iv) realizar la gestión de cobro por el servicio público domiciliario respectivo; (v) la realización de encuestas, estudios e investigaciones de mercado; (vi) en general, todo lo necesario para la debida prestación del servicio público domiciliario correspondiente, de conformidad con las Leyes 142 y 143 de 1994, demás normas de carácter reglamentario y regulatorio emitidas por autoridad competente y el respectivo contrato que regule las relaciones entre el Cliente y LA COMPAÑÍA; y (vii) utilizar los Datos Personales de sus clientes o usuarios del servicio público domiciliario para el envío de ofertas y/o promociones de productos propios o de terceros, por cualquier medio físico, digital o electrónico, incluyendo WhatsApp, Facebook, Instagram y cualquier otra red social, para la venta de cualquier producto y/o servicio que ofrezcan en desarrollo de su objeto social y para cualquiera de las finalidades listadas en el numeral 6.6 siguiente.

LA COMPAÑÍA podrá transmitir y/o transferir la información personal recolectada a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz y a las demás personas naturales o jurídicas con las cuales tengan directa o indirectamente alianzas comerciales para la adquisición, promoción y financiación por parte de sus clientes o usuarios de los bienes y servicios que promocione directa o indirectamente.

6.6. Tratamiento de Datos Personales de otros Clientes de bienes y servicios distintos a los servicios públicos domiciliarios.

Los Datos Personales contendidos en las Bases de Datos de LA COMPAÑÍA cuyo Tratamiento está relacionado con la venta o prestación de cualquier otro bien o servicio, diferente de la prestación de los servicios públicos domiciliarios, podrán ser utilizados con fines comerciales y/o publicitarios de opciones y productos propios o de terceros ofrecidos al público a través de cualquier medio físico o electrónico, así como para la ejecución de las relaciones contractuales presentes o futuras, incluyendo pero sin limitarse, a las siguientes actividades:

Completar automáticamente los documentos asociados a las transacciones que realice el cliente.

Dar cumplimiento a todas y cada una de las obligaciones adquiridas en la relación contractual existente con el cliente.

Entrega y estado de sus pedidos.

Encuestas de satisfacción.

Prevención y protección frente al fraude.

Cumplimiento de la regulación sobre lavado de activos y financiación del terrorismo.

Perfilamiento crediticio.

Ofrecer medios de financiación, y para esto verificar y analizar el comportamiento crediticio actual e histórico del titular, estimar los niveles de ingresos del titular, validar la identidad del titular, realizar estudios de crédito, entre otros.

Cumplimiento de obligaciones crediticias, así como los reportes actualizaciones a que haya lugar en los bancos de información financiera mientras exista la relación contractual u obligaciones pendientes de pago.

Analítica de comportamientos y hábitos de consumo.

Enviar ofertas y/o promociones de productos propios o de terceros, por cualquier medio físico, digital o electrónico, incluyendo WhatsApp, Facebook, Instagram y cualquier otra red social, para la venta de cualquier producto y/o servicio que ofrezcan en desarrollo de su objeto social.

LA COMPAÑÍA podrá transmitir y/o transferir la información personal recolectada y las analíticas de datos crediticios, comerciales y de consumo a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz Encargadas del tratamiento de los Datos Personales, y a las demás personas naturales o jurídicas con las cuales tenga directa o indirectamente alianzas comerciales para la adquisición, promoción y financiación por parte de sus clientes o usuarios de los bienes y servicios que promocione directa o indirectamente.

6.7. Tratamiento de datos personales de las personas que participen directa o indirectamente en procesos de licenciamiento ambiental, consultas previas, obras por impuestos o cualquier otro tipo de autorización administrativa.

La recolección de Datos Personales de personas naturales que LA COMPAÑÍA trate en desarrollo de acciones relacionadas con la ejecución de sus proyectos, tales como el levantamiento de información socioeconómica, cultural y ambiental en el marco de procesos de participación social o comunitaria, licenciamiento ambiental, permisos ambientales, cumplimiento de medidas socioambientales, consultas previas, obras por impuestos y/o obtención de cualquier otro tipo de autorización de naturaleza administrativa, se sujetará a lo dispuesto en esta política.

Los datos personales recopilados, se utilizarán exclusivamente para todas aquellas actividades relacionadas directamente con el objeto del respectivo trámite o autorización administrativa.

LA COMPAÑÍA informará y obtendrá la autorización de los Titulares de los Datos Personales en los documentos e instrumentos que utilice para el efecto, relacionados con estas actividades.

LA COMPAÑÍA para los fines indicados podrá transmitir y/o transferir la información personal recolectada a su matriz (Grupo Argos S.A), a las demás empresas filiales o subordinadas de la matriz y a las autoridades administrativas que en ejercicio de sus facultades Constitucionales, legales o reglamentarias así lo requieran.

6.8. Tratamiento de Datos Personales de la comunidad en general.

La recolección de Datos Personales de personas naturales que LA COMPAÑÍA trate en desarrollo de acciones relacionadas con la comunidad, tales como implementación de programas de responsabilidad social empresarial, voluntariado corporativo, o de cualquiera otra actividad que implique la recolección y tratamiento de datos de personas naturales con quienes no existe una relación contractual previamente establecida, se sujetará a la finalidad propia e inherente a cada actividad y a lo dispuesto en esta política.

LA COMPAÑÍA informará y obtendrá la autorización de los Titulares de los Datos Personales en los documentos e instrumentos que utilice para el efecto, relacionados con estas actividades en donde se indicarán las finalidades del Tratamiento.

LA COMPAÑÍA para los fines indicados podrá transmitir y/o transferir la información personal recolectada a su matriz (Grupo Argos S.A) y a las demás empresas filiales o subordinadas de la matriz y a las demás personas naturales o jurídicas que contrate para desarrollo de acciones relacionadas con la comunidad.

En cada uno de los casos antes descritos, las áreas de la organización que desarrollen los procesos de negocios en los que se involucren Datos Personales, deberán considerar en sus estrategias de acción la formulación de reglas y procedimientos que permitan cumplir y hacer efectivas las políticas aquí adoptadas, además de prevenir posibles sanciones legales.

6.9. Tratamiento de Datos Personales Biométricos

La recolección de Datos Personales biométricos de cualquier persona natural diferente de los Colaboradores, que sean captados por los sistemas de video vigilancia, de registro fotográfico, de captura de la huella digital o cualquier otro sistema de seguridad con los que cuente LA COMPAÑÍA, serán tratados con el único fin de llevar un control de acceso a las instalaciones y para procurar por la seguridad de Visitantes, Colaboradores, Clientes y la Comunidad en general.

Los datos así recolectados podrán servir como prueba en los procesos judiciales que adelante LA COMPAÑÍA e igualmente ser transferidos a los proveedores de bienes o servicios contratadas para alguna de las actividades antes descritas.

## VII. Prohibiciones

En desarrollo de las políticas de seguridad de la información personal de LA COMPAÑÍA, se establecen las siguientes prohibiciones y sanciones como consecuencia de su incumplimiento.

7.1. LA COMPAÑÍA prohíbe el acceso, uso, gestión, cesión, comunicación, almacenamiento y cualquiera otro Tratamiento de Datos Personales de carácter sensible sin autorización del Titular del Dato Personal.

El incumplimiento de esta prohibición por parte de los Colaboradores de LA COMPAÑÍA será considerado como falta grave, que podrá dar lugar a la terminación de la relación laboral. Lo anterior, sin perjuicio de las demás acciones legales a que haya lugar.

El incumplimiento de esta prohibición por parte de los proveedores que contraten con LA COMPAÑÍA será considerado como causa grave para dar terminación al contrato, sin perjuicio de las demás acciones a que haya lugar.

En los contratos con los proveedores, donde el objeto contratado tenga relación con Datos Personales, se pactará una previsión en relación con las disposiciones especiales que deben considerar para el tratamiento de los datos personales y los perjuicios que se pueden llegar a ocasionar a LA COMPAÑÍA como consecuencia de la imposición de multas, sanciones operativas, entre otras, por parte de las autoridades competentes y como consecuencia del obrar imprudente y negligente del proveedor.

7.2. LA COMPAÑÍA prohíbe la cesión, comunicación o circulación de Datos Personales, sin el consentimiento previo, escrito y expreso del Titular del Dato Personal o sin autorización de LA COMPAÑÍA. La cesión o comunicación de Datos Personales deberá ser inscrita en el registro central de Datos Personales de LA COMPAÑÍA y contar con la autorización del Custodio de la Base de Datos.

7.3. LA COMPAÑÍA prohíbe el acceso, uso, cesión, comunicación, tratamiento, almacenamiento y cualquiera otro Tratamiento de Datos Personales de carácter sensible, diferentes a aquellos que por ley sean de obligatorio tratamiento.

7.4. LA COMPAÑÍA prohíbe a los destinatarios de estas políticas cualquier Tratamiento de Datos Personales que pueda dar lugar a alguna de las conductas descritas en la Ley de delitos informáticos 1273 de 2009.

7.5. LA COMPAÑÍA prohíbe el Tratamiento de Datos Personales de niños, niñas y adolescentes menores de edad, salvo autorización expresa de sus representantes legales. Todo Tratamiento de datos personales de niños, niñas y adolescentes de naturaleza pública está permitido de conformidad con lo establecido en el artículo 7° de la Ley 1581 de 2012, siempre que el mismo responda y respete el interés superior de los niños, niñas y adolescentes y se asegure el respeto de sus derechos fundamentales. En el Tratamiento se asegurará el respeto a los derechos prevalentes de los niños, niñas y adolescentes, para lo cual el representante legal del niño, niña o adolescente otorgará la autorización, previo ejercicio del menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto. Todo responsable y encargado involucrado en el tratamiento de los datos personales de niños, niñas y adolescentes velará por el uso adecuado de los mismos aplicando los principios y obligaciones establecidos en el régimen de protección de datos personales.

La finalidad del Tratamiento de Datos Personales de niños, niñas y adolescentes menores de edad será informada expresamente a su representante legal; sin perjuicio de ello, los principales escenarios de tratamientos que tienen previstos LA COMPAÑÍA son:

a. Desarrollo de actividades de bienestar para los familiares de los Colaboradores de LA COMPAÑÍA.

b. Diseño y ejecución de proyectos de responsabilidad social empresarial.

## VIII. Roles y responsabilidades en el cumplimiento de la protección de datos personales

La responsabilidad en el adecuado Tratamiento de Datos Personales al interior de LA COMPAÑÍA está en cabeza de todos sus Administradores y Colaboradores.

En consecuencia, al interior de cada área que maneje los procesos de negocios que involucren Tratamiento de Datos Personales, deberán adoptar las reglas y procedimientos para la aplicación y cumplimiento de las presentes políticas, dada su condición de custodios de la información personal contenida en los sistemas de información de LA COMPAÑÍA.

Todos los colaboradores de la organización, en caso de duda respecto del Tratamiento de los Datos Personales, acudirán al Oficial de Protección de Datos Personales, al área Responsable de la seguridad de la información y/o al equipo Jurídico para que indiquen la directriz a seguir, según el caso.

## IX. Glosario

Para los fines del presente documento, los términos en mayúsculas que aquí se usan tendrán el significado asignado a dichos términos en la Ley 1581 de 2012 y en el Decreto 1377 de 2013.

Base de datos: Es todo conjunto organizado de datos personales que sea objeto de tratamiento.

Canales de atención: medios a través de los cuales el Titular puede ejercer sus derechos, y son: i) Para clientes o usuarios del servicio público domiciliario de energía eléctrica suministrado por LA COMPAÑÍA el correo de contacto es escribenos@enerbit.co; para los demás titulares de datos personales el correo de contacto es protecciondatospersonales@celsia.com; (ii) WhatsApp (+57) 3112706213, iii) enviando una comunicación a la Carrera 43 A No. 1 A SUR 143 Medellín, Antioquia - Atención Oficial Protección de Datos Personales.

Custodio de la base de datos: Es la persona que tiene bajo su custodia la base de Datos Personales al interior de LA COMPAÑÍA.

Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.

Datos públicos: Es el dato que no sea semiprivado, privado o sensible.

Datos sensibles: Se entiende por Datos Sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación.

Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.

Habeas Data: Derecho fundamental de toda persona para conocer, actualizar, rectificar y/o cancelar la información y Datos Personales que de ella se hayan recolectado y/o se traten, en Bases de Datos públicas o privadas, conforme lo dispuesto en la Ley y demás normatividad aplicable.

Oficial de Protección de Datos Personales: Persona y/o área responsable, entre otras funciones, de supervisar que los diferentes equipos de la COMPAÑÍA den atención a las peticiones, consultas y reclamos ante la cual el titular de la información puede ejercer sus derechos a conocer, actualizar, rectificar y suprimir el dato y revocar la autorización, para efectos de esta política el Oficial de Protección de Datos Personales de LA COMPAÑÍA será el Oficial de Cumplimiento de Celsia S.A.

Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos.

Titular: Es la persona física cuyos datos sean objeto de Tratamiento.

Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como recolección, almacenamiento, uso, circulación o supresión.

Transferencia: La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de Datos Personales, ubicado en Colombia, envía la información o los Datos Personales a un receptor que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.

Transmisión: La transmisión de datos personales por su parte implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia y tiene por objeto la realización de un tratamiento por parte del Encargado por cuenta del Responsable.

## X. Vigencia y modificación de esta política

La presente política de tratamiento de datos fue aprobada en cada COMPAÑÍA por el órgano de dirección competente y aplicará a partir del día 04 de marzo de 2022. Esta Política podrá ser modificada por LA COMPAÑÍA en cualquier momento. En tal evento, LA COMPAÑÍA informará, por los medios que se estime convenientes, de cualquier cambio sustancial con anterioridad a que dicha modificación entre en vigencia.

En constancia de aprobación del presente documento, suscribe el representante legal de enerBit S.A.S. E.S.P.

**GABRIEL ANDRÉS VIZCAINO SÁNCHEZ**
Representante Legal enerBit S.A.S. E.S.P.`;

export const warranty = `# Garantías mecanismos de cubrimiento
#### Costo garantía MEM
|Periodo|Costo $|
|:---|---:|
|Jun-22|$0|
|Jul-22|$0|

#### Costo SDL+STR
|Periodo|SDL+STR sur|SDL+STR norte|
|:---|---:|---:|
|Jun-22|$33.268|$0|
|Jul-22|$0|$467.312|`;

export const OHSPolicy = `# POLÍTICA DE SEGURIDAD Y SALUD EN EL TRABAJO 

## Declaración

Para ENERBIT la seguridad es un valor de vida por eso fomentamos el  autocontrol, el autocuidado y actuamos preservando nuestra seguridad y la de otros para llegar sanos y salvos a nuestros hogares.

ENERBIT como organización que comercializa energía eléctrica declara el compromiso de:

- Integrar la gestión de prevención de riesgos laborales y salud ocupacional a la estrategia empresarial de la Compañía.

- Implementar, mantener y mejorar de manera continua un Sistema de Gestión en Seguridad y salud en el trabajo para la gestión de los riesgos laborales.

- Proteger la integridad y promover el bienestar de sus trabajadores, contratistas, subcontratistas, terceros, visitantes y demás partes interesadas.

- Identificar los peligros, evaluar y valorar los riesgos de las actividades de comercialización y establecer los respectivos controles, con el fin de evitar y minimizar los accidentes de trabajo, enfermedades laborales o lesiones personales.

- Cumplir la normatividad vigente aplicable en materia de Salud y Seguridad en el trabajo y otros requisitos que sean adoptados por nuestra organización.

- Brindar los recursos necesarios para el desarrollo del sistema de gestión de seguridad y salud en el trabajo y facilitar espacios de participación y consulta para mejorar su desempeño.

## Alcance

Esta Política aplica a todos los centros de trabajo y Colaboradores de EnerBit S.A.S. E.S.P. Independiente de su forma de contratación o vinculación, incluyendo los contratistas y subcontratistas.

## Lineamientos de la política

**Para cumplirlo, ENERBIT ha definido los siguientes 5 principios de seguridad:**

1. Trabaja seguro, quédate con nosotros
    - La seguridad como valor de vida.
    - La seguridad es calidad de vida.
    - La seguridad es condición de empleo. 

2. Me cuido y te cuido para vivir la seguridad integralmente
    - Cuido mi bienestar.
    - Evalúo los riesgos de forma integral.
    - Integro buenas prácticas seguras.

3. Puedo prevenir los incidentes en todo momento
    - Reporto, mejoro, aprendo.
    - Aseguro el proceso y condiciones.
    - Asumo comportamientos propositivos.

4. Soy ejemplo, tengo derecho a decir “alto” a los actos y condiciones inseguras
    - No puedo ser permisivo con los actos, ni condiciones inseguras.
    - La seguridad no es negociable, ni transferible.
    - Asumo mi responsabilidad.

5. Siendo seguros, somos sostenibles
    - Somos productivos siendo seguros.
    - Aseguramos la continuidad del negocio.
    - Tenemos la capacidad de respuesta para atender cualquier eventualidad.


**Gabriel Andrés Vizcaíno Sánchez**

Rep. Legal
`;

export const tycAPP = `
# TÉRMINOS Y CONDICIONES DE USO PARA EL USO DE LA APLICACIÓN ENERBIT

## ACEPTACIÓN TOTAL DE TÉRMINOS Y CONDICIONES DE USO

Al descargar, instalar, acceder, usar o brindar información desde y hacia la aplicación móvil enerBit, (“la Aplicación”), de propiedad de ENERBIT S.A.S. E.S.P. (“la Compañía”), el usuario admite haber leído y entendido los presentes términos y condiciones de uso (“Términos y Condiciones”) y acepta sujetarse a los mismos y cumplir con todas las leyes y reglamentos aplicables que hagan parte de la legislación colombiana.

En caso de que el usuario no esté de acuerdo, de forma total o parcial, con los Términos y Condiciones, deberá abstenerse inmediatamente de acceder y/o usar la Aplicación o cualquier servicio disponible a través de esta o cualquier información contenida en la Aplicación.

Los Términos y Condiciones y todo aquello relacionado con la Aplicación, se rigen por la legislación colombiana, por tanto, quien decida acceder a la Aplicación desde fuera del territorio colombiano deberá asegurarse de cumplir con la legislación aplicable en dicho país.

## GENERALIDADES

En los presentes Términos y Condiciones, la expresión “Usuario” se refiere a aquellas personas que descarguen, instalen, accedan, usen o brinden información desde y hacia la Aplicación y/o tengan contratado los servicios de eficiencia energética con la Compañía, así como a aquellos que puedan obtener acceso a la misma como “Suscriptor” a través de la cuenta del Usuario que contrató los servicios.

Para poder acceder y usar la Aplicación, el Usuario deberá contar con un dispositivo móvil inteligente (Smartphone) con sistema operativo (Android o IOS), cualquiera de estos con acceso a internet, ambos seguros, confiables y además deberá contar siempre con la última versión. Sin embargo, no podemos garantizar que la Aplicación sea compatible con el dispositivo móvil inteligente del Usuario o Suscriptor.

Solo podrán ser Usuarios de la Aplicación personas naturales, mayores de edad, que cuenten con capacidad legal para contratar, que actúen a nombre propio o en representación de una persona jurídica.

Para tener acceso al contenido y a todos los servicios ofrecidos a través de la Aplicación, el Usuario deberá contar con correo electrónico, que será su cuenta de acceso, y contraseña, ésta última le será suministrada inicialmente por la Compañía. Cuando el Usuario inicie por primera vez la sesión en la Aplicación deberá actualizar la contraseña.

Una vez el Cliente se registre, podrá recibir a la(s) dirección(es) de correo electrónico registrada(s), número de celular y/o WhatsApp, comunicaciones referentes a la Aplicación.

El Usuario no podrá permitir ni autorizar el uso de su cuenta a terceras personas sin obtener la autorización expresa de la Compañía. La cuenta y contraseña de cada Usuario, será personal e intransferible.

El Usuario se obliga a notificar por el medio más expedito a la Compañía cualquiera de las siguientes situaciones: (i) pérdida o hurto de su contraseña; (ii) uso no autorizado de su contraseña; (iii) alguna falla, error o hecho inusual al recibir algún mensaje relacionado con una orden iniciada por el Usuario a través de la Aplicación o que haya sido recibida y/o ejecutada a través del mismo; (iv) el ingreso o intentos de ingreso por terceros no autorizados. No obstante lo anterior, y aun mediando notificación oportuna, el Usuario será responsable por la confidencialidad y el uso de la cuenta y contraseña y asumirá total responsabilidad por las transacciones que se realicen en la Aplicación mediante el uso de la misma. De igual forma, se compromete a modificar su contraseña en forma inmediata, en el evento en el que tenga sospechas del conocimiento por parte de un tercero o vulneración de seguridad.

Así mismo, el Usuario deberá entregar en todo momento datos personales e información actualizada, completa, real, veraz y fidedigna, la cual deberá mantenerla actualizada en los casos en que se produzca algún cambio o cuando así lo solicite la Compañía por cualquier medio de comunicación o contacto. La Compañía podrá discrecionalmente solicitar información o documentación adicional para el uso de la Aplicación. Lo anterior con el fin de verificar la información entregada por el Usuario, rechazar un registro o cancelar o suspender temporal o definitivamente al Usuario cuando sus datos no puedan ser confirmados o sean incongruentes.

Igualmente, la Compañía, de forma discrecional o en el evento que el Usuario incumpla los Términos y Condiciones o cualquier otra disposición relacionada con la Aplicación, la Compañía podrá retirar, suspender o bloquear a un Usuario de la Aplicación sin que haya lugar a pago de indemnización alguna.

Si una o más de las disposiciones contenidas en estos Términos y Condiciones son consideradas nulas, ilegales o ineficaces en cualquier aspecto, la validez, legalidad, exigibilidad o eficacia del resto de las disposiciones aquí contenidas no se verán afectadas o anuladas por dicha circunstancia.

## ALCANCE Y USO DE LA APLICACIÓN

El Usuario podrá utilizar la Aplicación para uso personal, teniendo permitido la lectura, visualización, envío y descarga del material o contenido que se derive explícitamente de lo que tenga contratado con la Compañía, dentro de los que se encuentran pero no se limitan a: el análisis de sus consumos energéticos, los valores facturados por cualquier concepto, certificados ReverdeC o REC (según aplique), elección o cambio de plan de Eficiencia Energética, pago de la factura del servicio público domiciliario de energía eléctrica y los valores adicionales que en ella se incluyan, como los servicios de eficiencia energética entre otros, y en general, el Usuario podrá hacer uso de todo el contenido y servicios que se encuentren disponibles en la Aplicación, según el tipo de productos y/o servicios ofertados y/o contratados, siempre y cuando sea Usuario de la Compañía.

El Usuario acepta y autoriza que los registros electrónicos de las actividades que realice en la Aplicación constituyen plena prueba de estos.

## DERECHOS DEL USUARIO

El Usuario tiene derecho a consultar en cualquier momento los Términos y Condiciones y a aceptarlos o no, por lo que podrá evitar el uso, así como desinstalarla de su dispositivo y/o elevar consultas a la Compañía.

## DEBERES DEL USUARIO

Sin perjuicio de otras obligaciones que se encuentren descritas en otros apartes de los Términos y Condiciones, el Usuario se obliga a:

• Asumir la responsabilidad única y exclusiva de las actividades que realice o ejecute a través la Aplicación.
• Indemnizar y eximir de responsabilidad a la Compañía por cualquier reclamo relacionado con el uso indebido o abuso del uso de la Aplicación, o por el incumplimiento de los Términos y Condiciones.
• Prestar toda la colaboración que sea necesaria para la defensa de la Compañía en cualquier tipo de reclamación o acción judicial o administrativa en la que sea parte y que esté relacionada con el uso de la Aplicación.
• Adoptar las medidas necesarias para ingresar a la Aplicación, contando con programas de protección y/o antivirus para manejo de malware, spyware y todas las demás herramientas que le permitan proteger su dispositivo móvil y sus datos.

De igual forma, el Usuario se obliga a usar la Aplicación y los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:

- Reproducir, copiar, representar, publicar, utilizar, distribuir, transformar o modificar el contenido de la Aplicación o de cualquier otra manera realizar actos de disposición o explotación sobre esta, por cualquier procedimiento o sobre cualquier soporte, total o parcial o permitir el acceso del público a través de cualquier modalidad de comunicación pública.

- Publicar cualquier información, imagen o cualquier contenido que explote o afecte los derechos de menores de edad.

- Emplear el contenido y en particular, la información de cualquier clase obtenida a través de la Aplicación o de los servicios que en esta se ofrecen para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o el contenido de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como abstenerse de comercializar o divulgar de cualquier modo dicha información.

- Utilizar la Aplicación y su contenido con fines o efectos ilícitos, contrarios a la moral, a las buenas costumbres generalmente aceptadas, al orden público, a lo establecido en los Términos y Condiciones o al uso mismo de la Aplicación, que sean lesivos a los derechos e intereses de terceros o de la Compañía o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la Aplicación, su contenido, productos, servicios o impedir la normal utilización o disfrute de esta y del contenido por parte de los usuarios.

En caso de que el Cliente desinstale o elimine la aplicación, esto no genera la liberación de las demás obligaciones que haya adquirido con la Compañía para el pago de los servicios y/o productos prestados.

## ACEPTACIÓN DE ACUERDO DE VOLUNTADES MEDIANTE MENSAJES DE DATOS, FIRMA ELECTRÓNICA, VALIDEZ Y OPONIBILIDAD DE LA INFORMACIÓN

Validez con la aceptación de los Términos y Condiciones, el Usuario expresamente manifiesta su consentimiento y autorización para que en la oferta y provisión de cualquier aspecto a contratar o contratado con la Compañía se utilicen mensajes de datos, SMS, métodos electrónicos de verificación de identidad, firma electrónica, entre otros, otorgándoles la validez, efectos jurídicos y probatorios que para el efecto prevé la Ley 527 de 1999, el Decreto 2364 de 2012, así como las demás normas que los modifiquen, adicionen o sustituyan.

El Usuario también acepta recibir comunicaciones y notificaciones electrónicas por parte de la Compañía. Estas comunicaciones electrónicas pueden incluir avisos sobre tarifas y cargos aplicables, información transaccional y otra información relacionada con cualquier aspecto contratado o a contratar con la Compañía. El Usuario acepta que cualquier aviso, acuerdo, divulgación u otra comunicación enviada electrónicamente cumplirá con los requisitos y efectos legales de comunicación y notificación.

## PROPIEDAD INTELECTUAL

Todo los contenidos, elementos e información que se encuentra en la Aplicación, incluyendo textos, formatos, bases de datos, imágenes, música, marcas, logotipos, enseñas, nombres comerciales, sonidos, gráficos, videos, animación y demás materiales, son de propiedad de la Compañía, sus filiales o subsidiarias y controlantes y, en algunos casos, de aquellos terceros contratistas, licenciantes o cedentes, según corresponda. Igualmente, el uso en la Aplicación de algunos contenidos de propiedad de terceros se encuentra expresamente autorizado por la ley o por dichos terceros. Todos los contenidos están protegidos por las leyes de propiedad intelectual, derechos de autor, marcas registradas, propiedad industrial, y en general por las disposiciones normativas nacionales e internacionales aplicables.

Al aceptar los Términos y Condiciones, el Usuario reconoce expresa e irrevocablemente que, sin excepción alguna, incluyendo pero sin limitarse a todo el material y contenido publicitario y de diseño, comercial, informático, software, hardware, multimedia, audiovisual, textos, formatos, información, bases de datos, imágenes, música, marcas, nombres, logotipos, enseñas, sonidos, gráficos, videos, animación así como cualquier otro contenido puesto a su disposición y sobre el cual tenga o pueda tener acceso y/o se encuentre disponible en la Aplicación son y serán de propiedad de la Compañía, y en algunos casos, de terceros que han autorizado su uso o explotación.

En consecuencia, el Usuario tiene expresamente prohibido copiar, reproducir, modificar, publicar, editar, comercializar, distribuir, comunicar o transferir a cualquier título el contenido de la Aplicación, o permitir que terceros lo hagan a su nombre o representación. El Usuario tampoco podrá crear obras derivadas del contenido de la Aplicación, bien sea de manera total o parcial, por medios físicos, tecnológicos o cualquier otro, sin el permiso previo y por escrito de la Compañía.

El Usuario acepta expresa e irrevocablemente que cede y autoriza a la Compañía sin contraprestación alguna, los derechos y licencias para el uso ilimitado de las imágenes, textos y en general todo el contenido que comparta a través de la Aplicación, permitiendo incluso su edición, reproducción y en general cualquier uso publicitario que se pueda derivar de estos o cualquier acto necesario para prestar los servicios que tenga o quiera contratar con la Compañía.


En ningún caso, estos Términos y Condiciones o el acceso o uso de la Aplicación implicarán la concesión o negación de cualquier licencia, concesión o derecho de uso sobre cualquiera de las marcas, nombres, logotipos, diseños o de cualquier contenido protegido por el derecho de propiedad intelectual de la Compañía o de cualquier tercero, según corresponda.

El uso sin autorización de cualquier tipo de contenido de la Aplicación que viole los derechos de propiedad y propiedad intelectual de la Compañía o de terceros, constituye una violación a los Términos y Condiciones, y podrá implicar el inicio de las acciones legales correspondientes por lo que la Compañía o por los titulares de los derechos violados.

## ACTUALIZACIONES DE LA APLICACIÓN

El Usuario acepta expresa e irrevocablemente con la aceptación de los Términos y Condiciones que la Compañía podrá suspender de forma ocasional y/o actualizar la Aplicación en cualquier momento, sin que esto constituya una falla en el servicio. Para esto, solicitará al Usuario la actualización de su Aplicación con el fin de continuar con la prestación del servicio y el uso adecuado de la misma. Como consecuencia del proceso de actualización pueden existir funciones, configuraciones, parámetros o información que puede llegar a ser eliminada de forma temporal o permanente.

## ACTUALIZACIÓN Y/O MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES

La Compañía podrá realizar cambios en el contenido y los productos y/o servicios ofrecidos en la Aplicación, así como actualizar o modificar los Términos y Condiciones de la Aplicación en cualquier momento, los cuales entrarán a regir y por tanto, serán vinculantes para el Usuario, a partir de su publicación o actualización  en App Store (IOS) o Google Play (Android) o cuando se difundan por mensaje de datos o cualquier medio electrónico, bastando para ello cualquiera de las anteriores opciones. Si el Usuario continúa con el uso de la Aplicación se entenderá como señal expresa de aceptación a la actualización o modificación respectiva.

A partir de la fecha de publicación de la actualización o modificación de los Términos y Condiciones, todas las operaciones que se celebren entre la Compañía y el Usuario se regirán por la versión del documento modificada.

En todo caso, el Usuario tiene derecho a no aceptar las actualizaciones o modificaciones que la Compañía realice a los Términos y Condiciones, caso en el cual deberá evitar el uso y eliminar el registro de la Aplicación, así como desinstalarla de su dispositivo.

## RESPONSABILIDAD LIMITADA

La Compañía no será responsable por la imposibilidad de, por problemas en, perjuicios derivados de o la utilización de la Aplicación o de alguno de los servicios, contenidos, páginas o enlaces que la conforman, incluyendo pero sin limitarse a eventos tales como:

1) problemas en el servidor o en la conexión, interrupciones en su comunicación, problemas técnicos, bloqueos, interrupción o restricción de acceso a esta o en caso que la Compañía decida, discrecionalmente, desactivar o retirar la Aplicación de la App Store (IOS) o Google Play (Android)

2) Inconvenientes de cualquier tipo frente a la adquisición o pago de bienes o servicios a través de la Aplicación

3) Acceso no autorizado a la cuenta o perfil del Usuario, así como modificación de los datos, planes y condiciones del Usuario sean o no realizados por éste

4) Fuerza mayor o caso fortuito

5) Por el hurto, daño o pérdida de su dispositivo móvil y/o cuenta o contraseña que implique el acceso de terceros autorizados o no autorizados a la Aplicación

6) Por los perjuicios, lucro cesante, daño emergente, económico, moral, y en general por los retrasos, no procesamiento de información o suspensión del servicio del operador de internet o daños en los dispositivos móviles.

El Usuario renuncia a toda acción u otro tipo de reclamo de cualquier naturaleza en contra de la Compañía con respecto a la Aplicación; sin embargo, el recurso idóneo para el Usuario ante tal reclamo, acción u otros de cualquier naturaleza, será poner fin al uso de la Aplicación.

## INTEGRACIÓN Y ENLACE CON OTRAS PÁGINAS O APLICACIONES

Los links o enlaces que se muestren o a los cuales se pueda redirigir el Usuario por el uso de la Aplicación, no están bajo el control de la Compañía por pertenecer a terceros sobre los cuales ésta última no ostenta control de su naturaleza y contenido, razón por la cual la Compañía no acepta, ni asume ninguna responsabilidad por pérdida directa, indirecta o consecuencial por el funcionamiento de esos links, accesos, sitios web, aplicaciones o por el uso de un sitio de un tercero. El Usuario está en la obligación de conocer y verificar los términos y condiciones, políticas de privacidad y confiabilidad de los sitios o terceros a los que la Aplicación le enlace o redireccione.

## PERMISOS DE LA APLICACIÓN

La Aplicación solicitará permisos para habilitar diferentes funciones, las cuales están diseñadas para mejorar la experiencia e interacción con la información de productos o servicios. El Usuario está en libertad de aceptar o no el permiso a estas funciones entre las cuales se encuentran: a) acceso a cámara, fotos/datos multimedia/archivos, tomar fotografías y grabar audio y videos, b) identidad, c) teléfono, d) almacenamiento, e) ID de dispositivo y datos de llamadas, f) recibir datos desde Internet.

La Aplicación puede hacer uso de ciertas funciones y servicios proporcionados por terceros que permiten habilitar canales de comunicación con clientes y almacenamiento de datos personales como parte de la prestación de los servicios dentro de la Aplicación, por lo que el uso de datos está sujeto a los términos de uso vigentes de ese tercero.

## EXONERACIÓN Y GARANTÍAS

El uso de la Aplicación es y será a completo riesgo y responsabilidad del Usuario, quien acepta y comprende que el material contenido en la Aplicación, incluyendo sin limitación, textos, reportes, gráficos y vínculos (links de hipertexto), son suministrados según el leal saber y entender de la Compañía; sin embargo, no es posible descartar errores de contenido o del material, razón por la cual la Compañía no asume responsabilidad por la veracidad, exactitud, oportunidad y actualidad de la información publicada ni por las omisiones, errores o discrepancias que se encuentre en la información publicada, lo cual es aceptado por parte del Usuario.

Respecto a la información publicada en la Aplicación, la Compañía no confiere garantías de ningún tipo, ya sea expresas o implícitas, incluyendo, más no limitado a, garantías de comerciabilidad, adecuación para un propósito particular, distintas a lo indicado en el respectivo contenido.

La información respecto a los productos y servicios de la Compañía incluida en la Aplicación es con fines informativos y no constituye oferta de venta a los usuarios de la Aplicación. La Compañía se reserva el derecho de corregir cualquier error, omisión o inexactitud, cambiar o actualizar la información contenida en la Aplicación en cualquier momento.

## USO Y MANEJO DE LOS DATOS

Con la descarga de la Aplicación el Usuario acepta y autoriza a la Compañía a recolectar, almacenar, utilizar, circular, suprimir, transmitir, transferir y en general, a realizar cualquier otro tratamiento de los datos personales suministrados por el Usuario al momento de hacer uso de la Aplicación.

La Compañía como responsable de los datos personales, comunica a los titulares de estos, que las políticas de tratamiento de la información recolectada pueden ser consultadas a través del enlace Política de Tratamiento de datos personales disponible en el portal web [www.enerbit.co].

Como parte de los servicios ofrecidos en la Aplicación, el Usuario acepta que la Compañía acceda y recopile datos relacionados con su consumo de energía eléctrica, historial de uso y otros datos similares, a través de los equipos de medición inteligente y en general de los dispositivos conectados en su hogar o empresa.

Teniendo en cuenta que en el uso de la Aplicación existe la posibilidad de transmitir de forma electrónica algún tipo de información mediante el uso de redes de proveedores de servicio inalámbrico, la Compañía no es ni será responsable por errores, fallas o cualquier circunstancia relacionada con la seguridad o privacidad de las transmisiones inalámbricas de datos, pues es una obligación del Usuario usar sistemas inalámbricos confiables.

Cualquier reclamación relacionados con el uso y tratamiento de datos personales deberán realizarse a través de los canales de comunicación señalados en la Política de Tratamiento de datos personales de la Compañía disponible en el portal web [www.enerbit.co].

## CONSULTAS Y COMENTARIOS

Cualquier pregunta o comentario relacionada con los Términos y Condiciones y con el uso de marcas, enseñas, logos, cualquier otro signo distintivo y en general de los elementos de propiedad industrial y/o intelectual y de los contenidos de la Aplicación, podrá ser hecha a la Compañía a través de www.enerbit.co o en la sección PQR que se encuentra en la Aplicación.

[www.enerbit.co]: https://www.enerbit.co> "sitio web enerbit"`;

export const tycWEB = `# 1. Aceptación de Términos y Condiciones de Uso

Al acceder, navegar o usar este sitio de Internet (el “Sitio”), de propiedad de enerBit S.A.S. E.S.P. (la “Compañía” o “enerBit”) el usuario admite haber leído y entendido los presentes términos y condiciones de uso (los “Términos y Condiciones de Uso”) y está de acuerdo en sujetarse a los mismos y cumplir con todas las leyes y reglamentos aplicables que hagan parte de la legislación colombiana. Además, cuando el usuario utilice cualquier servicio suministrado en este Sitio estará sujeto a las reglas, guías, políticas, términos y condiciones aplicables a dicho servicio.

En el evento que el usuario no esté de acuerdo con estos términos y condiciones de uso, comedidamente le solicitamos el favor de abstenerse inmediatamente de usar este Sitio. Este Sitio es controlado y operado por enerBit desde sus oficinas ubicadas en Colombia. La empresa no es responsable de que el material en este Sitio sea apropiado o esté disponible para su uso en otros lugares, estando prohibido su acceso desde territorios donde su contenido sea ilegal. Aquellos que decidan acceder a este Sitio desde otros lugares lo harán bajo su propia iniciativa, y es su responsabilidad el sujetarse a las leyes locales que sean aplicables. Cualquier reclamo en relación con este Sitio y el material en él contenido, está regulado por las leyes de Colombia. 

Estos Términos y Condiciones de Uso están sujetos a cambios sin previo aviso en cualquier momento, bajo la sola voluntad de enerBit. A partir de la fecha de publicación de la modificación de los mismos en este Sitio, todas las operaciones que se celebren entre la Compañía y el usuario se regirán por el documento modificado.

# 2. Independencia de disposiciones

En el evento que una o más de las disposiciones contenidas en estos Términos y Condiciones de Uso sean consideradas nulas, ilegales o ineficaces en cualquier aspecto, la validez, legalidad, exigibilidad o eficacia del resto de las disposiciones aquí contenidas no se verán afectadas o anuladas por dicha circunstancia.

# 3. www.enerbit.co

www.enerbit.co es un sitio web dedicado a la utilización de diversos servicios y contenidos, tales como la descripción de la compañía, productos, servicios, información financiera y noticias. www.enerbit.co es un nombre de dominio de propiedad de enerBit, sociedad comercial constituida bajo las leyes colombianas. 

# 4. Derechos de autor

Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y de diseño, así como todos los contenidos, textos y bases de datos puestos a su disposición en este Sitio (los “Contenidos”) son de propiedad exclusiva de enerBit, sus filiales o subsidiarias y controlantes, o en algunos casos, de terceros que han autorizado a enerBit su uso en el Sitio. Igualmente, el uso en www.enerbit.co de algunos contenidos de propiedad de terceros se encuentra expresamente autorizado por la ley o por dichos terceros. Todos los Contenidos en www.enerbit.co están protegidos por las leyes de propiedad intelectual, derechos de autor, marcas registradas, propiedad industrial, y en general por las disposiciones normativas nacionales e internacionales aplicables.

Exceptuando lo expresamente estipulado en estos Términos y Condiciones de Uso queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de este Sitio, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y escrito de enerBit o del titular de los derechos de autor respecto de dichos contenidos.

En ningún caso estos Términos y Condiciones de Uso confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los Contenidos constituirá una violación a los presentes Términos y Condiciones de Uso y a las normas vigentes sobre derechos de autor y a cualquier otra que sea aplicable. EnerBit otorga al usuario una licencia y derecho personal, intransferible y no exclusivo para desplegar www.enerbit.co en la pantalla de un computador, ordenador o dispositivo bajo su control, para utilizar el código objeto (no el código fuente) del software de www.enerbit.co.

La licencia previamente mencionada no se extiende a la propiedad intelectual de enerBit o de terceros, y termina automáticamente ante el incumplimiento del usuario de cualquiera de las previsiones de estos Términos y Condiciones de Uso. La licencia otorgada por enerBit se realiza teniendo en cuenta que el usuario no podrá modificar, crear, distribuir, reproducir o exhibir un trabajo derivado de ello, invierta el proceso, invierta el montaje o de algún modo intente descubrir algún código de acceso, vender, ceder, sublicenciar, prestar garantía o transferir cualquier derecho en el software de www.enerbit.co. El usuario se obliga por los presentes Términos y Condiciones de Uso a no modificar el software del Sitio.

# 5. Propiedad industrial

Todas las marcas, enseñas, logos, nombres y cualquier otro signo distintivo, así como los modelos de utilidad o diseños industriales y demás elementos de propiedad industrial o intelectual insertados, usados o desplegados en este Sitio son Propiedad exclusiva de enerBit y en algunos casos son de propiedad de terceros que han autorizado expresamente a enerBit para su uso en el Sitio. Igualmente, en algunos casos el uso de propiedad industrial de terceros ha sido autorizado por la ley de manera general, y en consecuencia su uso no requiere de autorización previa y expresa de su titular. Nada en www.enerbit.co podrá ser interpretado como concesión u otorgamiento a cualquier título de autorizaciones, licencias o cualquier otro derecho para usar o disponer de cualquier forma de la propiedad industrial, sin el permiso previo y escrito de enerBit o del titular de los derechos de la misma. Cualquier uso no autorizado constituirá una violación a los presentes Términos y Condiciones de Uso y a las normas vigentes nacionales e internacionales sobre propiedad industrial, acarreándole al usuario las sanciones y consecuencias que le sean impuestas por enerBit o la Ley.

# 6. Linking, deeplinking y framing

EnerBit prohíbe el uso de los nombres, logotipos, marcas y cualquier otros signos distintivos de su propiedad como hipervínculos (los "links”) direccionados a sitios en Internet cuyo URL sea diferente de http://www.enerbit.co, a menos que el establecimiento de un enlace de tal naturaleza sea aprobado por enerBit previamente y por escrito, en cuyo caso deberá atenerse a los criterios y manuales de diseño y publicidad establecidos por enerBit. EnerBit se reserva el derecho de solicitar el retiro de enlaces que hayan sido establecidos en sitios web sin su expresa y previa autorización. El establecimiento en páginas no controladas por enerBit de enlaces a subdirectorios dentro del URL (“Deeplinking”) queda prohibido. El despliegue, uso, exhibición, copia o cualquier otra forma de reproducción del Sitio www.enerbit.co o de cualquiera de sus subdirectorios y páginas, en sitios no controlados por enerBit (“Framing”) queda expresamente prohibido. La inobservancia de estas prohibiciones será una violación a los derechos de propiedad intelectual sobre los Contenidos y a los derechos sobre la propiedad industrial.

Cada usuario está en la obligación de conocer y verificar los términos y condiciones, políticas de privacidad y confiabilidad de los sitios o terceros a los que www.enerbit.co le enlace o redireccione.

# 7. Consultas

Cualquier pregunta relacionada con el uso de marcas, enseñas, logos, cualquier otro signo distintivo y en general de cualquier elemento de propiedad industrial del Sitio, así como las relacionadas con el uso de los Contenidos o con el establecimiento de Links hacia el URL http://www.enerbit.co podrá ser hecha a enerBit en Colombia al WhatsApp (+57) 3112706213 o directamente a través de www.enerbit.co. Su uso, en contravención de lo aquí dispuesto, dará lugar a las acciones civiles y penales correspondientes.

# 8. Exoneración y garantías

De conformidad con la legislación colombiana, el material contenido en este Sitio, incluyendo sin limitación, textos, reportes, gráficos y vínculos (links), son suministrados según nuestro leal saber y entender, sin embargo, no es posible descartar errores de contenido o del material, razón por la cual enerBit no asume responsabilidad por la veracidad, exactitud, oportunidad y actualidad de la información publicada ni por las omisiones, errores o discrepancias que se encuentre en la información publicada.

Respecto a la información publicada en el Sitio, enerBit no confiere garantías de ningún tipo, ya sea expresas o implícitas, incluyendo, más no limitado a, garantías de comerciabilidad, adecuación para un propósito particular, etc., distintas a lo indicado en el respectivo contenido. EnerBit no se hace responsable por el uso que el usuario haga de los contenidos incluidos en el Sitio, ni de las decisiones que tome respecto de éstos.

La información respecto a los productos y servicios de enerBit incluida en el Sitio es con fines informativos y no constituye oferta de venta a los usuarios del Sitio. EnerBit se reserva el derecho de corregir cualquier error, omisión o inexactitud, cambiar o actualizar la información contenida en el Sitio en cualquier momento y sin previo aviso.

# 9. Responsabilidad limitada

EnerBit no será responsable por la imposibilidad de, por problemas en, perjuicios derivados de o la utilización del Sitio o de alguno de los servicios, contenidos, páginas o enlaces que la conforman, incluyendo pero sin limitarse a eventos tales como: **1)** problemas en el servidor o en la conexión, interrupciones en su comunicación, problemas técnicos, bloqueos, interrupción o restricción de acceso a esta o en caso que enerBit decida, discrecionalmente, desactivar o retirar el Sitio; **2)** Inconvenientes de cualquier tipo frente a la adquisición o pago de bienes o servicios a través del Sitio; **3)** Acceso no autorizado a la cuenta o perfil del usuario, así como modificación de los datos, planes y condiciones del usuario sean o no realizados por éste; **4)** Fuerza mayor o caso fortuito; **5)** Por el hurto, daño o pérdida de su equipo y/o cuenta o contraseña que implique el acceso de terceros autorizados o no autorizados al Sitio; **6)** Por los perjuicios, lucro cesante, daño emergente, económico, moral, y en general por los retrasos, no procesamiento de información o suspensión del servicio del operador de internet o daños en el equipo que utilice para acceder al Sitio. 

El usuario renuncia a toda acción u otro tipo de reclamo de cualquier naturaleza en contra de enerBit con respecto al Sitio; sin embargo, el recurso idóneo para el Usuario ante tal reclamo, acción u otros de cualquier naturaleza, será poner fin al uso del Sitio. 

# 10. Visitas al Sitio

La prestación del servicio del Sitio tiene carácter gratuito para los usuarios. Las visitas que realice a sus sitios web a través de los vínculos de este Sitio, serán por la exclusiva cuenta y riesgo de los usuarios. El usuario se obliga a usar los contenidos de una manera diligente, correcta, lícita y, en especial, se compromete a no realizar las conductas descritas a continuación:

*(i)* Utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas, o al orden público.

*(ii)* Reproducir, copiar, representar, publicar, utilizar, distribuir, transformar o modificar los Contenidos, o de cualquier otra manera realizar actos de disposición o explotación sobre los Contenidos, por cualquier procedimiento o sobre cualquier soporte, de manera total o parcial o permitir el acceso del público a través de cualquier modalidad de comunicación pública, distintos de los que, según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado en las páginas web donde se encuentren los Contenidos o, en general, de los que se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo de daño o inutilización del Sitio, de los servicios o de los Contenidos.

*(iii)* Suprimir, eludir o manipular el “copyright” y demás datos de los derechos de propiedad intelectual de enerBit o de sus titulares, incorporados a los Contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualquier mecanismo de información que pudieren tener los Contenidos.

*(iv)* Emplear los Contenidos y, en particular, la información de cualquier clase obtenida a través del Sitio o de los servicios para distribuir, transmitir, remitir, modificar, reusar o reportar la publicidad o los Contenidos del Sitio con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como a abstenerse de comercializar o divulgar de cualquier modo dicha información. En consecuencia, el usuario podrá descargar el material que aquí se publica, en soporte papel o informático, sólo para fines personales y con la citación de la fuente.

*(v)* Utilizar el Sitio, el Contenido y los servicios con fines o efectos ilícitos, contrarios a lo establecido en estos Términos y Condiciones de Uso, lesivos de los derechos de enerBit e intereses de terceros o de enerBit, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el Sitio, los Contenidos o los Servicios o impedir la normal utilización o disfrute del Sitio, de los Contenidos o de los servicios por parte de los usuarios.

# 11. Protección de información personal

Mediante el ingreso al Sitio y su registro, el usuario autoriza a enerBit a usar y tratar los datos personales por él ingresados al Sitio, para los fines establecidos en el Sitio y en la Política de Protección de Datos Personales de enerBit, en especial, con el fin de permitirle crear una base de datos de los usuarios registrados, enviar información comercial, publicitaria, técnica de enerBit y/o sus productos y/o sus servicios a los usuarios, construir estrategias comerciales con los usuarios registrados y la información por ellos reportada, uso de la información en procesos comerciales operativos de enerBit, la programación y envío de obsequios, postales y cualquier material publicitario así como para cualquier otro fin comercial que permita desarrollar relación comercial con los usuarios para la venta de productos de enerBit.  

enerBit tomará medidas razonables lógicas, administrativas y físicas para proteger del acceso no autorizado de terceros a la información personal obtenida a través del Sitio, así como para evitar la adulteración, pérdida, consulta o uso no autorizado o fraudulento por parte de terceros de la información personal. Sin embargo, enerBit no se hace responsable por el ingreso no autorizado de terceros al Sitio y el acceso a la información personal de los usuarios y visitantes, vulnerando las medidas a través de internet. En estos eventos enerBit gestionará el incidente de seguridad de conformidad con los lineamientos establecidos por la ley aplicable.

El usuario autoriza a enerBit a dar a conocer su información personal a las autoridades competentes en cumplimiento de la ley o de orden administrativa, y a conservar dicha información para las finalidades acá establecidas, pudiendo conservarla hasta que la finalidad para la cual hayan sido obtenidos tales datos se haya cumplido. 

El usuario podrá ejercer derechos reconocidos por el artículo 8 de la Ley 1581 de 2012, respecto de los datos personales que haya ingresado a través del Sitio. La solicitud para ejercer cualquiera de los derechos deberá hacerse por escrito sea este físico o por correo electrónico. Dicha solicitud podrá dirigirse a la dirección principal en Medellín: Carrera 43 A No. 1 A Sur 143, Edificio Centro Santillana, Torre Sur, Piso 6 o al correo electrónico habilitado por enerBit: protecciondatospersonales@celsia.com o puede comunicarse al WhatsApp (+57) 3112706213.

# 12. Revisión de los Términos y Condiciones de Uso

EnerBit puede en cualquier momento revisar estos Términos y Condiciones de Uso aquí contenidos, por medio de la actualización de este anuncio. Al usar www.enerbit.co, el usuario conviene en darse por obligado por cualquiera de tales revisiones, las cuales estarán vigentes a partir del momento en que las mismas sean accesibles por los usuarios, debiendo entonces visitar periódicamente esta página para determinar los Términos y Condiciones de Uso vigentes en ese momento a los cuales el usuario estará obligado en el evento que decida utilizar este Sitio.
`;

export const electronicNotify = `# AUTORIZACIÓN PARA RECIBIR LA FACTURA Y SER NOTIFICADO POR MEDIOS ELECTRÓNICOS 

## Autorizo a **ENERBIT S.A.S. E.S.P.**:

1. Que me notifique al correo electrónico registrado en la Página Web  [www.enerbit.co](https://www.enerbit.co/) o en la APP enerBit, las respuestas a las PQR que presente.

2. Que a través de la APP enerBit me envíe la factura por los servicios contratados.`;

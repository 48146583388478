<template>
  <div>
    <section class="py-14 youKnow" id="youKnowSection">
      <div class="container text-center mx-auto px-5">
        <h2 class="text-4xl font-bold text-center">
          ¡El comercializador o empresa lo eliges tú!
        </h2>
        <div class="flex my-8 md:my-12 ...">
          <div class="flex-none w-5 md:w-44 ..."></div>
          <div class="grow ...">
            <img
              src="@/assets/sdm/assets/img/infografía-enerbit-comercializador.png"
              class="w-full img-info"
              alt=""
            />
            <img
              src="@/assets/sdm/assets/img/gif-enerbit-sabias-que.gif"
              class="w-full img-info-movil"
              alt=""
            />
          </div>
          <div class="flex-none w-5 md:w-44 ..."></div>
        </div>

        <a class="btn-primary" href="#form" v-smooth-scroll>Elijo enerBit</a>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "YouknowSection",
};
</script>
<style>
.youKnow {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.img-info-movil {
  display: none;
}

@media (max-width: 768px) {
  .img-info {
    display: none;
  }

  .img-info-movil {
    display: block;
  }
}
</style>

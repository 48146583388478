<template>
  <div id="app">
    
    <px-header :links="links" :textButton="textButton"></px-header>
    <router-view class="max-w-full main" />
    <px-footer />
  </div>
</template>

<script>
import PxHeader from "@/components/PxHeader.vue";
import PxFooter from "@/components/PxFooter.vue";

export default {
  name: "App",
  components: { PxHeader, PxFooter },
  data() {
    return {
      links: [
        {
          title: "Inicio",
          to: { name: "start" },
        },
        {
          title: "Empresas",
          to: { name: "business" },
        },
        {
          title: "Hogares",
          to: { name: "homes" },
        },
        {
          title: "PQR",
          to: { name: "pqr" },
        },
      ],
      textButton: "Mi proceso",
    };
  },
};
</script>

<style scoped>
body {
  margin: 0 !important;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0%;
  min-height: 100vh;
  position: relative;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 110;
}
</style>

<template>
  <div class="mt-16 ml-10">
    <nav class="text-white rounded-md w-full mb-4">
      <ol class="list-reset flex">
        <li>
          <router-link to="/legal" class="text-blue-600 hover:text-blue-700"
            >Legal</router-link
          >
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold"
            >></span
          >
        </li>
        <li>
          <a href="#" class="text-secondary-orange-300 font-bold"
            >Derechos, obligaciones y mecanismos de protección
          </a>
        </li>
      </ol>
    </nav>
  </div>
  <div class="mt-10 mb-10 ml-10 container-button">
    <button
      class="bg-secondary-orange-500 rounded-md text-white mb-6 px-4 py-2 mt-5 font-bold"
    >
      Guía derechos de los clientes
      <i class="mdi mdi-eye-outline ml-2"></i>
    </button>
    <br />
    <button
      class="bg-secondary-orange-500 rounded-md text-white mb-6 px-4 py-2 font-bold"
      @click="openModal"
    >
      Carta de trato digno al cliente
      <i class="mdi mdi-eye-outline ml-2"></i>
    </button>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="markClient" />
  </vue-final-modal>
</template>

<script>
import PXModalMark from '@/components/PXModalMark.vue';
import { tratoCliente } from '@/helpers/markDownString.js';
export default {
  components: {
    PXModalMark,
  },

  data() {
    return {
      isShowModal: false,
      markClient: tratoCliente,
    };
  },

  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },
  },
  created() {
    this.$store.commit('showLegalButtons');
  },
};
</script>

<style scoped>
button:focus {
  outline: none;
}

.container-button {
  min-height: 30vh;
}
</style>

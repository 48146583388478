/* eslint-disable no-unused-vars */
import MainLayout from "@/layout/MainLayout";
import Business from "@/views/Business.vue";
import CREG from "@/views/CREG.vue";
import Calls from "@/views/Calls.vue";
import CostMonth from "@/views/CostMonth.vue";
import CostPeriods from "@/views/CostPeriods.vue";
import CostYear from "@/views/CostYear.vue";
import Costs from "@/views/Costs.vue";
import DataTreatment from "@/views/DataTreatment.vue";
import Error from "@/views/Error.vue";
import Homes from "@/views/Homes.vue";
import Legal from "@/views/Legal2.vue";
import MarketerChange from "@/views/MarketerChange.vue";
import MyProcess from "@/views/MyProcess.vue";
import PQRS from "@/views/PQRS.vue";
import RecoverPassword from "@/views/RecoverPassword.vue";
import Rights from "@/views/Rights.vue";
import Start from "@/views/Start.vue";
import TyCPage from "@/views/TyCPage.vue";
import SDM from "@/views/sdm/SDM.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/recuperar/",
    name: "recuperar-contraseña",
    component: RecoverPassword,
  },
  // {
  //   path: '/',
  //   name: 'coming-soon',
  //   component: ComingSoon,
  // },
  {
    path: "/sdm/",
    name: "sdm",
    component: SDM,
  },
  {
    path: "/inicio",
    name: "home",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "start",
        component: Start,
      },

      {
        path: "/empresas",
        name: "business",
        component: Business,
      },
      {
        path: "/hogares",
        name: "homes",
        component: Homes,
      },
      {
        path: "/mi-proceso",
        name: "my-process",
        component: MyProcess,
      },
      {
        path: "/tyc",
        name: "tyc",
        component: TyCPage,
      },
      {
        path: "/pqr",
        name: "pqr",
        component: PQRS,
      },
      {
        path: "/legal",
        name: "legal",
        component: Legal,
      },
      {
        path: "/cambio-comercializador",
        name: "marketer-change",
        component: MarketerChange,
      },
      {
        path: "/costos-tarifas",
        name: "costs-fees",
        component: Costs,
      },
      {
        path: "/creg",
        name: "creg",
        component: CREG,
      },
      {
        path: "/convocatorias",
        name: "calls",
        component: Calls,
      },
      {
        path: "/derechos",
        name: "rights",
        component: Rights,
      },
      {
        path: "/costos-tarifas/:year",
        name: "costPeriods",
        component: CostPeriods,
      },
      {
        path: "/costos-tarifas/:year/:month",
        name: "costYear",
        component: CostYear,
      },
      {
        path: "/costos-tarifas/:year/:month/:city",
        name: "costMonth",
        component: CostMonth,
      },
      {
        path: "/politicadetratamientodedatos",
        name: "tratamiento-datos",
        component: DataTreatment,
      },

      // ],
      // },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: Error,
  },
];

const router = createRouter({
  mode: "",
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;

<template>
  <div>
    <div
      class="w-full pt-12 pl-4 lg:pl-20 pr-20 process flex flex-col lg:flex-row relative"
    >
      <div
        class="lg:w-6/12 w-4/5 text-2xl lg:text-4xl font-medium leading-10 text-left float-left z-50"
      >
        <p class="take-control-text mb-6 text-white">
          ¿Cómo va tu proceso para tomar el control?
        </p>
        <div class="lg:float-left">
          <Form @submit="onSubmit">
            <label for="code"> Código de seguimiento </label>
            <Field
              class="rounded-md h-10"
              name="code"
              id="code"
              type="text"
              required
              :rules="validate"
            />
            <br />
            <px-modal-recovery-code />
            <ErrorMessage name="code" class="text-red-500 text-lg" />

            <button class="consult" type="submit">
              <div class="flex flex-row text-white text-lg">
                Consultar
                <img
                  :class="{ hidden: !isSubmitting, block: isSubmitting }"
                  class="w-4 ml-2 loader-img"
                  src="@/assets/img/isoTipo.svg"
                  alt=""
                />
              </div>
            </button>
          </Form>
        </div>
      </div>
      <div
        class="lg:float-right w-full timeline"
        :class="{ 'animate-content': showTimeLine }"
      >
        <px-time-line :class="{ hidden: !showTimeLine }" />
      </div>
    </div>
    <px-messsage-modal />
  </div>
</template>
<script>
import PxModalRecoveryCode from "@/components/PxModalRecoveryCode.vue";
import PxMesssageModal from "../components/PxMesssageModal.vue";
import PxTimeLine from "@/components/PxTimeLine.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import api from "@/api";

export default {
  name: "MyProcess",
  computed: {
    showTimeLine() {
      return this.$store.state.showTimeLine;
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  components: {
    PxModalRecoveryCode,
    PxTimeLine,
    Form,
    Field,
    ErrorMessage,
    PxMesssageModal,
  },
  methods: {
    onSubmit(values) {
      this.isSubmitting = true;
      let { code } = values;
      api
        .leadProcess(code)
        .then((response) => {
          let { result, items, message } = response;
          console.log(result);
          if (result != "InProgress" && items.length > 0) {
            this.$store.dispatch("showTimeLine", items);
          } else if (result == "InProgress") {
            let messageModal = { title: "Hola!", description: message };
            this.$store.dispatch("toggleSuccessModal", messageModal);
          } else {
            let messageModal = {
              title: "Hola!",
              description:
                "No hemos encontrado ningún registro con el código ingresado",
            };
            this.$store.dispatch("toggleSuccessModal", messageModal);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    validate(value) {
      // if the field is empty
      if (!value || !value.length) {
        return "Este campo es requerido";
      }

      // All is good
      return true;
    },
  },
};
</script>
<style scoped>
.process {
  padding-top: 52px;
  padding-left: 79px;
  padding-right: 79px;
}

label {
  font-weight: bold;
  font-size: 18px;
  display: block;
  color: #fff;
}

.timeline {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 675px;
  background: #8b78b0;
  transition: left 0.5s, width 2s;
}

.animate-content {
  left: 50% !important;
  width: 50% !important;
}

.consult {
  border: 0px;
  height: 54px;
  width: 164px;
  border-radius: 5px;
  padding: 10px, 25px, 10px, 25px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  color: white;
  background: #f79009;
  border-radius: 5px;
  margin-bottom: 40px;
}

.loader-img {
  animation: spin 2s linear infinite;
}
</style>

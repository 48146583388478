import {
  baseLeadsUrl,
  basePQRUrl,
  baseZendeskUrl,
  electricityMarketUrl,
} from "@/config/config";
const baseGeopoliticsUrl = "https://back.enerbit.dev/geopolitics/";
const getStates = async () => {
  try {
    // const responseLocale = await fetch(
    //   'https://ipinfo.io?token=94d967614e3584',
    //   {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //     },
    //   }
    // );
    // const { country } = await responseLocale.json();

    const country = "CO";

    const response = await fetch(
      `${baseGeopoliticsUrl}states/?country_code=${country}&page=1&size=200`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const { items } = await response.json();
    return items;
  } catch (error) {
    console.log(error);
  }
};

const getCities = async (stateId) => {
  try {
    const response = await fetch(
      `${baseGeopoliticsUrl}states/${stateId}/cities?size=200`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const { items } = await response.json();
    return items;
  } catch (error) {
    console.log(error);
  }
};

const getCompanies = async (keyword = "") => {
  try {
    const response = await fetch(
      `${electricityMarketUrl}legal-person/?name_like=${keyword}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const { items } = await response.json();
    const collator = new Intl.Collator("en");

    const sortArray = (x, y) => {
      return collator.compare(x.name, y.name);
    };

    items.sort(sortArray);
    return items;
  } catch (error) {
    console.log(error);
  }
};

const createLead = async (leadData) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(leadData),
    };
    const response = await fetch(`${baseLeadsUrl}leads/`, requestOptions);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const leadProcess = async (leadCode) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${baseZendeskUrl}Lead/historic?idLead=${leadCode}`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getLeadProcess = async (leadEmail) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${baseZendeskUrl}Lead/rememberLead?email=${leadEmail}`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getTyC = async () => {
  try {
    const requestOptions = {
      method: "GET",
      mode: "cors",
    };
    const response = await fetch(
      `https://enerbitdevpublic.blob.core.windows.net/terms-and-conditions/t-and-c3.md`,
      requestOptions
    );
    let tyc = response.text().then((content) => {
      return content;
    });

    return tyc;
  } catch (error) {
    console.log(error);
  }
};

const getLeadIntegrator = async () => {
  try {
    const requestOptions = {
      method: "GET",
      mode: "cors",
    };
    const response = await fetch(
      `${baseLeadsUrl}lead-intgrators/?integrator_name=Odoo`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const createPQR = async (data, values) => {
  try {
    const requestOptions = {
      method: "POST",
      body: data,
    };
    console.log(data);
    const response = await fetch(
      `${basePQRUrl}/pqr/?pqr_type=${values["pqr_type"]}&full_name=${values["full_name"]}&legal_id_code=${values["legal_id_code"]}&email=${values["email"]}&phone=${values["phone"]}&how_we_can_help=${values["how_we_can_help"]}&source=Web`,
      requestOptions
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export default {
  getCompanies,
  getStates,
  getCities,
  createLead,
  leadProcess,
  getLeadProcess,
  getTyC,
  getLeadIntegrator,
  createPQR,
};

<template>
  <div
    class="cards m-auto text-center justify-evenly flex flex-col lg:justify-center lg:flex-row lg:pl-12 lg:pr-12 mt-8"
  >
    <div
      v-for="item in items"
      :key="item.title"
      class="mt-6 lg:w-6/12 rounded card w-full lg:mr-8"
      :class="item.bg"
    >
      <div class="pb-8 m-auto text-center">
        <img class="inline-block" :src="item.img" alt="" />
      </div>
      <p class="font-bold text-2xl" :class="item.titleColor">
        {{ item.title }}
      </p>
      <p class="mt-2 text-base">
        {{ item.description }}
      </p>
      <ul
        class="text-left md:text-center lg:text-left ml-3 lg:ml-5 digital-list text-base font-medium leading-6 mb-3 text-white list-disc"
      >
        <li v-for="it in item.childrens" :key="it.title" class="pb-0">
          {{ it.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "PxCards",
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
<style scoped>
.card {
  padding: 20px 1.5% 0px 1.5%;
  height: 405px;
  flex: 0 1 30%;
}

@media (min-width: 1024px) {
  .cards {
    margin-top: 3rem !important;
  }
}
</style>

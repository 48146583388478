<template>
  <div>
    <div class="btn-floating">
      <a href="https://bit.ly/SDM-eB" target="__blank" class="btn-sing">
        <img src="@/assets/sdm/assets/img/sign.svg" alt="" />
        <span>Firmar</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "SdmFloatingButtons",
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

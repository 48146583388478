<template>
  <div>
    <Form @submit="onSubmit" class="text-center pr-16 pl-16">
      <p class="font text-sm leading-5 text-gray-500 mb-8 mt-6">
        Ingresa tu correo electrónico y te enviaremos nuevamente tu código
      </p>
      <div>
        <Field
          id="email"
          name="email"
          class="text-email"
          type="email"
          :rules="validate"
        />
        <ErrorMessage name="email" class="text-red-500 text-lg" />
      </div>
      <div class="text-center">
        <button type="submit" class="send">
          <div class="flex flex-row text-white text-lg">
            enviar
            <img
              :class="{ hidden: !isSubmitting, block: isSubmitting }"
              class="w-4 ml-2 loader-img"
              src="@/assets/img/isoTipo.svg"
              alt=""
            />
          </div>
        </button>
      </div>
    </Form>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import api from "@/api";

export default {
  name: "PxFormRecoveryCode",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    onSubmit(values) {
      this.isSubmitting = true;
      let { email } = values;
      api
        .getLeadProcess(email)
        .then((response) => {
          let { result } = response;
          if (result == "Ok") {
            let messageModal = {
              title: "Mensaje enviado con éxito",
              description:
                "Revisa tu correo electrónico ya debes tener tu código en la bandeja de entrada.",
            };
            this.$store.dispatch("toggleSuccessModal", messageModal);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    validate(value) {
      // if the field is empty
      if (!value || !value.length) {
        return "Este campo es requerido";
      }

      // All is good
      return true;
    },
  },
};
</script>
<style scoped>
.text-email {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 36px;
}

.loader-img {
  animation: spin 2s linear infinite;
}

.send {
  border: 0px;
  height: 36px;
  width: 100%;
  border-radius: 5px;
  padding: 10px, 25px, 10px, 25px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  color: white;
  background: #f79009;
  border-radius: 5px;
  margin-bottom: 40px;
  font-size: 18px;
}
</style>

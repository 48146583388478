<template>
  <div>
    <button @click="buttonClick" class="legal-button text-lg leading-8">
      {{ text }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'PxButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  data() {},
  methods: {
    buttonClick() {
      this.$router.push(this.to);
    },
  },
};
</script>
<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  text-align: start;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

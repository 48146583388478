<template>
  <div>
    <!-- section formulario -->
    <section class="section-form py-14" id="form">
      <div class="container mx-auto px-5">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-5 bg-bitor relative ...">
            <h3 class="text-2xl color-white font-semibold mb-5">
              ¿Todavía quieres saber más?
            </h3>
            <h3 class="text-2xl font-semibold color-white">
              Regálanos tu info y Bitor te contactará por WhatsApp
            </h3>
            <img
              src="@/assets/sdm/assets/img/Bitor.png"
              class="bitor mx-auto"
              alt=""
            />
          </div>
          <div class="col-span-12 md:col-span-7 ...">
            <div class="content-form p-5">
              <form ref="sdmForm" @submit="onSubmit">
                <h4 class="text-lg color-white">Sobre ti</h4>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mb-3">
                  <input
                    type="text"
                    class="input-form"
                    v-model="name"
                    required
                    placeholder="Nombre"
                  />
                  <input
                    type="text"
                    class="input-form"
                    v-model="last_name"
                    required
                    placeholder="Apellido"
                  />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mb-3">
                  <input
                    type="text"
                    class="input-form"
                    v-model="phone"
                    required
                    placeholder="Celular"
                  />
                  <input
                    type="email"
                    class="input-form"
                    v-model="email_from"
                    required
                    placeholder="Correo electrónico"
                  />
                </div>
                <!-- <div class="grid grid-cols-1 gap-5 mb-3">
                  <input type="text" class="input-form" placeholder="Nombre" />
                </div> -->

                <!-- <h4 class="text-lg color-white">Sobre tu energía</h4>
                <div class="grid grid-cols-1 gap-5 mb-3">
                  <div class="content-select">
                    <select required v-model="current_network_operator">
                      <option disabled selected>
                        ¿Cuál es tu empresa actual de energía?
                      </option>
                      <option v-for="company in companies" :key="company.id">
                        {{ company.name }}
                      </option>
                    </select>
                    <i></i>
                  </div>
                </div> -->

                <h4 class="text-lg color-white mb-3">
                  ¿Cuál es tu consumo aproximadamente al mes (kWh)?
                </h4>
                <div class="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-3">
                  <label class="radio-input color-white"
                    >1-100 kWh
                    <input
                      type="radio"
                      class="radio"
                      v-model="initial_average_active_energy_usage"
                      name="radio"
                    />
                    <span class="checkradio"></span>
                  </label>
                  <label class="radio-input color-white"
                    >101-500 kWh
                    <input
                      type="radio"
                      class="radio"
                      v-model="initial_average_active_energy_usage"
                      name="radio"
                    />
                    <span class="checkradio"></span>
                  </label>
                  <label class="radio-input color-white"
                    >501-1000 kWh
                    <input
                      type="radio"
                      class="radio"
                      v-model="initial_average_active_energy_usage"
                      name="radio"
                    />
                    <span class="checkradio"></span>
                  </label>
                  <label class="radio-input color-white"
                    >Más de 1001 kWh
                    <input
                      type="radio"
                      class="radio"
                      v-model="initial_average_active_energy_usage"
                      name="radio"
                    />
                    <span class="checkradio"></span>
                  </label>
                </div>
                <div class="grid grid-cols-1 gap-5 mb-3">
                  <div class="content-select">
                    <select v-model="hear_about_enerbit" required>
                      <option disabled selected>
                        ¿Cómo te enteraste de enerBit?
                      </option>
                      <option
                        v-for="option in optionsFindEnerbit"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <i></i>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-5 mb-3">
                  <label class="check-input color-white">
                    Acepto que he leído los
                    <span @click="showTycWeb()" class="underline"
                      >términos y condiciones</span
                    >
                    <input type="checkbox" class="checkbox" required />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="grid grid-cols-1 gap-5 mb-3">
                  <label class="check-input color-white">
                    Autorizo a enerBit el tratamiento de mi información conforme a su
                    <span @click="showPersonalData()" class="underline"
                      >política de tratamiento de datos personales</span
                    > para fines comerciales, legales y contractuales
                    <input type="checkbox" class="checkbox" required />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <button class="btn-primary">Enviar</button>
              </form>
            </div>

            <div class="bg-bitor-mobil">
              <img src="@/assets/sdm/assets/img/Bitor.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <PxMesssageModal />
    </section>
    <vue-final-modal v-model="isShowModal">
      <PXModalMark :onClose="closeModal" :data="renderMark" />
    </vue-final-modal>
  </div>
</template>
<script>
import { optionsFindOut } from "@/helpers/optionsFormSelect";
import PxMesssageModal from "@/components/PxMesssageModal.vue";
import PXModalMark from "@/components/PXModalMark.vue";
import { tratamientoDatos, tycWEB } from "@/helpers/markDownString.js";

import api from "@/api";

export default {
  name: "FormSection",
  components: { PxMesssageModal, PXModalMark },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
      name: "",
      last_name: "",
      email_from: "",
      phone: "",
      city: "",
      optionsFindEnerbit: optionsFindOut,
      current_network_operator: "",
      initial_average_active_energy_usage: "",
      policy_data_processing: "yes",
      hear_about_enerbit: "",
      integrator_id: this.leadIntegrator,
      department: "",
      companies: "",
      markDownCc: tratamientoDatos,
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      console.log(this.name);
      let data = {
        type_services: "home",
        name: this.name,
        last_name: this.last_name,
        email_from: this.email_from,
        phone: this.phone,
        city: "Cartagena",
        current_network_operator: "Afinia",
        initial_average_active_energy_usage:
          this.initial_average_active_energy_usage,
        policy_data_processing: "yes",
        hear_about_enerbit: "social_networks",
        integrator_id: this.leadIntegrator,
        department: "Bolívar",
      };
      api
        .createLead(data)
        .then((result) => {
          if (result.status == 200) {
            this.$refs.sdmForm.reset();
            let messageModal = {
              title: "Formulario enviado exitosamente",
              description:
                "Tu formulario fue enviado con éxito, pronto nos comunicaremos contigo",
            };
            this.$store.commit("toggleSuccessModal", messageModal);
          } else {
            let messageModal = {
              title: "error al enviar el formulario",
              description: `status code : ${result.status}`,
            };
            this.$store.commit("toggleSuccessModal", messageModal);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getCompanies(name = "") {
      api
        .getCompanies(name)
        .then((result) => {
          console.log(result);
          this.companies = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadIntegrator() {
      api
        .getLeadIntegrator()
        .then((result) => {
          this.leadIntegrator = result.items[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    showPersonalData() {
      this.isShowModal = true;
      this.renderMark = tratamientoDatos;
    },
    showTycWeb() {
      this.isShowModal = true;
      this.renderMark = tycWEB;
    }
  },
  created() {
    this.getCompanies();
    this.getLeadIntegrator();
  },
  
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

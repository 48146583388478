const baseGeopoliticsUrl = `${window.VUE_APP_BACKEND_BASE_URL}geopolitics/`;
const electricityMarketUrl = `${window.VUE_APP_BACKEND_BASE_URL}electricity-market-info/`;
const baseZendeskUrl = `${window.VUE_APP_BACKEND_BASE_URL}ccare/`;
const baseLeadsUrl = `${window.VUE_APP_BACKEND_BASE_URL}leads/`;
const baseUsers = `${window.VUE_APP_BACKEND_BASE_URL}users/`;
const basePQRUrl = `${window.VUE_APP_BACKEND_BASE_URL}pqr/`;
const baseLogosUrl = `${window.VUE_APP_BLOB_BASE_URL}logos/`;

export {
  baseGeopoliticsUrl,
  electricityMarketUrl,
  baseZendeskUrl,
  baseLeadsUrl,
  baseUsers,
  basePQRUrl,
  baseLogosUrl
};

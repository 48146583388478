<template>
  <div>
    <header class="bg-white" id="home">
      <div class="grid grid-cols-1">
        <div class="header-banner">
          <div class="container mx-auto relative h-full">
            <div class="content p-7 text-center">
              <h2
                class="text-4xl md:text-6xl font-semibold mb-5 drop-shadow-md"
              >
                ¿Sabías que puedes elegir tu empresa de energía?
              </h2>
              <a
                class="btn-primary btn-secondary text-center m-2"
                href="#celsiaBackrest"
                v-smooth-scroll
                >Quiero saber más</a
              >
              <a
                class="btn-primary text-center m-2"
                href="https://bit.ly/SDM-eB"
                target="__blank"
                >¡Estoy listo para firmar!</a
              >
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "SdmHeader",
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

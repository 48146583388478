<template>
  <div class="modal-container">
    <div class="mark-down">
      <i
        class="mdi mdi-close float-right text-xl cursor-pointer"
        @click="onClose"
      ></i>
      <Markdown :source="data" class="prose md:prose-lg lg:prose-lg" />
    </div>
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';
export default {
  props: ['onClose', 'data'],

  components: {
    Markdown,
  },
};
</script>
<style scoped>
.modal-container {
  position: absolute;

  /* margin: auto; */
  overflow: scroll;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal-container .mark-down {
  background: #fff;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
}
</style>

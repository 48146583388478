<template>
  <div class="mt-16 ml-10">
    <div>
      <nav class="text-white rounded-md w-full mb-4">
        <ol class="list-reset flex">
          <li>
            <router-link class="text-blue-600 hover:text-blue-700" :to="{ path: '/legal' }">Legal</router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold">></span>
          </li>
          <li>
            <router-link to="/periodos" class="text-secondary-orange-300 font-bold">Costos y tarifas </router-link>
          </li>
        </ol>
      </nav>

      <px-legal-button v-for="data in buttonPeriods" :key="data.text" :text="data.text" :to="data.to" />
    </div>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="markCcu" />
  </vue-final-modal>
</template>

<script>
import PxLegalButton from "../components/PxLegalButton.vue";
import PXModalMark from "@/components/PXModalMark.vue";
import { ccu } from "@/helpers/markDownString.js";
import cost from "../assets/cost.json";

export default {
  components: { PxLegalButton, PXModalMark },
  data() {
    return {
      isShowModal: false,
      markCcu: ccu,
      buttonPeriods: cost.periods,
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

import { createStore } from "vuex";
export default createStore({
  state: {
    showSuccessModal: false,
    showFormModal: false,
    showInvoiceModal: false,
    showTyCmodal: false,
    personalData: false,
    messageModal: {},
    showTimeLine: false,
    timeLineData: [],
    showButtons: false,
    showLegalContent: false,
    showPqrModal: false,
    pqrType: ""
  },
  mutations: {
    toggleSuccessModal(state, message = {}) {
      state.showSuccessModal = !state.showSuccessModal;
      state.messageModal = message;
    },
    toggleFormModal(state) {
      state.showFormModal = !state.showFormModal;
    },
    toggleShowTimeLine(state, data = []) {
      state.showTimeLine = !state.toggleShowTimeLine;
      state.timeLineData = data;
    },
    toggleInvoiceModal(state) {
      state.showInvoiceModal = !state.showInvoiceModal;
    },
    toggleTyCmodal(state) {
      state.showTyCmodal = !state.showTyCmodal;
    },
    togglePersonalData(state) {
      state.togglePersonalData = !state.togglePersonalData;
    },
    showLegalButtons(state) {
      console.log("change show legal button", state.showButtons);
      state.showButtons = !state.showButtons;
    },
    showLegalContent(state) {
      state.showLegalContent = !state.showLegalContent;
    },
    showPqrModal(state) {
      state.showPqrModal = !state.showPqrModal;
    },
    changePqrType(state, pqrType) {
      state.pqrType = pqrType;
    }
    
  },
  getters: {
    showSuccessModal: (state) => {
      return state.showSuccessModal;
    },
    showFormModal: (state) => {
      return state.showFormModal;
    },
    showTimeLine: (state) => {
      return state.showTimeLine;
    },
    getPqrTypeState: (state) => {
      return state.pqrType;
    },
  },
  actions: {
    showTimeLine (context, payload) {
      context.commit('toggleShowTimeLine', payload)
    },
    toggleSuccessModal (context, payload) {
      context.commit('toggleSuccessModal', payload)
    },
    toggleTyCmodal (context, payload) {
      context.commit('toggleTyCmodal', payload)
    },
    togglePersonalData (context, payload) {
      context.commit('togglePersonalData', payload)
    }
  }
});

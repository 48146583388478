import { createApp, h } from 'vue';

import App from './App.vue';
import router from '@/router';
import vfmPlugin from 'vue-final-modal';
import vSelect from 'vue-select';
import store from '@/store';
import Markdown from 'vue3-markdown-it';
import VeeValidate from 'vee-validate';
import DropZone from 'dropzone-vue';
import VueSplide from '@splidejs/vue-splide';
import VueSmoothScroll from 'vue3-smooth-scroll'
import './assets/css/styles.css';

import '@/assets/css/tailwind.css';
import 'mdi-icons/css/materialdesignicons.min.css';
import 'vue-select/dist/vue-select.css';

const app = createApp({
  render: () => h(App),
});
app.component('v-select', vSelect);

app.config.devtools = true;
app.use(vfmPlugin);
app.use(store);
app.use(router);
app.use(Markdown);
app.use(DropZone);
app.use( VueSplide );
app.use( VueSmoothScroll );
app.use(VeeValidate, {
  classes: true,
});
app.mount('#app');

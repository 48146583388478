<template>
  <div class="mt-16 ml-10 container">
    <nav class="text-white rounded-md w-full mb-4">
      <ol class="list-reset flex">
        <li>
          <!-- <a href="/legal" class="text-blue-600 hover:text-blue-700">Legal</a> -->
          <router-link to="/legal">Legal</router-link>
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold"
            >></span
          >
        </li>
        <li>
          <a href="#" class="text-secondary-orange-300 font-bold"
            >Cambio de comercializador
          </a>
        </li>
      </ol>
    </nav>
    <div>
      <div>
        <p class="text-secondary-orange-300 font-bold text-5xl mb-8">
          Cambio de comercializador
        </p>
      </div>

      <div class="conent">
        <p class="text-white text-xl">
          ¿Sabías que puedes elegir tu comercializador de energía?
        </p>
        <br />
        <p class="text-white text-xl">
          ¡Es muy fácil! Y puedes hacerlo para tu casa o empresa, si quieres
          saber más de nosotros escríbenos al whatsapp 311 2706213 y si quieres
          conocer las resoluciones que lo respaldan, Haz clic aquí.
        </p>
        <br />
        <button
          class="bg-secondary-orange-500 rounded-md text-white see-document mb-12"
          @click="openModal"
        >
          Ver documento
        </button>
      </div>
    </div>
    <div class="content-table">
      <table class="w-full mb-16 mt-8">
        <tr class="bg-primary-100">
          <th class="text-left pl-4">Mercado de comercialización</th>
          <th>No. de agentes en el mercado</th>
        </tr>
        <tr class="bg-neutral-100">
          <td class="pl-4">Pereira</td>
          <th>1</th>
        </tr>
      </table>
    </div>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="markDownCc" />
  </vue-final-modal>
</template>
<script>
import { cambioDeComercializador } from "@/helpers/markDownString.js";
import PXModalMark from "@/components/PXModalMark.vue";
export default {
  name: "MarekterChange",
  components: {
    PXModalMark,
  },

  data() {
    return {
      isShowModal: false,
      markDownCc: cambioDeComercializador,
    };
  },

  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },
  },
  created() {
    this.$store.commit("showLegalButtons");
  },
};
</script>
<style scoped>
.content {
  width: 80%;
}

.content-table {
  height: 32vh;
}
.see-document {
  height: 44px;
  width: 170px;
}
</style>

<template>
  <div id="app" class="mt-8">
    <div class="about-you mb-5">
      <span class="text-primary-500 text-xl font-normal">Sobre ti</span>
    </div>
    <Form @submit="onSubmit" class="bg-modal-color">
      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="service"
          >Tu servicio es para</label
        >
        <div>
          <Field
            v-model="serviceSelected"
            name="service"
            as="select"
            :rules="validate"
            class="rounded-lg"
          >
            <option value="1" selected>Hogar</option>
            <option value="2">Empresa</option>
          </Field>
          <ErrorMessage name="service" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="name"
          >Nombres</label
        >
        <div>
          <Field name="name" type="text" class="rounded-lg" :rules="validate" />
          <ErrorMessage name="name" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5" v-if="serviceSelected == 1">
        <label class="text-neutral-700 font-normal text-base" for="lastname"
          >Apellidos</label
        >
        <div>
          <Field
            name="lastname"
            type="text"
            :rules="validate"
            class="rounded-lg"
          />
          <ErrorMessage name="lastname" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="phone"
          >Número de celular</label
        >
        <div>
          <Field
            name="phone"
            type="number"
            @keypress="isNumber($event)"
            :rules="validate"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10)"
            class="rounded-lg"
          />
          <ErrorMessage name="phone" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="email"
          >Correo electrónico</label
        >
        <div>
          <Field
            name="email"
            type="email"
            :rules="validateEmail"
            class="rounded-lg"
          />
          <ErrorMessage name="email" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="state"
          >Departamento del servicio</label
        >
        <div>
          <v-select
            :options="states"
            label="name"
            name="state"
            v-model="stateSelected"
            class="custom-select"
          >
            <template v-slot:no-options>Sin resultados</template>
          </v-select>
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="city"
          >Ciudad del servicio</label
        >
        <div>
          <v-select
            name="city"
            v-model="citySelected"
            :options="cities"
            label="name"
            class="custom-select"
          >
            <template v-slot:no-options>Sin resultados</template>
          </v-select>
        </div>
      </div>

      <div class="about-you mb-5">
        <span class="text-primary-500 text-xl font-normal"
          >Sobre tu energ&iacute;a</span
        >
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="operator_id"
          >¿Cuál es tu empresa actual de energía?</label
        >
        <div>
          <v-select
            name="operator_id"
            :options="companies"
            label="name"
            v-model="companySelected"
            @keyup="textSearch($event)"
          >
            <template v-slot:no-options>Sin resultados</template>
          </v-select>
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="consume"
          >¿Cuál es tu consumo mensual (kWh)?</label
        >
        <div>
          <Field
            name="consume"
            type="text"
            :rules="validate"
            @keypress="isNumber($event)"
          />
          <ErrorMessage name="consume" class="text-error-500" />
        </div>
      </div>

      <div class="my-4">
        <label class="text-neutral-700 font-normal text-base" for="enerbit"
          >¿Cómo te enteraste de Enerbit?</label
        >
        <v-select
          name="enerbit"
          :options="optionsFindEnerbit"
          label="enerbit"
          v-model="findOutSelected"
        >
          <template v-slot:no-options>Sin resultados</template>
        </v-select>
      </div>

      <div class="relative block mb-3 container-check">
        <label class="mr-3 inline-block text-xs" for="authorize"
          >Acepto que he leído los
          <span
            @click="showTycWeb"
            class="underline text-error-500 cursor-pointer"
          >
            Términos y Condiciones
          </span>
          <Field
            class="w-4 ml-20 h-4 float-right check"
            name="authorize"
            type="checkbox"
            :value="true"
            :rules="checkAuthorize"
          />
          <br />
          <ErrorMessage
            name="authorize"
            class="text-error-500 font-bold text-start"
          />
        </label>
      </div>

      <div class="relative block">
        <label class="mr-3 inline-block text-xs" for="authorize"
          >Autorizo a enerBit el tratamiento de mi información <br />
          conforme a su
          <span
            @click="showPersonalData"
            class="underline text-error-500 cursor-pointer"
          >
            política de tratamiento de datos personales
          </span>
          <br />
          para fines comerciales, legales y contractuales
          <Field
            class="w-4 ml-20 h-4 float-right check"
            name="authorize2"
            type="checkbox"
            :rules="checkAuthorize2"
            :value="true"
          />
          <br />
          <br />
          <ErrorMessage name="authorize2" class="text-error-500 font-bold" />
        </label>
      </div>

      <button
        type="submit"
        :disabled="isSubmitting"
        class="send-form w-full text-center py-2 mt-2"
        :class="!isSubmitting && 'bg-secondary'"
      >
        Enviar
      </button>
    </Form>
    <px-invoice-modal />
    <PxTyCModal />
    <vue-final-modal v-model="isShowModal">
      <PXModalMark :onClose="closeModal" :data="renderMark" />
    </vue-final-modal>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import api from "@/api";
import PxInvoiceModal from "@/components/PxInvoiceModal.vue";
import PxTyCModal from "@/components/PxTyCModal.vue";
import { optionsFindOut } from "../helpers/optionsFormSelect";
import PXModalMark from "@/components/PXModalMark.vue";
import { tratamientoDatos, tycWEB } from "@/helpers/markDownString.js";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    PxInvoiceModal,
    PxTyCModal,
    PXModalMark,
  },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
      optionsFindEnerbit: optionsFindOut,
      findOutSelected: "",
      states: [],
      cities: [],
      companies: [],
      stateSelected: "",
      citySelected: "",
      companySelected: "",
      isSubmitting: false,
      leadIntegrator: "",
      serviceSelected: "",
    };
  },

  computed: {
    getHearAboutEnerbit() {
      switch (this.findOutSelected) {
        case "Redes sociales":
          return "social_networks";
        case "Recomendación de un amigo":
          return "recommendation_friend";
        case "Ejecutivo comercial":
          return "commercial_executive";
        default:
          return "other";
      }
    },
  },

  methods: {
    onSubmit(values, { resetForm }) {
      this.isSubmitting = true;
      const cityObject = { ...this.citySelected };
      const operatorObject = { ...this.companySelected };
      const stateObject = { ...this.stateSelected };

      let data = {
        type_services: values.service == 1 ? "home" : "company",
        name: values.name,
        last_name: values.lastname,
        email_from: values.email,
        phone: values.phone,
        city: cityObject.name,
        current_network_operator: operatorObject.name,
        initial_average_active_energy_usage: values.consume,
        policy_data_processing: "yes",
        hear_about_enerbit: this.getHearAboutEnerbit,
        integrator_id: this.leadIntegrator,
        department: stateObject.name,
      };
      api
        .createLead(data)
        .then((result) => {
          if (result.status == 200) {
            resetForm();
            this.$store.commit("toggleFormModal");
            let messageModal = {
              title: "Formulario enviado exitosamente",
              description:
                "Gracias por querer hacer parte de la energía 100% digital enerBit, pronto te contactaremos",
            };
            this.$store.commit("toggleSuccessModal", messageModal);
          } else {
            let messageModal = {
              title: "error al enviar el formulario",
              description: `status code : ${result.status}`,
            };
            this.$store.commit("toggleSuccessModal", messageModal);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        return "Este campo es requerido";
      }

      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "Este campo debe ser un correo electrónico válido";
      }

      // All is good
      return true;
    },
    validate(value) {
      // if the field is empty
      if (!value || !value.length) {
        return "Este campo es requerido";
      }

      // All is good
      return true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getCitiesByState(stateId) {
      api
        .getCities(stateId)
        .then((result) => {
          this.cities = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanies(name = "") {
      api
        .getCompanies(name)
        .then((result) => {
          this.companies = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAuthorize(value) {
      if (value) {
        return true;
      }

      return "Acepta los términos y condiciones para continuar";
    },

    checkAuthorize2(value) {
      if (value) {
        return true;
      }

      return "Autoriza  el tratamiento de datos personales para continuar";
    },
    showInvoiceModal() {
      this.$store.commit("toggleInvoiceModal");
    },
    showTyCmodal() {
      this.$store.commit("toggleTyCmodal");
    },
    getLeadIntegrator() {
      api
        .getLeadIntegrator()
        .then((result) => {
          this.leadIntegrator = result.items[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    textSearch(e) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.getCompanies(e.target.value);
      }, 700);
    },
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    showPersonalData() {
      this.isShowModal = true;
      this.renderMark = tratamientoDatos;
    },
    showTycWeb() {
      this.isShowModal = true;
      this.renderMark = tycWEB;
    },
  },
  watch: {
    stateSelected() {
      const rawObject = { ...this.stateSelected };
      this.getCitiesByState(rawObject.id);
    },

    companySelected() {
      const rawObject = { ...this.companySelected };
      this.getCompanies(rawObject.name);
    },
  },
  created() {
    api
      .getStates()
      .then((result) => {
        this.states = result;
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCompanies();
    this.getLeadIntegrator();
  },
};
</script>

<style>
input,
select {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0%;
  position: relative;
}

.bg-secondary {
  background: #f79009 !important;
  color: #fff;
}

.question-circle {
  position: absolute;
  right: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.perm-identity-icon {
  position: absolute;
  left: 20px;
  margin-top: 15px;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.loader-img {
  animation: spin 2s linear infinite;
}

.about-you {
  border-bottom: 1px solid #d0d5dd;
}

.vs__search {
  height: 35px !important;
  text-align: center !important;
}

.vs__dropdown-toggle {
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

@media (max-width: 460px) {
  .check {
    margin-bottom: 10px;
  }
  .container-check {
    display: flex !important;
    align-items: flex-end !important;
  }
}
</style>

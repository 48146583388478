<template>
  <div>
    <!-- section formulario -->
    <footer class="py-14 bg-primary-700">
      <div class="container mx-auto px-5">
        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4 items-center">
          <div class="col-span-2 ...">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <h3 class="text-lg color-SecondaryOrange300">Legal</h3>
                <ul>
                  <li>
                    <p class="color-white cursor-pointer" @click="pushPage()">
                      Legal y regulatorio
                    </p>
                  </li>
                  <li>
                    <p
                      class="color-white cursor-pointer"
                      @click="showPersonalData()"
                    >
                      Política de tratamiento de datos personales
                    </p>
                  </li>
                  <li>
                    <p
                      class="color-white cursor-pointer"
                      @click="pushTycPage()"
                    >
                      Términos y condiciones
                    </p>
                  </li>
                </ul>
              </div>
              <!-- <div>
                <h3 class="text-lg color-SecondaryOrange300">Compañia</h3>
                <ul>
                  <li>
                    <a href="#" class="color-white">Ley de transparencia</a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="... m-auto sm:ml-auto">
            <img
              src="@/assets/sdm/assets/img/enerbit-white.png"
              width="200"
              alt=""
            />
          </div>
        </div>
        <div class="divider my-5"></div>
        <div class="grid grid-cols-12 gap-4 items-center">
          <div class="col-span-12 md:col-span-6 ...">
            <p class="color-white text-center md:text-left">
              © enerBit S.A.S. E.S.P. Todos los derechos reservados 2022
            </p>
          </div>
          <div class="col-span-12 md:col-span-3 m-auto ...">
            <img
              src="@/assets/sdm/assets/img/logo-vigilancia.png"
              width="100"
              alt=""
            />
          </div>
          <div class="col-span-12 md:col-span-3 m-auto md:ml-auto ...">
            <div class="flex gap-4">
              <a
                href="https://www.linkedin.com/company/enerbit-co/"
                target="__blank"
              >
                <img src="@/assets/sdm/assets/img/linked-in.svg" alt="" />
              </a>
              <a href="https://www.facebook.com/enerbitco" target="__blank">
                <img src="@/assets/sdm/assets/img/facebook.svg" alt="" />
              </a>
              <a href="https://www.instagram.com/enerbitco/" target="__blank">
                <img src="@/assets/sdm/assets/img/intagram.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <vue-final-modal v-model="isShowModal">
      <PXModalMark :onClose="closeModal" :data="renderMark" />
    </vue-final-modal>
  </div>
</template>
<script>
import PXModalMark from "@/components/PXModalMark.vue";
import { tratamientoDatos } from "@/helpers/markDownString.js";

export default {
  components: {
    PXModalMark,
  },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
    };
  },
  methods: {
    pushPage() {
      window.scrollTo(0, 0);
      this.$router.push("/legal");
    },
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    showPersonalData() {
      this.isShowModal = true;
      this.renderMark = tratamientoDatos;
    },
    pushTycPage() {
      window.scrollTo(0, 0);
      this.$router.push("/tyc");
    },
  },
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

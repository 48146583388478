<template>
  <div id="app" class="mt-8">
    <Form @submit="onSubmit" class="bg-modal-color">
      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="full_name"
          >Nombre completo</label
        >
        <div>
          <Field
            name="full_name"
            type="text"
            class="rounded-lg"
            :rules="validate"
          />
          <ErrorMessage name="full_name" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label
          class="text-neutral-700 font-normal text-base"
          for="legal_id_code"
          >No. Documento</label
        >
        <div>
          <Field
            name="legal_id_code"
            type="text"
            :rules="validate"
            class="rounded-lg"
          />
          <ErrorMessage name="legal_id_code" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="email"
          >Correo electrónico</label
        >
        <div>
          <Field
            name="email"
            type="email"
            :rules="validateEmail"
            class="rounded-lg"
          />
          <ErrorMessage name="email" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label class="text-neutral-700 font-normal text-base" for="phone"
          >Número de contacto</label
        >
        <div>
          <Field
            name="phone"
            type="number"
            @keypress="isNumber($event)"
            :rules="validate"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10)"
            class="rounded-lg"
          />
          <ErrorMessage name="phone" class="text-error-500" />
        </div>
      </div>

      <div class="mb-5">
        <label
          class="text-neutral-700 font-normal text-base"
          for="how_we_can_help"
          >En qué podemos ayudarte</label
        >
        <div>
          <textarea
            name="how_we_can_help"
            v-model="howWeCanHelp"
            class="weHelp w-full rounded-lg"
          ></textarea>
        </div>
      </div>

      <div class="custom-input-file mb-5">
        <input
          type="file"
          id="files"
          multiple
          ref="pqrFiles"
          @change="previewFiles"
          class="w-full text-center py-2 mt-2 border-none input-file"
        />
        <label for="files">Subir archivos</label>
        <small class="" v-show="showSelectedFiles"
          >Archivos seleccionados: {{ filesSelected }}</small
        >
      </div>

      <div class="grid grid-cols-1 gap-5 mb-3">
        <label class="check-input">
          Acepto que he leído los
          <span @click="showTycWeb" class="underline"
            >Términos y Condiciones</span
          >
          <Field
            class="checkbox"
            name="authorize"
            type="checkbox"
            :value="true"
            :rules="checkAuthorize"
          />
          <span class="checkmark"></span>
        </label>
        <ErrorMessage
          name="authorize"
          class="text-error-500 font-bold text-start"
        />
      </div>

      <div class="grid grid-cols-1 gap-5 mb-3">
        <label class="check-input">
          Autorizo a enerBit el tratamiento de mi información conforme a su
          <span @click="showPersonalData" class="underline"
            >política de tratamiento de datos personales</span
          >
          para fines comerciales, legales y contractuales
          <Field
            class="checkbox"
            name="authorize2"
            type="checkbox"
            :value="true"
            :rules="checkAuthorize2"
          />
          <span class="checkmark"></span>
        </label>
        <ErrorMessage
          name="authorize2"
          class="text-error-500 font-bold text-start"
        />
      </div>

      <div class="grid grid-cols-1 gap-5 mb-3">
        <label class="check-input">
          Acepto
          <span @click="showElectronicNotify" class="underline"
            >recibir notificaciones por correo electrónico</span
          >
          <Field
            class="checkbox"
            name="authorize3"
            type="checkbox"
            :value="true"
            :rules="checkAuthorize3"
          />
          <span class="checkmark"></span>
        </label>
        <ErrorMessage
          name="authorize3"
          class="text-error-500 font-bold text-start"
        />
      </div>

      <button
        type="submit"
        :disabled="isSubmitting"
        class="send-form w-full text-center py-2 mt-2 rounded-md"
        :class="!isSubmitting && 'bg-secondary'"
      >
        Enviar
      </button>
    </Form>
    <px-invoice-modal />
    <vue-final-modal v-model="isShowModal">
      <PXModalMark :onClose="closeModal" :data="renderMark" />
    </vue-final-modal>
  </div>
</template>

<script>
import PxInvoiceModal from "@/components/PxInvoiceModal.vue";
import PXModalMark from "@/components/PXModalMark.vue";
import {
  electronicNotify,
  tratamientoDatos,
  tycWEB,
} from "@/helpers/markDownString.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import { optionsFindOut } from "../helpers/optionsFormSelect";

import api from "@/api";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    PxInvoiceModal,
    PXModalMark,
  },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
      optionsFindEnerbit: optionsFindOut,
      howWeCanHelp: "",
      isSubmitting: false,
      files: [],
      filesSelected: [],
      showSelectedFiles: false,
    };
  },

  methods: {
    previewFiles() {
      this.files = this.$refs.pqrFiles.files;
      for (const file of this.files) {
        this.filesSelected.push(file.name);
      }
      this.showSelectedFiles = true;
    },
    onSubmit(values, { resetForm }) {
      let formData = new FormData();

      if (this.files.length == 0) {
        formData.append("file", "");
      } else {
        for (const file of this.files) {
          formData.append("file", file, file.name);
        }
      }

      let pqrType = this.$store.getters.getPqrTypeState;
      values["pqr_type"] = pqrType;
      values["how_we_can_help"] = this.howWeCanHelp;

      api
        .createPQR(formData, values)
        .then((result) => {
          if (result.status == 200) {
            resetForm();
            this.howWeCanHelp = "";
            this.filesSelected = [];
            this.$store.commit("showPqrModal");
            let messageModal = {
              title: "PQR registrada exitosamente",
              description:
                "A tu correo llegará un código que hará referencia al seguimiento del caso.",
            };
            this.$store.commit("toggleSuccessModal", messageModal);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        return "Este campo es requerido";
      }

      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "Este campo debe ser un correo electrónico válido";
      }

      // All is good
      return true;
    },
    validate(value) {
      // if the field is empty
      if (!value || !value.length) {
        return "Este campo es requerido";
      }

      // All is good
      return true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkAuthorize(value) {
      if (value) {
        return true;
      }

      return "Acepta los términos y condiciones para continuar";
    },

    checkAuthorize2(value) {
      if (value) {
        return true;
      }

      return "Autoriza  el tratamiento de datos personales para continuar";
    },
    checkAuthorize3(value) {
      if (value) {
        return true;
      }

      return "Autoriza recibir notificaciones por correo electrónico";
    },
    showInvoiceModal() {
      this.$store.commit("toggleInvoiceModal");
    },
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    showPersonalData() {
      this.isShowModal = true;
      this.renderMark = tratamientoDatos;
    },
    showTycWeb() {
      this.isShowModal = true;
      this.renderMark = tycWEB;
    },
    showElectronicNotify() {
      this.isShowModal = true;
      this.renderMark = electronicNotify;
    },
  },
  watch: {
    stateSelected() {
      const rawObject = { ...this.stateSelected };
      this.getCitiesByState(rawObject.id);
    },

    companySelected() {
      const rawObject = { ...this.companySelected };
      this.getCompanies(rawObject.name);
    },
  },
};
</script>

<style>
input,
select {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0%;
  position: relative;
}

.bg-secondary {
  background: #ed7004 !important;
  color: #fff;
}

.question-circle {
  position: absolute;
  right: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.perm-identity-icon {
  position: absolute;
  left: 20px;
  margin-top: 15px;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.loader-img {
  animation: spin 2s linear infinite;
}

.about-you {
  border-bottom: 1px solid #d0d5dd;
}

.vs__search {
  height: 35px !important;
  text-align: center !important;
}

.vs__dropdown-toggle {
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.weHelp {
  resize: none !important;
  border: 1px solid #e0e0e0;
}

.input-file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.custom-input-file label {
  display: block;
  position: relative;
  width: 200px;
  height: 44px;
  background: #ed7004;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 460px) {
  .check {
    margin-bottom: 10px;
  }
  .container-check {
    display: flex !important;
    align-items: flex-end !important;
  }
}
</style>

<template>
  <div>
    <section class="py-14" id="celsiaBackrest">
      <div class="container mx-auto px-5">
        <h2 class="text-4xl font-semibold text-center mb-4">
          <span class="color-primary">ener</span>Bit con respaldo Celsia
        </h2>
        <iframe
          class="w-full h-video"
          src="https://enerbitdevpublic.blob.core.windows.net/logos/video_sdm.mp4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "BackrestSection",
};
</script>
<style>
#celsiaBackrest {
  background-color: #e4e7ec !important;
}
</style>

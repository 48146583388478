<template>
  <div class="mt-16 ml-10">
    <div>
      <nav class="text-white rounded-md w-full mb-4">
        <ol class="list-reset flex">
          <li>
            <router-link class="text-blue-600 hover:text-blue-700" :to="{ path: '/legal' }">Legal</router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-white font-bold">></span>
          </li>
          <li>
            <router-link class="text-blue-600 hover:text-blue-700" :to="{ path: '/costos-tarifas' }"
              >Costos y tarifas
            </router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-white font-bold">></span>
          </li>
          <li>
            <router-link
              class="text-blue-600 hover:text-blue-700"
              :to="{ path: '/costos-tarifas/' + this.$route.params.year }"
              >Meses
            </router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold">></span>
          </li>
          <li>
            <router-link to="" class="text-secondary-orange-300 font-bold">Mercado </router-link>
          </li>
        </ol>
      </nav>

      <div v-if="buttonLegalData.length > 0">
        <px-legal-button v-for="data in buttonLegalData" :key="data.text" :text="data.text" :to="data.to" />
      </div>
      <div v-else>
        <div class="text-center mt-5 mb-8">
          <img
            :src="
              require('@/assets/img/tarifas/versionesNuevas/' + $route.params.year + '/' + $route.params.month + '.jpg')
            "
            alt=""
            class="text-center inline-block"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PxLegalButton from "../components/PxLegalButton.vue";
import { ccu } from "@/helpers/markDownString.js";
import cost from "../assets/cost.json";

export default {
  components: { PxLegalButton },
  data() {
    return {
      isShowModal: false,
      markCcu: ccu,
      buttonLegalData: [],
    };
  },
  methods: {
    checkCityMonths: function () {
      var month = this.$route.params.month;
      var year = this.$route.params.year;
      var selectedMonths = cost.periods.find((d) => d.key === year);
      var selectedCities = selectedMonths.months.find((d) => d.key === month);

      this.buttonLegalData = selectedCities.cities.map((e) => ({
        text: e.text,
        to: "/costos-tarifas/" + this.$route.params.year + "/" + this.$route.params.month + e.to,
      }));
    },
  },
  beforeMount: function () {
    this.checkCityMonths(); // Calls the method before page loads
  },
};
</script>

<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <div class="mt-16 ml-10 h-screen">
    <div>
      <nav class="text-white rounded-md w-full mb-4">
        <ol class="list-reset flex">
          <li>
            <router-link
              class="text-blue-600 hover:text-blue-700"
              @click="showLegal()"
              :to="{ path: '/tyc' }"
              >Términos y condiciones</router-link
            >
          </li>
        </ol>
      </nav>
      <div>
        <button @click="showTycAPP" class="legal-button text-lg leading-8">
          Términos y condiciones APP
        </button>
      </div>
      <div>
        <button @click="showTycWeb" class="legal-button text-lg leading-8">
          Términos y condiciones WEB
        </button>
      </div>
    </div>
  </div>
  <vue-final-modal v-model="isShowModal">
    <PXModalMark :onClose="closeModal" :data="renderMark" />
  </vue-final-modal>
</template>

<script>
import PXModalMark from "@/components/PXModalMark.vue";
import { tycAPP, tycWEB } from "@/helpers/markDownString.js";

export default {
  components: { PXModalMark },
  data() {
    return {
      isShowModal: false,
      renderMark: "",
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    showTycAPP() {
      this.isShowModal = true;
      this.renderMark = tycAPP;
    },

    showTycWeb() {
      this.isShowModal = true;
      this.renderMark = tycWEB;
    }
  },
};
</script>

<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

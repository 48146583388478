<template>
  <div class="container-time">
    <div
      v-for="item in timeLineData"
      :key="item.idContacto"
      class="timeline-block timeline-block-right mb-10"
    >
      <div class="marker">
        <img src="../assets/img/check-circle.png" class="img-timeline" alt="" />
      </div>
      <div class="timeline-content">
        <h3>{{ item.fecha }}</h3>
        <p>{{ item.estado }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PxTimeLine",
  computed: {
    timeLineData() {
      return this.$store.state.timeLineData;
    },
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  font-weight: 300;
  color: #777;
}

h1 span {
  font-weight: 600;
}

.container-time {
  width: 50%;
  padding: 50px 0;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 4;
}

.timeline-block:before {
  content: "";
  position: absolute;
  top: 90px;
  margin-left: 11px;
  width: 2px;
  height: 100%;
  background: #12b76a;
  z-index: 1;
}

.timeline-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.marker {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-top: 10px;
  z-index: 9999;
}

.timeline-content {
  width: 100%;
  padding: 0 15px;
  color: #666;
}

.timeline-content h3 {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.timeline-content span {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 400;
}

.img-timeline {
  background-color: #8b78b0;
}

@media screen and (max-width: 768px) {
  .container-time:before {
    left: 8px;
    width: 2px;
  }
  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}
</style>

<template>
  <div class="mt-16 ml-10 container">
    <nav class="text-white rounded-md w-full mb-4">
      <ol class="list-reset flex">
        <li>
          <router-link to="/legal" class="text-blue-600 hover:text-blue-700"
            >Legal
          </router-link>
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-white font-bold">></span>
        </li>
        <li>
          <router-link to="/costos-tarifas" class="text-white"
            >Costos y tarifas
          </router-link>
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-white font-bold">></span>
        </li>
        <li>
          <router-link
            class="text-blue-600 hover:text-blue-700"
            :to="{ path: '/costos-tarifas/' + $route.params.year }"
            >Meses</router-link
          >
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-white font-bold">></span>
        </li>
        <li>
          <router-link
            class="text-blue-600 hover:text-blue-700"
            :to="{
              path:
                '/costos-tarifas/' +
                $route.params.year +
                '/' +
                $route.params.month,
            }"
            >Mercado</router-link
          >
        </li>
        <li>
          <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold"
            >></span
          >
        </li>
        <li>
          <a href="#" class="text-secondary-orange-300 font-bold"
            >Cambio de comercializador
          </a>
        </li>
      </ol>
    </nav>
    <div>
      <div class="mt-16">
        <p class="text-secondary-orange-300 font-bold text-5xl">
          Tarifas reguladas
        </p>
      </div>

      <div class="text-center mt-5 mb-8">
        <img
          :src="
            require('@/assets/img/tarifas/versionesAnteriores/' +
              $route.params.city +
              '/' +
              $route.params.year +
              '/' +
              $route.params.month +
              '.jpg')
          "
          alt=""
          class="text-center inline-block"
        />
      </div>

      <div></div>
    </div>
  </div>
</template>
<script>
// import PxTable from "@/components/PxTable.vue";
export default {
  name: "Tarrifs",
  components: {},
};
</script>
<style scoped></style>

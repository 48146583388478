<template>
  <div>
    <nav class="bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-24">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <a href="/"
                ><img
                  class="h-11 sm:h-13"
                  src="@/assets/sdm/assets/img/Logotipo-eB.png"
                  alt="enerBit"
              /></a>
            </div>
            <div class="flex-shrink-0 px-2">
              <div class="nav-divider"></div>
            </div>
            <div class="flex-shrink-0 pl-2">
              <img
                class="h-8 sm:h-10"
                src="@/assets/sdm/assets/img/logo-SDM.png"
                alt="Serena del mar"
              />
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-10 nav-menu flex items-baseline space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

              <a
                href="#youKnowSection"
                v-smooth-scroll
                class="px-3 py-2 rounded-md text-md font-medium"
                >¿Sabías qué?</a
              >

              <a
                href="#benefits"
                v-smooth-scroll
                class="px-3 py-2 rounded-md text-md font-medium"
                >Beneficios</a
              >

              <a
                href="#steps"
                v-smooth-scroll
                class="px-3 py-2 rounded-md text-md font-medium"
                >Pasos</a
              >

              <a
                href="#form"
                v-smooth-scroll
                class="px-3 py-2 rounded-md text-md font-medium"
                >Formulario</a
              >
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button
              type="button"
              id=""
              class="bg-primary inline-flex items-center justify-center p-2 rounded-md color-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              @click="mobileNav"
            >
              <span class="sr-only">Open main menu</span>
              <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
              <svg
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div :class="{ hidden: !shoWMobileMenu }" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
            href="#youKnowSection"
            v-smooth-scroll
            class="color-secondary block px-3 py-2 rounded-md text-base font-medium"
            >¿Sabías qué?</a
          >

          <a
            href="#benefits"
            v-smooth-scroll
            class="color-secondary block px-3 py-2 rounded-md text-base font-medium"
            >Beneficios</a
          >

          <a
            href="#steps"
            v-smooth-scroll
            class="color-secondary block px-3 py-2 rounded-md text-base font-medium"
            >Pasos</a
          >
          <a
            href="#form"
            v-smooth-scroll
            class="color-secondary block px-3 py-2 rounded-md text-base font-medium"
            >Formulario</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SdmNav",
  data() {
    return {
      shoWMobileMenu: false,
    };
  },
  methods: {
    mobileNav() {
      this.shoWMobileMenu = !this.shoWMobileMenu;
    },
  },
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

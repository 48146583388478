<template>
  <div class="mt-16 ml-10">
    <div>
      <nav class="text-white rounded-md w-full mb-4">
        <ol class="list-reset flex">
          <li>
            <router-link class="text-blue-600 hover:text-blue-700" :to="{ path: '/legal' }">Legal</router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-white font-bold">></span>
          </li>
          <li>
            <router-link class="text-blue-600 hover:text-blue-700" :to="{ path: '/costos-tarifas' }"
              >Costos y tarifas
            </router-link>
          </li>
          <li>
            <span class="text-gray-500 mx-4 text-secondary-orange-300 font-bold">></span>
          </li>
          <li>
            <router-link to="" class="text-secondary-orange-300 font-bold">Meses </router-link>
          </li>
        </ol>
      </nav>
      <px-legal-button v-for="data in buttonLegalData" :key="data.text" :text="data.text" :to="data.to" />
    </div>
  </div>
</template>

<script>
import PxLegalButton from "../components/PxLegalButton.vue";
import { ccu } from "@/helpers/markDownString.js";
import cost from "../assets/cost.json";

export default {
  components: { PxLegalButton },
  data() {
    return {
      isShowModal: false,
      markCcu: ccu,
      buttonLegalData: [],
    };
  },
  methods: {
    checkMonthsYears: function () {
      var year = this.$route.params.year;
      var selectedYear = cost.periods.find((d) => d.key == year);
      this.buttonLegalData = selectedYear.months.map((e) => ({
        text: e.text,
        to: "/costos-tarifas/" + this.$route.params.year + e.to,
      }));
    },
  },
  mounted: function () {
    this.checkMonthsYears(); // Calls the method before page loads
  },
};
</script>

<style scoped>
.legal-button {
  cursor: pointer;
  color: #fff;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

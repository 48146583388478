<template>
  <div>
    <Nav />
    <Header />
    <FloatingButtons />
    <main class="bg-neutral-100">
      <YouknowSection />
      <BackrestSection />
      <BenefitsSection />
      <StepsSection />
      <FormSection />
      <Footer />
    </main>
  </div>
</template>
<script>
import Nav from "@/views/sdm/components/Nav.vue";
import Header from "@/views/sdm/components/Header.vue";
import FloatingButtons from "@/views/sdm/components/FloatingButtons.vue";
import YouknowSection from "@/views/sdm/components/YouknowSection.vue";
import BackrestSection from "@/views/sdm/components/BackrestSection.vue";
import BenefitsSection from "@/views/sdm/components/BenefitsSection.vue";
import StepsSection from "@/views/sdm/components/StepsSection.vue";
import FormSection from "@/views/sdm/components/FormSection.vue";
import Footer from "@/views/sdm/components/Footer.vue";
export default {
  name: "SDM",
  components: {
    Nav,
    Header,
    FloatingButtons,
    YouknowSection,
    BackrestSection,
    BenefitsSection,
    StepsSection,
    FormSection,
    Footer,
  },
};
</script>

<template>
  <div class="recover-container grid grid-cols-1 lg:grid-cols-2">
    <div class="flex col-span-1 justify-center">
      <div class="form-container mb-6">
        <img
          class="mb-5"
          src="@/assets/img/logo-enerbit.svg"
          alt="enerbit-logo"
        />
        <h3 class="text-primary-800 text-3xl font-normal mb-5">
          Reestablece tu contraseña
        </h3>
        <form @submit.prevent="onSubmit">
          <label class="text-primary-800 font-medium text-sm"
            >Ingresa nueva contraseña</label
          >
          <div class="input-container">
            <input
              :type="typeInput"
              class="mt-3 mb-6 px-3"
              v-model="password1"
              :class="isErrorPasswords.pass1s && 'error-input'"
            />
            <span
              class="error-msg text-error-700 text-sm font-medium"
              v-if="isErrorPasswords.pass1"
              >Requerido y 12 o más caracteres</span
            >
            <i
              class="mdi mdi-eye icon text-primary-800"
              @click="passwordToggle"
            ></i>
          </div>
          <label class="text-primary-800 font-medium text-sm"
            >Confirmar nueva contraseña</label
          >

          <div class="input-container">
            <input
              :type="typeInput2"
              class="mt-3 mb-5 px-3"
              v-model="password2"
              :class="isErrorPasswords.pass2 && 'error-input'"
              oncopy="return false"
              onpaste="return false"
            />
            <i
              class="mdi mdi-eye icon text-primary-800"
              @click="password2Toggle"
            ></i>
            <span
              class="error-msg text-sm text-error-700 font-medium"
              v-if="isErrorPasswords.pass2"
            >
              Las contraseñas no coinciden
            </span>
          </div>
          <button
            class="btn font-bold rounded text-white mt-6 py-2 px-5 w-full"
            type="submit"
            :class="isLoading ? 'button-disabled' : 'bg-primary-800'"
            :disabled="isLoading"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
    <div class="img-container hidden lg:block"></div>
    <div class="mask" v-if="isShowModal"></div>
    <div class="modal rounded shadow-xl" v-if="isShowModal">
      <i class="text-4xl" :class="classStyle"></i>
      <span
        class="text-lg text-center"
        :class="requestError ? 'text-error-700' : 'text-success-500'"
        >{{ msgModal }}</span
      >
      <button
        class="mt-6 btn px-5 py-1 rounded text-white"
        :class="requestError ? 'bg-error-700' : 'bg-success-500'"
        @click="closeModal"
      >
        OK
      </button>
    </div>
  </div>
</template>

<script>
import { baseUsers } from "../config/config";
export default {
  data() {
    return {
      typeInput: "password",
      typeInput2: "password",

      isShowModal: false,
      isLoading: false,
      msgModal: null,
      classStyle: "",
      requestError: false,

      password1: "",
      password2: "",

      isErrorPasswords: {
        pass1: false,
        pass2: false,
      },
    };
  },

  methods: {
    passwordToggle() {
      if (this.typeInput === "password") {
        this.typeInput = "text";
      } else {
        this.typeInput = "password";
      }
    },
    password2Toggle() {
      if (this.typeInput2 === "password") {
        this.typeInput2 = "text";
      } else {
        this.typeInput2 = "password";
      }
    },

    closeModal() {
      this.isShowModal = false;
    },

    async changedPassword() {
      this.isLoading = true;
      const body = {
        password: this.password1,
        token: this.$route.query.token,
      };
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        };

        const res = await fetch(`${baseUsers}reset-password`, requestOptions);

        if (res.ok) {
          this.isShowModal = true;
          this.msgModal = "Contraseña cambiada con éxito";
          this.classStyle = "mdi mdi-check-circle text-success-500";
          this.requestError = false;
          this.isLoading = false;
        }
        if (res.ok === false) {
          this.isShowModal = true;
          this.msgModal =
            "¡Ups algo raro paso! por favor inténtalo de nuevo más tarde";
          this.classStyle = "mdi mdi-close-circle text-error-700";
          this.requestError = true;
          this.isLoading = false;
        }
      } catch (error) {
        return error;
      }
    },

    onSubmit() {
      this.isErrorPasswords.pass1 = false;
      this.isErrorPasswords.pass2 = false;
      if (
        this.password1.trim().length === 0 ||
        this.password1.trim().length < 12
      ) {
        this.isErrorPasswords.pass1 = true;
      }

      if (this.password1 !== this.password2) {
        this.isErrorPasswords.pass2 = true;
      }

      if (this.isErrorPasswords.pass1 || this.isErrorPasswords.pass2) {
        return;
      }

      this.changedPassword();
    },
  },
};
</script>

<style scoped>
.recover-container {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  position: absolute;
  width: 100%;
}
.button-disabled {
  border: 1px solid #0066cc;
  background-color: #0099cc;
  color: #ffffff;
  padding: 5px 10px;
}

.modal {
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 25%;
  width: 300px;
}

.mask {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
}

.recover-container .form-container {
  max-width: 350px;
}

.recover-container .form-container .input-container {
  position: relative;
  top: 0px;
}

.recover-container .form-container .input-container .icon {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 15px;
}

input:focus {
  outline: 3px solid #8080ff;
}

.img-container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/formImg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.error-msg {
  position: absolute;
  top: 60px;
  left: 0;
}

.error-input {
  outline: 2px solid red;
}

@media (max-width: 360px) {
  .recover-container .form-container {
    max-width: 70%;
  }
}
</style>

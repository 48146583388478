<template>
  <div class="lg:mt-24 lg:flex lg:flex-row">
    <div
      class="lg:w-3/12 lg:float-left lg:mr-32 lg:text-2xl font-normal leading-7 text-center lg:ml-24 text-lg"
    >
      <p
        class="lg:text-4xl text-xl font-normal leading-10 mt-6 lg:mt-12 mb-6 m-auto text-center text-white"
      >
        ¿Quieres conocer cuánto <strong>ahorrarías</strong>?
      </p>

      <a
        href="https://api.whatsapp.com/send?phone=+573102118496&amp;text=Hola%21%20Quisiera%20más%20información."
        class="float pt-2"
        target="_blank"
        ><button
          class="button-plan text-center text-white bg-secondary-orange-500"
        >
          Chatea con nosotros
        </button></a
      >
    </div>
    <div
      class="lg:w-7/12 lg:float-right bg-white plan-description mr-4 lg:mr-0 rounded"
    >
      <div class="overflow-hidden flex flex-col lg:flex-row">
        <div
          class="lg:text-left text-2xl font-normal leading-7 lg:flex-auto text-center"
        >
          <img src="@/assets/img/clockLogo.svg" class="inline" alt="" />
        </div>
        <div
          class="lg:w-6/12 m-auto text-center float-right text-2xl font-normal leading-7 flex-auto"
        >
          <p>
            <b>¡Con este plan </b>
            <span class="text-green-400">DESPREOCÚPATE </span>
            <b>por las horas en que consumes!</b>
          </p>
          <br />
          <p>Con fijaBit tendrás ahorros frente a lo que pagas hoy.</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <px-cards :items="cardsContent" />
  </div>
</template>
<script>
import PxCards from "@/components/PxCards.vue";

export default {
  name: "PxPlanFijaBit",
  components: { PxCards },
  data() {
    return {
      cardsContent: [
        {
          title: "Cuidamos el planeta",
          titleColor: "text-tertiary-900",
          description:
            "Tu negocio consumirá energía 100% renovable. Te daremos Certificados de Energía Renovable (REC)",
          img: require("@/assets/img/tree.svg"),
          childrens: [],
          bg: "bg-white",
        },
        {
          title: "¡Somos 100% digitales!",
          titleColor: "text-white",
          description: "",
          img: require("@/assets/img/phone.svg"),
          childrens: [
            {
              title: "Visualiza tus consumos hora a hora.",
            },
            {
              title: "Atención 24/7.",
            },
            {
              title: "Consulta y paga tus facturas.",
            },
            {
              title: "Cámbiate de plan cuando quieras.",
            },
          ],
          bg: "bg-primary-700",
        },
      ],
      titlePlans: "Hogares",
      descriptionPlans: " Planes simples pensados para ti",
    };
  },
};
</script>
<style scoped>
.button-plan {
  cursor: pointer;
  height: 61px;
  border-radius: 5px;
  padding: 16px 24px 16px 24px;
  font-size: 20px;
  font-weight: bold;
}
.business-descrpiption {
  color: #04d15f;
}

.plan-description {
  border-radius: 33px 0px 0px 33px !important;
  padding: 55px 9.55% 55px 8.36%;
}
</style>

<template>
  <div>
    <div class="text-center text-white mt-10 mb-10">
      <p>
        Programa tus consumos para las horas de mayor ahorro y tu bolsillo te lo
        agradecerá
      </p>
    </div>
    <div class="lg:text-2xl font-normal leading-7 text-center text-lg">
      <a
        href="https://api.whatsapp.com/send?phone=+573112706213&amp;text=Hola%21%20Quisiera%20más%20información."
        class="float pt-2"
        target="_blank"
        ><button
          class="button-plan text-center text-white bg-secondary-orange-500"
        >
          Chatea con nosotros
        </button></a
      >
      <p
        class="text-4xl font-bold leading-10 mt-10 mb-6 m-auto text-center text-white"
      >
        Te contamos cuánto ahorrarías
      </p>
    </div>
  </div>
  <div>
    <px-cards :items="cardsContent" />
  </div>
</template>
<script>
import PxCards from "@/components/PxCards.vue";

export default {
  name: "PxPlanDinaBit",
  components: { PxCards },
  data() {
    return {
      cardsContent: [
        {
          title: "Cuidamos el planeta",
          titleColor: "text-tertiary-900",
          description:
            "Al ser nuestro cliente, sembraremos árboles por ti y tendrás un certificado a tu nombre",
          img: require("@/assets/img/tree.svg"),
          childrens: [],
          bg: "bg-white",
        },
        {
          title: "¡Somos 100% digitales!",
          titleColor: "text-white",
          description: "",
          img: require("@/assets/img/phone.svg"),
          childrens: [
            {
              title: "Visualiza tus consumos hora a hora.",
            },
            {
              title: "Atención 24/7.",
            },
            {
              title: "Consulta y paga tus facturas.",
            },
            {
              title: "Cámbiate de plan cuando quieras.",
            },
          ],
          bg: "bg-primary-700",
        },
      ],
      titlePlans: "Hogares",
      descriptionPlans: " Planes simples pensados para ti",
    };
  },
};
</script>
<style scoped>
.button-plan {
  cursor: pointer;
  height: 61px;
  border-radius: 5px;
  padding: 16px 24px 16px 24px;
  font-size: 20px;
  font-weight: bold;
}
.business-descrpiption {
  color: #04d15f;
}

.plan-description {
  width: 74.5%;
  padding: 33px 4% 33px 5.55%;
  border-radius: 0px 33px 33px 0px;
  margin-bottom: 27px;
}
</style>

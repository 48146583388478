<template>
  <div>
    <px-custom-modal
      v-model="showFormModal"
      @cancel="changeModalState"
      :transition="{
        'enter-active-class': 'transition duration-200 ease-in-out transform',
        'enter-class': 'translate-y-full',
        'enter-to-class': 'translate-y-0',
        'leave-active-class': 'transition duration-200 ease-in-out transform',
        'leave-to-class': 'translate-y-full',
        'leave-class': 'translate-y-0',
      }"
    >
      <template v-slot:title>
        <span class="text-center text-primary-500"
          >¡Toma el control de tu energía!</span
        >
      </template>
      <div class="w-full px-3 md:pr-48 md:pl-48 bg-modal-color">
        <px-form-take-control />
      </div>
    </px-custom-modal>
    <div class="text-center">
      <button
        @click="changeModalState"
        class="take-control text-lg leading-8 lg:float-left text-center sm:w-56 bg-secondary-orange-500"
      >
        {{ text }}
      </button>
    </div>
  </div>
</template>
<script>
import PxCustomModal from '@/components/PxCustomModal.vue';
import PxFormTakeControl from '@/components/PxFormTakeControl.vue';

export default {
  name: 'PxModal',
  components: { PxCustomModal, PxFormTakeControl },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    showFormModal() {
      return this.$store.state.showFormModal;
    },
  },
  methods: {
    changeModalState() {
      this.$store.commit('toggleFormModal');
    },
  },
};
</script>
<style scoped>
.take-control {
  cursor: pointer;
  width: 310px !important;
  height: 61px;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: url('../assets/img/click.svg');
  background-position: 255px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}
</style>

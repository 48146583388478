<template>
  <div>
    <vue-final-modal
      v-model="showInvoiceModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <button class="modal__close" @click="changeModalState()">
        <i class="mdi mdi-window-close"></i>
      </button>
      <div class="modal__content py-5 px-5">
        <div class="flex flex-row">
          <div class="mr-5">
            <img class="invoice-img" src="@/assets/img/invoice.png" alt="" />
          </div>
          <div class="flex flex-col justify-center items-center text-invoice">
            <p class="text-neutral-700 text-2xl font-bold">
              ¿Dónde encuentras la información?
            </p>
            <span class="text-xl mt-6"
              >En tu factura de energía, busca en la
              <b>información de consumo</b>, la barra que dice
              <b>“promedio”</b> resaltado en el círculo. <br />
              <br />
              Ingresa ese número en la casilla del formulario.</span
            >

            <button
              class="w-full bg-secondary-orange-500 h-12 rounded-md text-white mt-6"
              @click="changeModalState()"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <template> </template>
</template>
<script>
export default {
  name: 'PxInvoiceModal',
  components: {},
  props: {
    text: {
      type: String,
      // required: true,
    },
  },
  computed: {
    showInvoiceModal() {
      return this.$store.state.showInvoiceModal;
    },
  },
  methods: {
    changeModalState() {
      this.$store.commit('toggleInvoiceModal');
    },
  },
};
</script>
<style scoped>
.invoice-img {
  width: 638px;
}

.text-invoice {
  width: 450px;
}

.take-control {
  cursor: pointer;
  width: 260.6px;
  height: 62px;
  background: #f79009;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: #f79009 url('../assets/img/click.svg');
  background-position: 220px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

.enerbit-text {
  color: #4e04a3;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.modal__content {
  width: 1128.34px !important;
}
</style>

<template>
  <div>
    <section class="py-14 content-benefits" id="benefits">
      <div class="container mx-auto px-5">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-4 ...">
            <p class="color-white font-semibold text-1xl md:text-2xl mb-4">
              Más que una empresa de energía
              <span class="color-secondary font-bold"
                >somos una plataforma digital.</span
              >
            </p>
            <p class="color-white font-semibold text-1xl md:text-2xl mb-4">
              Ahorras hasta un 20% que con empresas tradicionales.
            </p>

            <p class="color-white font-semibold text-1xl md:text-2xl mb-4">
              Disfruta de estos
              <span class="color-secondary font-bold"
                >servicios de eficiencia energética.</span
              >
            </p>

            <img
              src="@/assets/sdm/assets/img/bitor1.png"
              class="img-benefits"
              alt=""
            />
          </div>
          <div class="col-span-12 md:col-span-7 ...">
            <div class="slider mb-12">
              <Splide
                :options="{
                  drag: true,
                  rewind: true,

                  perPage: 3,
                  breakpoints: {
                    500: {
                      perPage: 1,
                    },
                    1000: {
                      perPage: 2,
                    },
                  },
                }"
                aria-label="Vue Splide Example"
              >
                <SplideSlide>
                  <div class="content-slide">
                    <div class="info">
                      <img
                        src="@/assets/sdm/assets/img/Icon-SMD1.png"
                        class="mb-5"
                        width="100"
                        alt="Beneficios"
                      />
                      <p>Visualizar tus consumos hora a hora</p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="content-slide">
                    <div class="info">
                      <img
                        src="@/assets/sdm/assets/img/Icon-SMD2.png"
                        class="mb-5"
                        width="100"
                        alt="Beneficios"
                      />
                      <p>Consulta y pagar tu factura digital</p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="content-slide">
                    <div class="info">
                      <img
                        src="@/assets/sdm/assets/img/Icon-SMD3.png"
                        class="mb-5"
                        width="100"
                        alt="Beneficios"
                      />
                      <p>Personaliza y recibe notificaciones y alertas</p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="content-slide">
                    <div class="info">
                      <img
                        src="@/assets/sdm/assets/img/Icon-SMD4.png"
                        class="mb-5"
                        width="100"
                        alt="Beneficios"
                      />
                      <p>
                        Sembraremos árboles por ti y certificado a tu nombre
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="content-slide">
                    <div class="info">
                      <img
                        src="@/assets/sdm/assets/img/Icon-SMD5.png"
                        class="mb-5"
                        width="100"
                        alt="Beneficios"
                      />
                      <p>Realizar presupuesto de consumo</p>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div class="text-center">
              <p class="color-white text-lg mb-5">
                Los servicios de eficiencia energética tienen un cargo fijo
                mensual desde $9.900 hogar y $29.900 empresa. Aún con el cargo
                fijo mensual,
                <strong
                  >ahorrarás mucho más frente a un comercializador
                  tradicional.</strong
                >
              </p>
              <p class="color-white font-bold text-3xl mb-5">
                ¡Descarga ya la app enerBit!
              </p>
              <div class="flex justify-center">
                <a href="" class="inline-block p-2">
                  <img src="@/assets/sdm/assets/img/google-play.png" alt="" />
                </a>
                <a href="" class="inline-block p-2">
                  <img src="@/assets/sdm/assets/img/app-store.png" alt="" />
                </a>
              </div>
            </div>

            <img
              src="@/assets/sdm/assets/img/bitor1.png"
              class="img-benefits-movil"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
export default {
  name: "BenefitsSection",
  components: { Splide, SplideSlide },
};
</script>
<style src="@/assets/sdm/assets/css/style.css"></style>

<template>
  <div class="container-pqr">
    <div class="text-center w-6/12 m-auto">
      <p class="text-5xl font-bold text-secondary-orange-300 mt-10 mb-12">
        ¿En qué podemos ayudarte?
      </p>

      <div
        class="bg-primary-300 h-20 text-center rounded-md mb-5 cursor-pointer"
        @click="changeModalState('Peticion')"
      >
        <p class="text-white font-bold text-2xl pt-2">
          ¿Necesitas información?
        </p>
        <span class="text-sm text-white"
          >Peticiones, información de trámites o procedimientos</span
        >
      </div>
      <div
        class="bg-primary-300 h-20 text-center rounded-md mb-5 cursor-pointer"
        @click="changeModalState('Queja')"
      >
        <p class="text-white font-bold text-2xl pt-2">
          ¿Problemas con tu servicio?
        </p>
        <span class="text-sm text-white"
          >Quejas de servicio y/o procedimiento</span
        >
      </div>
      <div
        class="bg-primary-300 h-20 text-center rounded-md mb-5 cursor-pointer"
        @click="changeModalState('Reclamo')"
      >
        <p class="text-white font-bold text-2xl pt-2">
          ¿Problemas con tu facturación?
        </p>
        <span class="text-sm text-white"
          >Reclamos de consumo y/o valores facturados</span
        >
      </div>
     
    </div>
    <PxPQRModal />
    <PxMesssageModal />
  </div>
</template>
<script>
import PxMesssageModal from "@/components/PxMesssageModal.vue";
import PxPQRModal from "@/components/PxPQRModal.vue";

export default {
  components: { PxPQRModal, PxMesssageModal },
  methods: {
    changeModalState(type) {
      this.$store.commit("changePqrType", type);
      this.$store.commit("showPqrModal");
    },
  },
};
</script>
<style scoped></style>

<template>
  <vue-final-modal v-model="showTyCmodal">
    <div class="modal-container">
      <div class="mark-down">
        <i
          class="mdi mdi-close float-right text-xl cursor-pointer"
          @click="changeModalState()"
        ></i>
        <Markdown :source="tyc" class="prose md:prose-lg lg:prose-lg" />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Markdown from 'vue3-markdown-it';

import api from '@/api.js';

export default {
  name: 'PxTyCModal',
  components: {
    Markdown,
  },
  data() {
    return {
      tyc: '',
    };
  },
  computed: {
    showTyCmodal() {
      return this.$store.state.showTyCmodal;
    },
  },
  methods: {
    changeModalState() {
      this.$store.commit('toggleTyCmodal');
    },
  },
  created() {
    api
      .getTyC()
      .then((result) => {
        this.tyc = result;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped>
.modal-container {
  position: absolute;
  overflow: scroll;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal-container .mark-down {
  background: #fff;
  height: 100%;
  padding: 30px;
  min-width: 320px;
  overflow-y: scroll;
}
.invoice-img {
  width: 638px;
}

.text-invoice {
  width: 450px;
}

.take-control {
  cursor: pointer;
  width: 260.6px;
  height: 62px;
  background: #f79009;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, Verdana;
  background: #f79009 url('../assets/img/click.svg');
  background-position: 220px 15px;
  background-repeat: no-repeat;
}

.take-control:hover {
  box-shadow: 0px 4px 21px #f79009;
}

.enerbit-text {
  color: #4e04a3;
}
</style>

<template>
  
  <div class="pr-16 pl-16 mt-16">
    <div class="container flex flex-col mt-16 lg:inline xl:inline">
      <div class="order-2 lg:order-none lg:float-right lg:w-3/5">
        <video controls autoplay muted>
          <source
            :src=video
            type="video/mp4"
          />
        </video>
      </div>
      <h1
        class="text-2xl text-warning-500 lg:w-2/5 lg:text-left order-1 lg:order-none text-center font-bold save-money-text lg:text-5xl leading-tight mt-6"
      >
        ¿Quieres tomar el control de tu energía y ahorrar?
      </h1>

      <p
        class="text-lg lg:w-2/5 text-center order-3 lg:order-none text-white pb-5 lg:text-2xl lg:text-left mt-10"
      >
        Te damos la bienvenida a la energía digital. <br /><br />
      </p>

      <div class="order-4 lg:order-none lg:w-2/5">
        <px-modal-take-control :text="text"></px-modal-take-control>
        <px-messsage-modal />
      </div>
    </div>

    <div class="container flex mt-16 lg:mt-40 justify-center">
      <div
        class="highlights-icons bg-primary-300 absolute h-auto w-11/12 rounded-xl z-30 mt-5"
      >
        <div class="w-full rounded-xl pb-8 lg:flex justify-evenly">
          <div
            v-for="item in highlights"
            :key="item.title"
            class="w-5/6 h-48 mt-0 mr-auto mb-8 ml-auto lg:w-4/5 pt-5"
          >
            <div class="lg:absolute lg:w-48 lg:h-40 lg:ml-16">
              <div class="lg:m-auto lg:w-16 lg:h-16 m-auto w-16 h-16">
                <img :src="item.img" alt="" />
              </div>
              <h1
                class="text-white font-medium lg:h-12 lg:w-48 lg:mt-3 lg:mb-0 lg:text-lg lg:leading-6 text-center"
              >
                {{ item.title }}
              </h1>
              <p
                class="text-white lg:w-48 lg:mt-2 lg:leading-5 lg:text-sm lg:font-thin text-center"
              >
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="team lg:absolute w-full h-64 m-0 absolute">
        <img src="@/assets/img/teamPhoto.jpg" alt="enerbitTeam" />
      </div>
      <div
        class="team-enerbit bg-primary-300 pt-1 lg:pt-8 md:pt-8 text-white text-base font-bold"
      >
        <p
          class="m-auto h-16 w-11/12 lg:text-xl font-light sm:leading-4 sm:pt-2"
        >
          En enerBit apostamos todo a revolucionar el acceso a la
          <b class="font-bold">energía</b>, de una manera
          <b class="text-warning-300">100% digital</b>, amigable con el medio
          ambiente e increíblemente simple para nuestros clientes
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  baseLogosUrl
} from '@/config/config';
import PxModalTakeControl from "@/components/PxModalTakeControl.vue";
import PxMesssageModal from "../components/PxMesssageModal.vue";
//import PxBanner from "../components/PxBanner.vue";


export default {
  name: "Start",
  components: { PxModalTakeControl, PxMesssageModal },
  data() {
    return {
      text: "Toma el control aquí",
      showModal: false,
      highlights: [
        {
          img: require("../assets/img/plan.svg"),
          title: "¡Cámbiate a enerBit, toma el control!",
          description: "Elige el plan que se acomode a tu necesidad.",
        },
        {
          img: require("../assets/img/energyControl.svg"),
          title: "¡Qué voltaje tener el control!",
          description:
            "Consulta tus consumos en línea “lo que no se mide no se controla”",
        },
        {
          img: require("../assets/img/notifications.svg"),
          title: "Más vale advertido que aburrido",
          description:
            "Programa tus notificaciones sobre consumos y tú tranqui que nosotros te alertaremos",
        },
        {
          img: require("../assets/img/energy.svg"),
          title: " Energía que vibra 100% digital",
          description:
            "Aprende de energía y ahorra, nosotros nos encargamos de lo complicado",
        },
      ],
      video: `${baseLogosUrl}new_video.mp4`

    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
.save-money-text {
  color: #f79009;
}
.team-enerbit {
  height: 130px;
  width: 90%;
  margin-top: 295%;
  margin-left: 4.6%;
  box-shadow: 0px 2px 24px -1px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding-top: 38px;
  z-index: 100;
}

@media (min-width: 360px) {
  .team {
    margin-top: 55rem !important;
  }
}

@media (min-width: 1024px) {
  .team {
    margin-top: 17rem !important;
  }
}

@media (min-width: 1024px) {
  .team-enerbit {
    margin-top: 77% !important;
  }
}

@media (max-width: 1023px) and (min-width: 900px) {
  .team-enerbit {
    margin-top: 190% !important;
  }
}

@media (max-width: 899px) and (min-width: 800px) {
  .team-enerbit {
    margin-top: 210% !important;
  }
}

@media (max-width: 799px) and (min-width: 750px) {
  .team-enerbit {
    margin-top: 220% !important;
  }
}

@media (max-width: 749px) and (min-width: 650px) {
  .team-enerbit {
    margin-top: 250% !important;
  }
}
@media (max-width: 649px) and (min-width: 600px) {
  .team-enerbit {
    margin-top: 270% !important;
  }
}

@media (max-width: 599px) and (min-width: 540x) {
  .team-enerbit {
    margin-top: 280% !important;
  }
}

@media (max-width: 430px) {
  .team-enerbit {
    width: 100%;
    height: 170px;
    margin-top: 410% !important;
  }
}

@media (max-width: 380px) {
  .team-enerbit {
    width: 100%;
    height: 170px;
    z-index: 0;
    margin-top: 480% !important;
  }
}
</style>

<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="flex justify-center items-center"
    class=" "
    content-class="relative flex flex-col mx-4 p-4 border rounded modal-content bg-gray h-full"
  >
    <span class="form-title text-3xl text-center font-bold">
      <slot name="title"></slot>
    </span>
    <div class="flex-grow overflow-y-auto">
      <slot v-bind:params="params"></slot>
    </div>
    <button
      class="absolute top-0 right-0 mt-2 mr-2"
      @click="$emit('cancel', close)"
    >
      <i class="mdi mdi-window-close"></i>
    </button>
  </vue-final-modal>
</template>

<script>
export default {
  name: "PxCustomModal",
  inheritAttrs: false,
};
</script>
<style scoped>
.modal-content {
  max-height: 90% !important;
  margin: 0 1rem !important;
  width: 800px !important;
}

.form-title {
  margin: 0 auto;
  line-height: 35px;
}
</style>
